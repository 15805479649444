import { useContext } from 'react';
import { PortfolioContext } from '../Home/Home';
import logo from '../../../assets/img/logo.svg';
import { COLORS, UppercaseText } from '../../../common/ui/Theme/Theme.styles';
import { ImgContainer, TitleContainer, UserRoleText } from './UserRole.styles';
import { useNavigate, useParams } from 'react-router-dom';

const UserRole = ({ color = COLORS.NEUTRAL.TEXT_BLACK, isMobile = false }) => {
  const context = useContext(PortfolioContext);
  const navigate = useNavigate();
  const { userId } = useParams();
  const nameStr = `${context.userInformation.firstName} ${context.userInformation.lastName}`;
  const subStrLength = 75;
  const name =
    isMobile && nameStr.length > subStrLength
      ? `${nameStr.substr(0, subStrLength)}...`
      : nameStr;
  const role =
    isMobile && context.userInformation.role.length > subStrLength
      ? `${context.userInformation.role.substr(0, subStrLength)}...`
      : context.userInformation.role;

  return (
    <div
      style={{
        cursor: 'pointer',
      }}
      onClick={() => navigate(`/${userId}`)}
    >
      <ImgContainer>
        <img src={logo} alt='logo' />
      </ImgContainer>
      <TitleContainer>
        <UserRoleText color={color}>
          <UppercaseText>{name}</UppercaseText>
        </UserRoleText>
        <UserRoleText color={COLORS.NEUTRAL.MEDIUM_GREY}>
          <UppercaseText>{role}</UppercaseText>
        </UserRoleText>
      </TitleContainer>
    </div>
  );
};

export default UserRole;
