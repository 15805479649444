import { v4 as uuidv4 } from 'uuid';
import { INPUT_TYPE, PROFILE_BUTTON_TYPE } from '../../../common/constants/constants';
import Form from '../../../common/ui/Form/Form';

const inputs = [
  {
    id: uuidv4(),
    label: 'Title',
    type: INPUT_TYPE.TEXT,
    name: 'title',
    required: true,
    maxLength: 1000
  },
  {
    id: uuidv4(),
    label: 'Active Buttons',
    type: INPUT_TYPE.CHECKBOX_LIST,
    name: 'buttons',
    items: Object.keys(PROFILE_BUTTON_TYPE).map((key) => PROFILE_BUTTON_TYPE[key]),
  },
];

const ProfileSectionForm = ({ values, onSubmit, isCreate, onCancel }) => {
  return (
    <Form
      dataInputs={inputs}
      values={values}
      btnText='Save'
      onSubmit={onSubmit}
      onlyModifiedFields={!isCreate}
      onCancel={onCancel}
    />
  );
};

export default ProfileSectionForm;
