import { useContext } from 'react';
import Button from '../../../common/ui/Button/Button';
import MailIcon from '../../../common/ui/Theme/icons/Mail';
import {
  FooterAboutColumn,
  FooterAboutColumnItem,
  FooterAboutColumnItemLink,
  FooterAboutCopyrightColumn,
  FooterAboutRow,
  FooterContact,
  FooterContactButtonWrapper,
  FooterContactText,
  FooterContactWrapper,
  FooterContainer,
  FooterNavWrapper,
  FooterProfilePicture,
  FooterWrapper,
} from './Footer.styles';
import { PortfolioContext } from '../Home/Home';
import UserRole from '../UserRole/UserRole';
import {
  BodySmallRegular,
  BodyBold,
  SmallBold,
  COLORS,
} from '../../../common/ui/Theme/Theme.styles';
import CircleImage from '../../../common/ui/Image/CircleImage';

const Footer = ({
  withContact = false,
  noMargin = false,
  withUserRole = true,
  withContactImg = true,
  customMargin,
}) => {
  const { userInformation } = useContext(PortfolioContext);

  return (
    <FooterWrapper $noMargin={noMargin} $customMargin={customMargin}>
      {withContact && (
        <FooterContactWrapper>
          {withContactImg && (
            <FooterProfilePicture>
              <CircleImage
                src={userInformation.profilePic}
                alt={`${userInformation.firstName} ${userInformation.lastName}`}
                title={`${(userInformation.firstName || '').substr(0, 1)} ${(
                  userInformation.lastName || ''
                ).substr(0, 1)}`}
                centered
              />
            </FooterProfilePicture>
          )}
          <FooterContact withContactImg={withContactImg}>
            <FooterContactText>
              Ready to rock the world?
              <br />
              Drop me a line!
            </FooterContactText>
            <FooterContactButtonWrapper>
              <Button
                iconLeft={<MailIcon />}
                onClick={() => {
                  window.location = `mailto:${userInformation.email}`;
                }}
              >
                <BodyBold>{userInformation.email}</BodyBold>
              </Button>
            </FooterContactButtonWrapper>
          </FooterContact>
        </FooterContactWrapper>
      )}
      <FooterNavWrapper>
        <FooterContainer>
          {withUserRole && (
            <div>
              <UserRole color={COLORS.NEUTRAL.WHITE} />
            </div>
          )}
          <FooterAboutRow marginTop={withUserRole ? '32px' : '0px'}>
            <FooterAboutCopyrightColumn>
              <BodySmallRegular>
                {`© ${new Date().getFullYear()} — Proud member of LINKIT`}
              </BodySmallRegular>
            </FooterAboutCopyrightColumn>
            <FooterAboutColumn>
              <FooterAboutColumnItem>
                <FooterAboutColumnItemLink
                  href='https://www.linkit.nl/en/about'
                  target='_blank'
                  rel='noreferrer'
                >
                  <SmallBold>About LINKT</SmallBold>
                </FooterAboutColumnItemLink>
              </FooterAboutColumnItem>
              <FooterAboutColumnItem>
                <FooterAboutColumnItemLink
                  href='https://www.linkit.nl/vacancies?VacancyType=%5B%22Vacancy%22%5D'
                  target='_blank'
                  rel='noreferrer'
                >
                  <SmallBold>Vacancies at LINKIT</SmallBold>
                </FooterAboutColumnItemLink>
              </FooterAboutColumnItem>
            </FooterAboutColumn>
          </FooterAboutRow>
        </FooterContainer>
      </FooterNavWrapper>
    </FooterWrapper>
  );
};

export default Footer;
