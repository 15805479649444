import { useTranslation } from 'react-i18next';

import { THEME } from '../../../common/constants/constants';
import Button from '../../../common/ui/Button/Button';
import { BodySubtitle } from '../../../common/ui/Theme/Theme.styles';
import { ButtonWrapper } from '../CustomerList/CustomerList.styles';

const ConfirmModalContent = ({
  body,
  onCancel,
  onCofirm
}) => {
  const { t } = useTranslation();

  return (
    <>
      <BodySubtitle>
        {body}
      </BodySubtitle>
      <ButtonWrapper>
        <Button type='button' onClick={onCancel}>
          {t('common.cancel')}
        </Button>
        <Button
          theme={THEME.ERROR}
          type='buttom'
          onClick={onCofirm}
        >
          {t('common.confirm')}
        </Button>
      </ButtonWrapper>
    </>
  );
};

export default ConfirmModalContent;
