import { useTranslation } from 'react-i18next';

import { ChangePasswordForm } from './ChangePasswordForm';
import { TitleH3 } from '../../../common/ui/Theme/Theme.styles';

export function ChangePassword() {
  const { t } = useTranslation();

  return (
    <>
      <TitleH3>{t('profile.changePassword.title')}</TitleH3>
      <br />
      <ChangePasswordForm />
    </>
  );
}
