import styled from 'styled-components';
import { SCREEN } from '../../../common/ui/screen/screen';
import { BodyRegular, COLORS, PreTitle, TitleH1 } from '../../../common/ui/Theme/Theme.styles';

export const PortfolioPreTitle = styled(PreTitle)`
  color: ${COLORS.SILVER_TREE.PRIMARY};
  text-transform: uppercase;
  margin-bottom: 8px;
`;

export const PortfolioTitle = styled(TitleH1)`
  color: ${COLORS.NEUTRAL.TEXT_BLACK};
  margin-bottom: 24px;
`;

export const PortfolioSubtitle = styled(BodyRegular)`
  margin-bottom: 80px;

  ${SCREEN.xxsm} {
    margin-bottom: 40px;
  }

`;

export const PortfolioCustomer = styled(PreTitle)`
  color: ${COLORS.NEUTRAL.DARK_GREY};
  text-transform: uppercase;
`;

export const PortfolioCustomerList = styled.div`
  display: flex;
  width: 100%
  max-width: 100%;
  flex-wrap: wrap;
`;

export const PortfolioCustomerListItem = styled.div`
  margin-top: 8px;
  margin-right: 24px;
  ${SCREEN.xsm} {
    margin-right: 16px;
  }
`;

export const PortfolioCustomerListItemImg = styled.img`
  max-height: 40px;
  ${SCREEN.xxsm} {
    max-height: 35px;
  }
`;

export const PortfolioCustomerListItemTitle = styled.div`
  height: 40px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  ${SCREEN.sm} {
    height: 30px;
    width: 55px;
  }
`;
