import styled from 'styled-components';
import { TitleH3 } from '../../../common/ui/Theme/Theme.styles';

export const Logo = styled.img`
  height: 100px;
  display: flex;
  margin: auto;
  margin-bottom: 20px;
`;

export const Title = styled(TitleH3)`
  text-align: center;
  margin-bottom: 20px;
`;