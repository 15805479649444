import { useTranslation } from 'react-i18next';

import Form from '../../../common/ui/Form/Form';
import LogoImg from '../../../assets/img/logo.svg';
import { v4 as uuidv4 } from 'uuid';
import { INPUT_TYPE } from '../../../common/constants/constants';
import { Logo, Title } from './ForgotPasswordPage.styles';
import { toastHelper } from '../../../common/utils/toastHelper';
import { useForgotPassword } from '../hooks/useForgotPassword';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AdminWrapper, AuthFormWrapper } from '../Admin.styles';
import BlockUi from '../../../common/ui/BlockUi/BlockUi';
import { Container } from '../../../common/ui/Theme/Theme.styles';
import { useFetchIsForgotPasswordTokenValid } from '../hooks/useFetchIsForgotPasswordTokenValid';
import { useResetPassword } from '../hooks/useResetPassword';

const inputs = [
  {
    id: uuidv4(),
    label: 'resetPassword.input.password',
    type: INPUT_TYPE.PASSWORD,
    name: 'password',
    required: true,
    maxLength: 100,
    minLength: 5,
  },
  {
    id: uuidv4(),
    label: 'resetPassword.input.repeatPassword',
    type: INPUT_TYPE.PASSWORD,
    name: 'repeatPassword',
    required: true,
    maxLength: 100,
    minLength: 5,
    shouldMatch: 'password',
    matchLabel: 'Password',
  },
];

export function ResetPasswordForm() {
  const navigate = useNavigate();
  const { token } = useParams();
  const { t } = useTranslation();
  const { isLoading: isFetchingToken, fetchIsTokenValid } =
    useFetchIsForgotPasswordTokenValid();
  const { isLoading: isResetingPassword, resetPassword } = useResetPassword();

  const [data] = useState({
    password: '',
    repeatPassword: '',
  });
  const isLoading = isFetchingToken || isResetingPassword;

  const isTokenValid = useCallback(async () => {
    try {
      await fetchIsTokenValid(token);
    } catch (error) {
      toastHelper.error(error, t);
      navigate('/admin');
    }
  }, [token, fetchIsTokenValid, navigate, t]);

  useEffect(() => {
    if (!token) return;

    isTokenValid();
  }, [token, isTokenValid]);

  async function handleChangePassword({ password, repeatPassword }) {
    try {
      await resetPassword(token, { password, repeatPassword });

      toastHelper.success((t('resetPassword.messages.success')));

      navigate('/admin');
    } catch (error) {
      toastHelper.error(error, t);
    }
  }

  return (
    <Container>
      <BlockUi blocked={isLoading}>
        <AdminWrapper>
          <AuthFormWrapper>
            <Logo src={LogoImg} alt='LINKT' />
            <Title>{t('resetPassword.title')}</Title>
            <Form
              dataInputs={inputs.map((input) => ({
                ...input,
                label: t(input.label),
              }))}
              values={data}
              btnText={t('profile.changePassword.title')}
              onSubmit={handleChangePassword}
            />
          </AuthFormWrapper>
        </AdminWrapper>
      </BlockUi>
    </Container>
  );
}
