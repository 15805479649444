import styled from 'styled-components';
import { SCREEN } from '../../../common/ui/screen/screen';
import { ContainerColumn } from '../../../common/ui/Theme/Theme.styles';

export const PortfolioIntroductionColumn = styled(ContainerColumn)`
  margin-bottom: 80px;
  ${SCREEN.sm} {
    flex-basis: 80%;
    max-width: 80%;
  }
  ${SCREEN.xsm} {
    flex-basis: 100%;
    max-width: 100%;
  }
`;
