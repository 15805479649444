import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { SCREEN } from '../../../common/ui/screen/screen';
import { COLORS } from '../../../common/ui/Theme/Theme.styles';

export const ProjectTitle = styled.div``;

export const ProjectSectionRow = styled.div`
  display: flex;
  margin-bottom: 80px;
  ${({ align }) => !isEmpty(align) && align}
  ${SCREEN.sm} {
    flex-wrap: wrap;
  }
  ${SCREEN.xsm} {
    align-content: start;
  }
  ${SCREEN.xxsm} {
    margin-bottom: 40px;
  }
`;

export const ProjectSectionColumn = styled.div`
  ${({ isSingleAlignedColumn }) =>
    isSingleAlignedColumn
      ? `min-width: 50px; max-width: 600px;`
      : `flex-basis: 100%;`}

  ${SCREEN.xsm} {
    flex-basis: 100%;
  }
`;

export const ProjectSectionTextWrapper = styled.div`
  margin-bottom: 16px;
`;

export const ProjectSectionTitle = styled.div`
  text-transform: uppercase;
`;

export const ProjectSectionImageWrapper = styled.div`
  width: 100%;
  background white;
  min-height: 200px;
  display: flex;
  justify-content: center;
  background-color: ${COLORS.NEUTRAL.PALE_GREY};

  > img {
    max-width: 100%;
  }

  ${SCREEN.xsm} {
    max-height: 500px;
  }

  ${SCREEN.xxsm} {
    max-height: 250px;
  }
`;

export const ProjectSectionWrapper = styled.div`
  height: 100%;
`;

export const ProjectAuthWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const OtherProjectsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  ${SCREEN.xsm} {
    flex-direction: column;
  }
`;

export const OtherProject = styled.div`
  width: 33.3%;
  margin-left: 8px;
  margin-right: 8px;
  :first-child {
    margin-left: 0px;
  }
  :last-child {
    margin-right: 0px;
  }
  ${SCREEN.xsm} {
    margin-right: 0px;
    margin-left: 0px;
    margin-bottom: 16px;
    width: 100%;

    :last-child {
      margin-bottom: 0px;
    }
  }
`;