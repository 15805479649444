import { useTranslation } from 'react-i18next';
import {
  InputFileImage,
  InputFileWrapper,
  PictureWrapper,
} from './Form.styles';

const ImageUploader = ({ input, onInputChange }) => {
  const { t } = useTranslation();
  
  const onFileAdded = (e, input) => {
    const { files } = e.target;
    if (files && files.length === 1) {
      const file = files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        const result = {
          file: reader.result,
          size: file.size,
          name: file.name,
        };
        onInputChange(input, result.file);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <PictureWrapper>
      <InputFileWrapper>
        <InputFileImage
          type='file'
          name={input.name}
          id={input.name}
          disabled={input.disabled}
          onChange={(e) => onFileAdded(e, input)}
          accept='image/png, image/jpeg, image/jpg'
        />
        <span>{t('common.uploadNewPicture')}</span>
      </InputFileWrapper>
    </PictureWrapper>
  );
};

export default ImageUploader;
