import { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  COLUMN_ALIGN,
  CONTENT_TYPE,
  DB_STATUS,
  INPUT_TYPE,
} from '../../../common/constants/constants';
import { ButtonWrapper, InputWrapper } from '../../../common/ui/Form/Form.styles';
import Input from '../../../common/ui/Form/Input';
import Select from '../../../common/ui/Form/Select';
import {
  BodyRegular,
  BodySmallRegular,
  SmallBold,
  TitleH3,
} from '../../../common/ui/Theme/Theme.styles';
import { ActionLink } from '../CustomerList/CustomerList.styles';
import alignHelper from '../../../common/utils/alignHelper';
import {
  ProjectSection,
  ProjectSectionData,
  ProjectSectionTitle,
  ProjectSectionWrapper,
} from './ProjectSectionContent.styles';
import ProjectSectionColumnContent from './ProjectSectionColumnContent';
import Button from '../../../common/ui/Button/Button';

const ProjectSectionContent = ({ project, onGoBack, onSave }) => {
  const [sections, setSections] = useState([]);

  const handleAddNewSectionClick = () => {
    setSections((values) => [
      ...values,
      {
        id: uuidv4(),
        status: DB_STATUS.NEW,
        title: '',
        type: '',
        align: COLUMN_ALIGN.NONE,
        columns: [],
      },
    ]);
    window.scrollTo(0, document.body.scrollHeight);
  };

  const handleDeleteSectionClick = (sectionId) => {
    setSections((values) => {
      let newValues = [...values];
      const index = newValues.findIndex((el) => el.id === sectionId);

      if (index > -1) {
        if (newValues[index].status !== DB_STATUS.NEW) {
          newValues[index] = {
            ...newValues[index],
            status: DB_STATUS.DELETED,
          };
        } else {
          newValues = newValues.filter((el) => el.id !== sectionId);
        }
      }

      return newValues;
    });
  };

  const handleSectionDataChanged = (id, inputName, value) => {
    setSections((values) => {
      const newValues = [...values];
      const index = newValues.findIndex((el) => el.id === id);

      if (index > -1) {
        newValues[index] = {
          ...newValues[index],
          [`${inputName}`]: value,
        };
      }

      return newValues;
    });
  };

  const handleAddNewChildClick = (sectionId, columnId) => {
    setSections((values) => {
      const newValues = [...values];
      const sectionIndex = newValues.findIndex((el) => el.id === sectionId);
      if (sectionIndex > -1) {
        newValues[sectionIndex] = {
          ...newValues[sectionIndex],
        };

        newValues[sectionIndex].columns = [...newValues[sectionIndex].columns];

        const columnIndex = newValues[sectionIndex].columns.findIndex(
          (el) => el.id === columnId
        );

        if (columnIndex > -1) {
          newValues[sectionIndex].columns[columnIndex] = {
            ...newValues[sectionIndex].columns[columnIndex],
          };

          const children =
            newValues[sectionIndex].columns[columnIndex].children;
          newValues[sectionIndex].columns[columnIndex].children = [
            ...children,
            {
              id: uuidv4(),
              title: '',
              body: '',
              image: '',
              type: CONTENT_TYPE.CONTENT,
              status: DB_STATUS.NEW,
            },
          ];
        }
      }

      return newValues;
    });
  };

  const handleRemoveColumnClick = (sectionId, columnId) => {
    setSections((values) => {
      const newValues = [...values];
      const sectionIndex = newValues.findIndex((el) => el.id === sectionId);

      if (sectionIndex > -1) {
        newValues[sectionIndex] = {
          ...newValues[sectionIndex],
        };
        const columnIndex = newValues[sectionIndex].columns.findIndex(
          (el) => el.id === columnId
        );

        if (columnIndex > -1) {
          newValues[sectionIndex].columns[columnIndex] = {
            ...newValues[sectionIndex].columns[columnIndex],
          };
          if (
            newValues[sectionIndex].columns[columnIndex].status ===
            DB_STATUS.NEW
          ) {
            newValues[sectionIndex].columns = newValues[
              sectionIndex
            ].columns.filter((el) => el.id !== columnId);
          } else {
            newValues[sectionIndex].columns[columnIndex].status =
              DB_STATUS.DELETED;
          }
        }
      }

      return newValues;
    });
  };

  const handleRemoveColumnContentClick = (sectionId, columnId, childId) => {
    setSections((values) => {
      const newValues = [...values];
      const sectionIndex = newValues.findIndex((el) => el.id === sectionId);

      if (sectionIndex > -1) {
        newValues[sectionIndex] = {
          ...newValues[sectionIndex],
        };
        const columnIndex = newValues[sectionIndex].columns.findIndex(
          (el) => el.id === columnId
        );

        if (columnIndex > -1) {
          newValues[sectionIndex].columns[columnIndex] = {
            ...newValues[sectionIndex].columns[columnIndex],
          };
          const childIndex = newValues[sectionIndex].columns[
            columnIndex
          ].children.findIndex((el) => el.id === childId);

          if (childIndex > -1) {
            const children =
              newValues[sectionIndex].columns[columnIndex].children;
            newValues[sectionIndex].columns[columnIndex].children[childIndex] =
              {
                ...children[childIndex],
              };

            if (children[childIndex].status === DB_STATUS.NEW) {
              newValues[sectionIndex].columns[columnIndex].children =
                children.filter((el) => el.id !== childId);
            } else {
              newValues[sectionIndex].columns[columnIndex].children[
                childIndex
              ].status = DB_STATUS.DELETED;
            }
          }
        }
      }

      return newValues;
    });
  };

  const handleAddNewColumnClick = (sectionId) => {
    setSections((values) => {
      const newValues = [...values];
      const sectionIndex = newValues.findIndex((el) => el.id === sectionId);
      if (sectionIndex > -1) {
        newValues[sectionIndex] = {
          ...newValues[sectionIndex],
          columns: [
            ...newValues[sectionIndex].columns,
            {
              id: uuidv4(),
              children: [],
              status: DB_STATUS.NEW,
            },
          ],
        };
      }
      return newValues;
    });
  };

  const handleColumnDataChanged = (
    sectionId,
    columnId,
    childId,
    inputName,
    value
  ) => {
    setSections((values) => {
      const newValues = [...values];
      const sectionIndex = newValues.findIndex((el) => el.id === sectionId);
      if (sectionIndex > -1) {
        const columnIndex = newValues[sectionIndex].columns.findIndex(
          (el) => el.id === columnId
        );

        if (columnIndex > -1) {
          const childIndex = newValues[sectionIndex].columns[
            columnIndex
          ].children.findIndex((el) => el.id === childId);

          if (childIndex > -1) {
            const childData =
              newValues[sectionIndex].columns[columnIndex].children[childIndex];
            newValues[sectionIndex].columns[columnIndex].children[childIndex] =
              {
                ...childData,
                [`${inputName}`]: value,
              };
          }
        }
      }

      return newValues;
    });
  };

  const getFilteredColumns = useCallback(
    (section) =>
      (section.columns || []).filter(
        (column) => column.status !== DB_STATUS.DELETED
      ),
    []
  );

  useEffect(() => {
    if (project) {
      setSections(
        (project.sections || []).map((section) => ({
          ...section,
          columns: (section.columns || []).map((column) => ({
            ...column,
            children: (column.children || []).map((child) => ({
              ...child,
              status: DB_STATUS.UPDATED,
            })),
            status: DB_STATUS.UPDATED,
          })),
          status: DB_STATUS.UPDATED,
        }))
      );
    }
  }, [project]);

  if (!project) {
    return <></>;
  }

  return (
    <div>
      <ActionLink>
        <span onClick={onGoBack}>Go Back</span>
      </ActionLink>
      <ButtonWrapper>
        <Button type='button' onClick={() => onSave(sections)}>
          Save
        </Button>
      </ButtonWrapper>
      <ProjectSectionTitle>
        <SmallBold>Project Title: </SmallBold>
        <BodyRegular>{project.title}</BodyRegular>
      </ProjectSectionTitle>
      <ActionLink>
        <span onClick={() => handleAddNewSectionClick()}>Add new row</span>
      </ActionLink>
      <div>
        {sections
          .filter((el) => el.status !== DB_STATUS.DELETED)
          .map((section, index) => (
            <ProjectSectionWrapper key={`project-section-${section.id}`}>
              <TitleH3>Row #{index + 1}</TitleH3>
              <ActionLink>
                <span onClick={() => handleDeleteSectionClick(section.id)}>
                  Delete row
                </span>
              </ActionLink>
              <InputWrapper>
                <SmallBold>Title</SmallBold>
                <Input
                  input={{
                    name: 'title',
                    type: INPUT_TYPE.TEXT,
                    // disabled: isRemoved,
                  }}
                  onInputChange={(input, value) => {
                    handleSectionDataChanged(section.id, input.name, value);
                  }}
                  value={section.title}
                />
                <BodySmallRegular>
                  It's just an identifier, it's not dispalyed on Portfolio
                </BodySmallRegular>
              </InputWrapper>
              <InputWrapper>
                <SmallBold>Align</SmallBold>
                <Select
                  input={{
                    name: 'align',
                    noDefaultValue: true,
                  }}
                  value={section.align}
                  items={Object.keys(COLUMN_ALIGN).map((key) => ({
                    value: key,
                    label: key,
                  }))}
                  onInputChange={(input, value) => {
                    handleSectionDataChanged(section.id, input.name, value);
                  }}
                />
                <BodySmallRegular>
                  Alignment takes place only for one columns layout
                </BodySmallRegular>
              </InputWrapper>
              <div>
                <SmallBold>Columns</SmallBold>
                {/* {getFilteredColumns(section).length < 2 && ( */}
                <ActionLink>
                  <span onClick={() => handleAddNewColumnClick(section.id)}>
                    Add new column
                  </span>
                </ActionLink>
                {/* )} */}
                <ProjectSection
                  align={alignHelper.getProjectSectionAlignment(section.align)}
                >
                  {getFilteredColumns(section).map((column, index) => (
                    <ProjectSectionData
                      key={`project-column-${column.id}`}
                      isSingleAlignedColumn={
                        getFilteredColumns(section).length === 1 &&
                        section.align !== COLUMN_ALIGN.NONE
                      }
                    >
                      <ProjectSectionColumnContent
                        section={section}
                        column={column}
                        index={index}
                        handleColumnDataChanged={handleColumnDataChanged}
                        handleAddNewChildClick={handleAddNewChildClick}
                        handleRemoveColumnClick={handleRemoveColumnClick}
                        handleRemoveColumnContentClick={
                          handleRemoveColumnContentClick
                        }
                      />
                    </ProjectSectionData>
                  ))}
                </ProjectSection>
              </div>
            </ProjectSectionWrapper>
          ))}
      </div>
      <ButtonWrapper>
        <Button type='button' onClick={() => onSave(sections)}>
          Save
        </Button>
      </ButtonWrapper>
    </div>
  );
};

export default ProjectSectionContent;
