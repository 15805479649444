import { TextAreaStyled } from './Form.styles';

const TextArea = ({
  input,
  onChange,
  value,
  hasError
}) => (
  <TextAreaStyled
    type={input.type}
    name={input.name}
    disabled={input.disabled}
    required={input.required}
    value={value}
    maxLength={input.maxLength}
    minLength={input.minLength}
    pattern={input.pattern}
    placeholder={input.placeholder}
    onChange={(e) => onChange(input, e.target.value)}
    hasError={hasError}
  />
);

export default TextArea;
