import isEmpty from 'lodash/isEmpty';

import { ALERT_TYPE } from '../constants/constants';

export const getErrorMessages = ({ error, customError = '' } = {}) => {
  const { response } = error;

  if (
    (response?.status === 404 || response?.status === 400) &&
    !isEmpty(response?.data?.errors)
  ) {
    return response.data.errors.join('\n');
  }

  const errorId = response?.data?.errorId;
  const errorMessage = [customError || 'messages.errors.generic'];

  if (errorId) {
    errorMessage.push(`Error Code: ${errorId}`);
  }

  return errorMessage.join('\n');
};

export const setError = ({ setAlert, response, customError, section }) => {
  if (
    (response?.status === 404 || response?.status === 400) &&
    !isEmpty(response?.data?.errors)
  ) {
    setAlert({
      text: response.data.errors.map((error) => (
        <div key={`error-${error}`}>
          {error}
          <br />
        </div>
      )),
      type: ALERT_TYPE.ERROR,
      section,
    });
  } else {
    const errorId = response?.data?.errorId;
    const errorMessage = [customError || 'messages.errors.generic'];
    if (errorId) {
      errorMessage.push(`Error Code: ${errorId}`);
    }
    setAlert({
      text: errorMessage.map((error) => (
        <div key={`error-${error}`}>
          {error}
          <br />
        </div>
      )),
      type: ALERT_TYPE.ERROR,
      section,
    });
  }
};
