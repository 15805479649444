import axios from 'axios';
import axiosInstance from './interceptor';

export const getPortfolioByPortfolioId = async (portfolioId) => {
  const portfolio = await axios.get(
    `${process.env.REACT_APP_API_URL}/portfolio/${portfolioId}`
  );

  return portfolio.data;
};

export const getProfileSectionsByPortfolioId = async (portfolioId) => {
  const profileSections = await axios.get(
    `${process.env.REACT_APP_API_URL}/portfolio/${portfolioId}/profile-sections`
  );

  return profileSections.data;
};

export const getPortfolio = async () => {
  const portfolio = await axiosInstance.get(
    `${process.env.REACT_APP_API_URL}/portfolio`
  );

  return portfolio.data;
};

export const updatePortfolio = async (
  headerText,
  title,
  description,
  projectText,
  customersText,
  customers,
  aboutHeaderText
) => {
  const data = await axiosInstance.patch(
    `${process.env.REACT_APP_API_URL}/portfolio`,
    {
      headerText,
      title,
      description,
      projectText,
      customersText,
      customers,
      aboutHeaderText,
    }
  );

  return data.data;
};

export const portfolioService = {
  getPortfolio,
  updatePortfolio,
};
