import { useState, useCallback } from 'react';
import { requestStatus } from '../../../common/constants/requestStatus';
import { getErrorMessages } from '../../../common/utils/errorSetter';
import { profileService } from '../../../common/services/profileService';

export function useUpdateProfileSection() {
  const [status, setStatus] = useState(requestStatus.Initial);

  const isLoading = status === requestStatus.Pending;

  const updateProfile = useCallback(async ({ id, title, buttons }) => {
    setStatus(requestStatus.Pending);

    try {
      const updateProfileResponse = await profileService.updateProfileSection(
        id,
        title,
        buttons
      );

      if (!updateProfileResponse) {
        // Throwing an error if response is not success
        throw new Error('');
      }

      setStatus(requestStatus.Done);
    } catch (error) {
      setStatus(requestStatus.Error);

      throw Error(
        getErrorMessages({
          error,
        })
      );
    }
  }, []);

  return {
    isLoading,
    updateProfile,
  };
}
