import LoginForm from './LoginForm/LoginForm';
import { Container } from '../../common/ui/Theme/Theme.styles';
import { AdminWrapper, LINKITLogo, AuthFormWrapper } from './Admin.styles';
import LogoImg from '../../assets/img/logo.svg';
import { ChangePasswordForm } from './ChangePassword/ChangePasswordForm';
import { useAdminContext } from '../context/adminContext';
import { ProfilePage } from './Profile/ProfilePage';
import { TabsWrapper } from '../../common/ui/Tabs/TabsWrapper';

function AdminHomepage() {
  const { token, shouldChangePassword, updateChangePassword, setAuthUser } =
    useAdminContext();

  function handleOnAuth({ shouldChangePassword, token }) {
    setAuthUser({ shouldChangePassword, token });
  }

  function handleChangePassword() {
    // Removing change password form
    updateChangePassword(false);
  }

  if (token) {
    if (shouldChangePassword) {
      return (
        <Container>
          <AdminWrapper>
            <AuthFormWrapper>
              <ChangePasswordForm
                token={token}
                onChangePassword={handleChangePassword}
              />
            </AuthFormWrapper>
          </AdminWrapper>
        </Container>
      );
    }

    return (
      <TabsWrapper>
        <ProfilePage />
      </TabsWrapper>
    );
  }

  return (
    <Container>
      <AdminWrapper>
        <AuthFormWrapper>
          <LINKITLogo>
            <img src={LogoImg} alt='LINKT' />
          </LINKITLogo>
          <LoginForm onAuth={handleOnAuth} />
        </AuthFormWrapper>
      </AdminWrapper>
    </Container>
  );
}

export default AdminHomepage;
