import {
  CONTENT_TYPE,
  DB_STATUS,
  INPUT_TYPE,
} from '../../../common/constants/constants';
import {
  InputPictureWrapper,
  InputWrapper,
  PictureWrapper,
  SquareImage,
} from '../../../common/ui/Form/Form.styles';
import ImageUploader from '../../../common/ui/Form/ImageUploader';
import Input from '../../../common/ui/Form/Input';
import Select from '../../../common/ui/Form/Select';
import TextArea from '../../../common/ui/Form/TextArea';
import { SmallBold } from '../../../common/ui/Theme/Theme.styles';
import { ActionLink } from '../CustomerList/CustomerList.styles';
import { ProjectColumnWrapper } from './ProjectSectionContent.styles';

const ProjectSectionColumnContent = ({
  section,
  column,
  index,
  handleColumnDataChanged,
  handleAddNewChildClick,
  handleRemoveColumnClick,
  handleRemoveColumnContentClick,
}) => {
  return (
    <div>
      <SmallBold>Column #{index + 1}</SmallBold>
      <ActionLink>
        <span onClick={() => handleRemoveColumnClick(section.id, column.id)}>
          Remove Column
        </span>
      </ActionLink>
      <ActionLink>
        <span onClick={() => handleAddNewChildClick(section.id, column.id)}>
          Add new child
        </span>
      </ActionLink>
      {(column.children || [])
        .filter((child) => child.status !== DB_STATUS.DELETED)
        .map((child) => (
          <ProjectColumnWrapper key={`column-child-${child.id}`}>
            <InputWrapper>
              <SmallBold>Content Type</SmallBold>
              <Select
                input={{
                  id: 'type',
                  name: 'type',
                }}
                value={child.type}
                onInputChange={(input, value) => {
                  handleColumnDataChanged(
                    section.id,
                    column.id,
                    child.id,
                    input.name,
                    value
                  );
                }}
                items={Object.keys(CONTENT_TYPE)
                  .filter((key) => key !== CONTENT_TYPE.CUSTOM_CONTENT)
                  .map((key) => ({
                    label: key,
                    value: key,
                  }))}
              />
            </InputWrapper>
            {child.type === CONTENT_TYPE.CONTENT && (
              <InputWrapper>
                <SmallBold>Title</SmallBold>
                <Input
                  input={{
                    name: 'title',
                    type: INPUT_TYPE.TEXT,
                  }}
                  onInputChange={(input, value) => {
                    handleColumnDataChanged(
                      section.id,
                      column.id,
                      child.id,
                      input.name,
                      value
                    );
                  }}
                  value={child.title}
                />
              </InputWrapper>
            )}
            {child.type === CONTENT_TYPE.CONTENT && (
              <InputWrapper>
                <SmallBold>Body</SmallBold>
                <TextArea
                  input={{
                    name: 'body',
                    type: INPUT_TYPE.TEXT,
                  }}
                  onChange={(input, value) => {
                    handleColumnDataChanged(
                      section.id,
                      column.id,
                      child.id,
                      input.name,
                      value
                    );
                  }}
                  value={child.body}
                />
              </InputWrapper>
            )}
            {child.type === CONTENT_TYPE.IMAGE && (
              <InputPictureWrapper>
                <PictureWrapper>
                  {child.image && <SquareImage src={child.image} alt='Image' />}
                </PictureWrapper>
                <ImageUploader
                  input={{
                    name: 'image',
                  }}
                  onInputChange={(input, value) => {
                    handleColumnDataChanged(
                      section.id,
                      column.id,
                      child.id,
                      input.name,
                      value
                    );
                  }}
                />
              </InputPictureWrapper>
            )}
            <ActionLink
              style={{
                textAlign: 'right',
              }}
            >
              <span
                onClick={() =>
                  handleRemoveColumnContentClick(
                    section.id,
                    column.id,
                    child.id
                  )
                }
              >
                Remove Child
              </span>
            </ActionLink>
          </ProjectColumnWrapper>
        ))}
    </div>
  );
};

export default ProjectSectionColumnContent;
