import isEmpty from 'lodash/isEmpty';
import { useContext } from 'react';
import { PortfolioContext } from '../Home/Home';
import Tooltip from '../../../common/ui/Tooltip/Tooltip';
import {
  PortfolioCustomer,
  PortfolioCustomerList,
  PortfolioCustomerListItem,
  PortfolioCustomerListItemImg,
  PortfolioCustomerListItemTitle,
  PortfolioPreTitle,
  PortfolioSubtitle,
  PortfolioTitle,
} from './PortfolioIntroduction.styles';

const PortfolioIntroduction = ({ portfolio }) => {
  const { userInformation } = useContext(PortfolioContext);
  return (
    <>
      {userInformation.headerText && (
        <PortfolioPreTitle>{userInformation.headerText}</PortfolioPreTitle>
      )}
      <PortfolioTitle>{userInformation.title}</PortfolioTitle>
      <PortfolioSubtitle>{userInformation.description}</PortfolioSubtitle>
      {!isEmpty(portfolio.customers) && (
        <div>
          <PortfolioCustomer>
            {userInformation.customersText || 'I AM TRUSTED BY TEAMS AT'}
          </PortfolioCustomer>
          <PortfolioCustomerList>
            {portfolio.customers.map((customer) => (
              <PortfolioCustomerListItem key={`customer-${customer.id}`}>
                <Tooltip title={customer.title}>
                  {customer.image ? (
                    <PortfolioCustomerListItemImg
                      src={customer.image}
                      alt={customer.title}
                    />
                  ) : (
                    <PortfolioCustomerListItemTitle>
                      {customer.title.substr(0, 1)}
                    </PortfolioCustomerListItemTitle>
                  )}
                </Tooltip>
              </PortfolioCustomerListItem>
            ))}
          </PortfolioCustomerList>
        </div>
      )}
    </>
  );
};

export default PortfolioIntroduction;
