import { useState, useCallback } from 'react';
import { requestStatus } from '../../../common/constants/requestStatus';
import { portfolioService } from '../../../common/services/portfolioService';
import { getErrorMessages } from '../../../common/utils/errorSetter';

export function useUpdatePortfolio() {
  const [status, setStatus] = useState(requestStatus.Initial);

  const isLoading = status === requestStatus.Pending;

  const updatePortfolio = useCallback(
    async ({
      headerText,
      title,
      description,
      projectText,
      customersText,
      customers,
      aboutHeaderText,
    }) => {
      setStatus(requestStatus.Pending);

      try {
        const updatedPortfolioResponse = await portfolioService.updatePortfolio(
          headerText,
          title,
          description,
          projectText,
          customersText,
          customers,
          aboutHeaderText
        );

        if (!updatedPortfolioResponse) {
          // Throwing an error if response is not success
          throw new Error('');
        }

        setStatus(requestStatus.Done);
      } catch (error) {
        setStatus(requestStatus.Error);

        throw Error(
          getErrorMessages({
            error,
          })
        );
      }
    },
    []
  );

  return {
    isLoading,
    updatePortfolio,
  };
}
