import { useMediaQuery } from 'react-responsive';
import { COLUMN_ALIGN } from '../../../common/constants/constants';
import alignHelper from '../../../common/utils/alignHelper';
import { SMALL_SCREEN_VALUE } from '../../../common/ui/screen/screen';
import { ProjectSectionColumn, ProjectSectionRow } from './Project.styles';
import ProjectSectionData from './ProjectSectionData';

const getProjectColumnStyle = (colLength, index, isXSMScreen) => {
  if (colLength === 1) {
    return {};
  }
  if (isXSMScreen) {
    return {
      marginBottom: '24px',
    };
  }
  if (index % 2 === 0) {
    return {
      marginRight: '24px',
    };
  }
};

const ProjectSection = ({ section }) => {
  const isXSMScreen = useMediaQuery({
    query: `(max-width: ${SMALL_SCREEN_VALUE})`,
  });

  return (
    <ProjectSectionRow
      align={alignHelper.getProjectSectionAlignment(section.align)}
    >
      {(section.columns || []).map((column, index) => (
        <ProjectSectionColumn
          key={`section-${section.id}-column-${column.id}`}
          style={getProjectColumnStyle(
            section.columns.length,
            index,
            isXSMScreen
          )}
          isSingleAlignedColumn={
            section.columns.length === 1 && section.align !== COLUMN_ALIGN.NONE
          }
        >
          <ProjectSectionData column={column} />
        </ProjectSectionColumn>
      ))}
    </ProjectSectionRow>
  );
};

export default ProjectSection;
