import { useNavigate, useParams } from 'react-router-dom';
import { useFetchProject } from '../hooks/useFetchProject';
import { useCallback, useEffect } from 'react';
import { toastHelper } from '../../../common/utils/toastHelper';
import ProjectSectionContent from './ProjectSectionContent';
import BlockUi from '../../../common/ui/BlockUi/BlockUi';
import { useUpdateProject } from '../hooks/useUpdateProject';

export function ProjectContent() {
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    isLoading: isFetchingProject,
    project,
    fetchProjectById,
  } = useFetchProject();

  const { isLoading: isUpdatingProject, updateProjectSection } =
    useUpdateProject();

  const isLoading = isFetchingProject || isUpdatingProject;

  function handleGoBack() {
    navigate(-1);
  }

  async function handleSave(sections) {
    try {
      await updateProjectSection(id, sections);

      toastHelper.success('Project update successfully');

      handleGoBack();
    } catch (error) {
      toastHelper.error(error);
    }
  }

  const getProject = useCallback(async () => {
    try {
      await fetchProjectById(id);
    } catch (error) {
      toastHelper.error(error);
    }
  }, [id, fetchProjectById]);

  useEffect(() => {
    getProject();
  }, [getProject]);

  return (
    <BlockUi blocked={isLoading}>
      <ProjectSectionContent
        project={project}
        onGoBack={handleGoBack}
        onSave={handleSave}
      />
    </BlockUi>
  );
}
