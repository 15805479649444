import { useState } from 'react';
import { toast } from 'react-toastify';

import { SmallBold } from '../../../common/ui/Theme/Theme.styles';
import Button from '../../../common/ui/Button/Button';
import {
  InputWrapper,
  ButtonWrapper,
  InputStyled,
} from '../../../common/ui/Form/Form.styles';
import { useLogin } from '../hooks/useLogin';
import { useNavigate } from 'react-router-dom';

const LoginForm = ({ onAuth }) => {
  const navigate = useNavigate();
  const { isLoading, authUser } = useLogin();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  function handleForgotPasswordClick() {
    navigate('forgot-password');
  }

  async function handleLogin(event) {
    event.preventDefault();

    try {
      const data = await authUser(email, password);

      onAuth(data);
    } catch (error) {
      toast.error(error.message);
    }
  }

  if (isLoading) {
    <div
      style={{
        textAlign: 'center',
      }}
    >
      Loading...
    </div>;
  }

  return (
    <form onSubmit={handleLogin}>
      <InputWrapper>
        <SmallBold>Email</SmallBold>
        <InputStyled
          type='email'
          name='email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </InputWrapper>
      <InputWrapper>
        <SmallBold>Password</SmallBold>
        <InputStyled
          type='password'
          name='password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </InputWrapper>
      <ButtonWrapper>
        <Button type='button' onClick={handleForgotPasswordClick}>Forgot password</Button>
        <Button type='submit'>Login</Button>
      </ButtonWrapper>
    </form>
  );
};

export default LoginForm;
