import { useLayoutEffect, useMemo, useState } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import {
  COLORS,
  Container,
  PreTitle,
} from '../../../common/ui/Theme/Theme.styles';
import {
  Wrapper,
  HeaderItemWrapper,
  MenuWrapper,
  MenuWrapperItem,
  MenuLink,
  HeaderWrapper,
  MobileMenuWrapper,
  MobileMenuItem,
  MobileMenuItemText,
} from './Header.styles';
import Button from '../../../common/ui/Button/Button';
import UserRole from '../UserRole/UserRole';
import { X_SMALL_SCREEN_VALUE } from '../../../common/ui/screen/screen';

const Header = ({ color, noMargin = false }) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const isXSMScreen = useMediaQuery({
    query: `(max-width: ${X_SMALL_SCREEN_VALUE})`,
  });
  const { userId } = useParams();
  const location = useLocation();

  const activeLink = useMemo(() => {
    if (location.pathname.includes('about')) {
      return 'about';
    } else if (location.pathname.includes('contact')) {
      return 'contact';
    }
    return 'projects';
  }, [location.pathname]);

  useLayoutEffect(() => {
    if (isMenuOpened) {
      document.body.style.position = 'fixed';
      document.body.style.top = '0';
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.overflow = '';
    }
  }, [isMenuOpened]);

  return (
    <>
      <HeaderWrapper isMenuOpened={isMenuOpened}>
        <Container $paddingBottom='24px'>
          <Wrapper noMargin={noMargin}>
            <HeaderItemWrapper>
              <UserRole
                isMobile={isMenuOpened}
                color={isMenuOpened ? COLORS.NEUTRAL.WHITE : color}
              />
            </HeaderItemWrapper>
            <HeaderItemWrapper align='right'>
              {!isXSMScreen ? (
                <MenuWrapper>
                  <MenuWrapperItem>
                    <MenuLink
                      color={
                        activeLink === 'projects'
                          ? COLORS.SILVER_TREE.PRIMARY
                          : COLORS.NEUTRAL.DARK_GREY
                      }
                    >
                      <Link to={`/${userId}`}>
                        <PreTitle>projects</PreTitle>
                      </Link>
                    </MenuLink>
                  </MenuWrapperItem>
                  <MenuWrapperItem>
                    <MenuLink
                      color={
                        activeLink === 'about'
                          ? COLORS.SILVER_TREE.PRIMARY
                          : COLORS.NEUTRAL.DARK_GREY
                      }
                    >
                      <Link to={`/${userId}/about`}>
                        <PreTitle>about me</PreTitle>
                      </Link>
                    </MenuLink>
                  </MenuWrapperItem>
                  <MenuWrapperItem>
                    <MenuLink
                      color={
                        activeLink === 'contact'
                          ? COLORS.SILVER_TREE.PRIMARY
                          : ''
                      }
                    >
                      <Link to={`/${userId}/contact`}>
                        <Button uppercase size='sm'>
                          <PreTitle>let's talk</PreTitle>
                        </Button>
                      </Link>
                    </MenuLink>
                  </MenuWrapperItem>
                </MenuWrapper>
              ) : (
                <Button
                  uppercase
                  size='sm'
                  onClick={() => setIsMenuOpened((val) => !val)}
                >
                  <PreTitle>{!isMenuOpened ? 'menu' : 'close'}</PreTitle>
                </Button>
              )}
            </HeaderItemWrapper>
          </Wrapper>
        </Container>
        {isMenuOpened && (
          <MobileMenuWrapper>
            <MobileMenuItem>
              <MobileMenuItemText>
                <MenuLink
                  color={
                    activeLink === 'projects' ? COLORS.SILVER_TREE.PRIMARY : ''
                  }
                >
                  <Link to={`/${userId}`}>projects</Link>
                </MenuLink>
              </MobileMenuItemText>
            </MobileMenuItem>
            <MobileMenuItem>
              <MobileMenuItemText>
                <MenuLink
                  color={
                    activeLink === 'about' ? COLORS.SILVER_TREE.PRIMARY : ''
                  }
                >
                  <Link to={`/${userId}/about`}>about me</Link>
                </MenuLink>
              </MobileMenuItemText>
            </MobileMenuItem>
            <MobileMenuItem>
              <MenuLink
                color={
                  activeLink === 'contact' ? COLORS.SILVER_TREE.PRIMARY : ''
                }
              >
                <Link to={`/${userId}/contact`}>
                  <Button uppercase size='padd16'>
                    <MobileMenuItemText>let's talk</MobileMenuItemText>
                  </Button>
                </Link>
              </MenuLink>
            </MobileMenuItem>
          </MobileMenuWrapper>
        )}
      </HeaderWrapper>
    </>
  );
};

export default Header;
