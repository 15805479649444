import styled from 'styled-components';
import { SCREEN } from '../../../common/ui/screen/screen';
import { COLORS, TitleH3 } from '../../../common/ui/Theme/Theme.styles';

export const Wrapper = styled.header`
  align-items: flex-start;
  display: flex;
  width: 100%;
  margin-bottom: ${({ noMargin }) => (noMargin ? '0px' : '56px')};
`;

export const HeaderItemWrapper = styled.div`
  text-align: ${({ align }) => align || 'left'};

  :first-child {
    flex-basis: 40%;
  }

  :last-child {
    flex-basis: 60%;
  }

  ${SCREEN.xsm} {
    :first-child {
      flex-basis: calc(100% - 90px);
    }

    :last-child {
      flex-basis: 80px;
      margin-left: 10px;
    }
  }
`;

export const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const MenuWrapperItem = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  text-transform: uppercase;
  :first-child {
    padding-left: 0px;
  }
  :last-child {
    padding-right: 0px;
  }
`;

export const MenuLink = styled.div`
  > a {
    text-decoration: none;
    color: ${({ color }) => (color ? color : COLORS.NEUTRAL.WHITE)};
    :hover {
      color: ${COLORS.SILVER_TREE.PRIMARY};
    }
  }
`;

export const HeaderWrapper = styled.div`
  ${({ isMenuOpened }) =>
    isMenuOpened
      ? `
    background: ${COLORS.NEUTRAL.TEXT_BLACK};
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 2;
  `
      : ''}
`;

export const MobileMenuWrapper = styled.div`
  position: absolute;
  margin: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

export const MobileMenuItem = styled.div`
  height: 64px;
  min-width: 154px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  :last-child {
    margin-bottom: 0px;
  }
`;

export const MobileMenuItemText = styled(TitleH3)`
  font-weight: normal;
  text-transform: uppercase;
`;
