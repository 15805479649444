import profile from './profile.json';
import common from './common.json';
import tabs from './tabs.json';
import home from './home.json';
import messages from './messages.json';
import forgotPassword from './forgotPassword.json';
import resetPassword from './resetPassword.json';

export function getMessages() {
  return {
    common,
    profile,
    tabs,
    home,
    messages,
    forgotPassword,
    resetPassword,
  }
}