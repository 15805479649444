import { useState, useCallback } from 'react';
import { requestStatus } from '../../../common/constants/requestStatus';
import { projectService } from '../../../common/services/projectService';
import { getErrorMessages } from '../../../common/utils/errorSetter';

export function useFetchProject() {
  const [project, setProject] = useState(null);
  const [status, setStatus] = useState(requestStatus.Initial);

  const isLoading = status === requestStatus.Pending;

  const fetchProjectById = useCallback(async (id) => {
    setStatus(requestStatus.Pending);

    try {
      const projectsResponse = await projectService.getProjectByProjectId(id);

      setProject(projectsResponse);

      setStatus(requestStatus.Done);
    } catch (error) {
      setStatus(requestStatus.Error);

      throw Error(
        getErrorMessages({
          error,
        })
      );
    }
  }, []);

  return {
    isLoading,
    project,
    fetchProjectById,
  };
}

