import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useCallback, useEffect, useState } from 'react';
import { DB_STATUS, CONTENT_TYPE } from '../../../common/constants/constants';
import { ActionLink } from '../CustomerList/CustomerList.styles';
import BlockUi from '../../../common/ui/BlockUi/BlockUi';
import { ProfileSectionData } from './ProfileSectionData';
import Button from '../../../common/ui/Button/Button';
import { ButtonWrapper } from '../../../common/ui/Form/Form.styles';
import { useNavigate, useParams } from 'react-router-dom';
import { toastHelper } from '../../../common/utils/toastHelper';
import { useFetchProfileSectionData } from '../hooks/useFetchProfileSectionData';
import { useUpdateProfileSectionData } from '../hooks/useUpdateProfileSectionData';

export function AboutMeContent() {
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    isLoading: isFetching,
    sections,
    fetchProfileSectionData,
  } = useFetchProfileSectionData();
  const { isLoading: isUpdating, updateProfileSectionData } =
    useUpdateProfileSectionData();

  const [sectionsData, setSectionsData] = useState([]);
  const [errors, setErrors] = useState({});

  const isLoading = isFetching || isUpdating;

  const onProfileDataChanged = (id, inputId, value) => {
    if (inputId === 'startDate' || inputId === 'endDate') {
      const index = sectionsData.findIndex((el) => el.id === id);
      let startDate;
      let endDate;

      if (inputId === 'startDate') {
        startDate = value;
        endDate = sectionsData[index].endDate;
      } else {
        startDate = sectionsData[index].startDate;
        endDate = value;
      }

      if (new Date(startDate).getTime() > new Date(endDate).getTime()) {
        setErrors({
          startDate: 'Start date must be before end date',
          endDate: 'End date must be after start date',
        });
      } else {
        setErrors({});
      }
    }

    setSectionsData((values) => {
      const newValues = [...values];
      const index = newValues.findIndex((el) => el.id === id);

      if (index >= 0) {
        newValues[index] = {
          ...newValues[index],
          [`${inputId}`]: value,
        };
      }

      return newValues;
    });
  };

  const switchPositions = (element, sum) => {
    setSectionsData((values) => {
      let newValues = [...values];
      const index = newValues.findIndex((el) => el.id === element.id);

      if (index >= 0) {
        const switchWith = newValues[index + sum];
        newValues[index + sum] = element;
        newValues[index] = switchWith;
      }

      return newValues;
    });
  };

  const onRemove = (element) => {
    setSectionsData((values) => {
      let newValues = [...values];
      const index = newValues.findIndex((el) => el.id === element.id);

      if (index >= 0) {
        if (newValues[index].status === DB_STATUS.NEW) {
          newValues = newValues.filter((el) => el.id !== element.id);
        } else {
          newValues[index] = {
            ...newValues[index],
            status: DB_STATUS.DELETED,
          };
        }
      }

      return newValues;
    });
  };

  const onAdd = (element) => {
    setSectionsData((values) => {
      let newValues = [...values];
      const index = newValues.findIndex((el) => el.id === element.id);

      if (index >= 0) {
        newValues[index] = {
          ...newValues[index],
          status: DB_STATUS.UPDATED,
        };
      }

      return newValues;
    });
  };

  function handleBack() {
    navigate(-1);
  }

  async function handleSave() {
    try {
      await updateProfileSectionData({ id, sectionsData });

      toastHelper.success('Updated correctly');

      handleBack();
    } catch (error) {
      toastHelper.error(error);
    }
  }

  const getProfileSectionData = useCallback(async () => {
    try {
      await fetchProfileSectionData(id);
    } catch (error) {
      toastHelper.error(error);
    }
  }, [id, fetchProfileSectionData]);

  useEffect(() => {
    if (sections.length > 0) {
      setSectionsData(
        sections.map((el) => ({
          preTitle: '',
          subTitle: '',
          body: '',
          image: '',
          startDate: el.startDate || '',
          endDate: el.endDate || '',
          ...el,
          status: DB_STATUS.UPDATED,
        }))
      );
    }
  }, [sections]);

  useEffect(() => {
    getProfileSectionData();
  }, [getProfileSectionData]);

  return (
    <BlockUi blocked={isLoading}>
      <ActionLink>
        <span onClick={handleBack}>{`<-`} Back</span>
      </ActionLink>
      <ButtonWrapper>
        <Button type='button' onClick={handleBack}>
          Cancel
        </Button>
        <Button type='buttom' onClick={handleSave} disabled={!isEmpty(errors)}>
          Save
        </Button>
      </ButtonWrapper>
      <ActionLink>
        <span
          onClick={() => {
            setSectionsData((values) => [
              ...values,
              {
                id: uuidv4(),
                preTitle: '',
                subTitle: '',
                body: '',
                image: '',
                startDate: '',
                endDate: '',
                type: CONTENT_TYPE.CONTENT,
                status: DB_STATUS.NEW,
              },
            ]);
          }}
        >
          Add new content section
        </span>
      </ActionLink>
      {sectionsData.map((profileSectionData, index) => (
        <ProfileSectionData
          key={`profile-sectino-data-${profileSectionData.id}`}
          profileSectionData={profileSectionData}
          onProfileDataChanged={onProfileDataChanged}
          onMoveUp={
            index > 0
              ? (profileSectionData) => switchPositions(profileSectionData, -1)
              : null
          }
          onMoveDown={
            index < sectionsData.length - 1
              ? (profileSectionData) => switchPositions(profileSectionData, 1)
              : null
          }
          errors={errors}
          onRemove={onRemove}
          onAdd={onAdd}
          isRemoved={profileSectionData.status === DB_STATUS.DELETED}
        />
      ))}
      <ButtonWrapper>
        <Button type='button' onClick={handleBack}>
          Cancel
        </Button>
        <Button type='buttom' onClick={handleSave} disabled={!isEmpty(errors)}>
          Save
        </Button>
      </ButtonWrapper>
    </BlockUi>
  );
}
