import styled from 'styled-components';
import { FONTS } from '../../../common/ui/Theme/Theme.styles';

export const ImgContainer = styled.div`
  display: inline-block;
  margin-right: 16px;
  position: relative;
`;

export const TitleContainer = styled.div`
  vertical-align: top;
  display: inline-block;
  font-family: ${FONTS.GENTONA};
  font-size: 14px;
  line-height: 16px;
  width: calc(100% - 50px);
`;

export const UserRoleText = styled.div`
  color: ${({ color }) => color};
  word-break: break-all;
`;
