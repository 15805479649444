import { useContext } from 'react';
import { ProjectListTitle, ProjectListWrapper } from './ProjectList.styles';
import { ContainerRow, CustomColumnColumn } from '../../../common/ui/Theme/Theme.styles';
import { useNavigate } from 'react-router-dom';
import { PortfolioContext } from '../Home/Home';
import SingleProject from '../SingleProject/SingleProject';

const ProjectList = ({ portfolio }) => {
  const { userInformation } = useContext(PortfolioContext);
  const navigate = useNavigate();

  return (
    <ProjectListWrapper>
      <ProjectListTitle>
        {userInformation.projectText || 'My work'}
      </ProjectListTitle>
      <ContainerRow>
        {(portfolio.projects || []).map((project, i) => (
          <CustomColumnColumn
            key={`project-${project.id}`}
            customStyle={
              i % 2 === 0 ? 'margin-right: 12px;' : 'margin-left: 12px;'
            }
          >
            <SingleProject
              projectId={project.id}
              customer={project.customer}
              title={project.title}
              description={project.description}
              image={project.image}
              isProtected={project.isProtected}
              onSubmit={project.onSubmit}
              backgroundColor={project.backgroundColor}
              onButtonClick={(projectId) => {
                navigate(`project/${projectId}`)
              }}
            />
          </CustomColumnColumn>
        ))}
      </ContainerRow>
    </ProjectListWrapper>
  );
};

export default ProjectList;
