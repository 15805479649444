import isEmpty from 'lodash/isEmpty';
import {
  Centered,
  CircularImageContainer,
  CircularImageWrapper,
  ImgWrapper,
} from './CircleImage.styles';

const WRAPPER_SIZES = {
  lg: 'lg',
  md: 'md',
  sm: 'sm',
  xsm: 'xsm',
  xxsm: 'xxsm',
};

const WRAPPER_SIZES_VALUES = {
  [WRAPPER_SIZES.lg]: `width: 150px; height: 150px;`,
  [WRAPPER_SIZES.md]: `width: 100px; height: 100px;`,
  [WRAPPER_SIZES.sm]: `width: 65px; height: 65px;`,
  [WRAPPER_SIZES.xsm]: `width: 50px; height: 50px;`,
  [WRAPPER_SIZES.xxsm]: `width: 35px; height: 35px;`,
};

const CircleImage = ({
  src,
  alt,
  title,
  onClick,
  wrapper = WRAPPER_SIZES.lg,
  bordered,
  centered,
}) => {
  return (
    <CircularImageContainer
      size={WRAPPER_SIZES_VALUES[wrapper]}
      onClick={onClick}
    >
      <CircularImageWrapper bordered={bordered} centered={centered}>
        {!isEmpty(src) ? (
          <ImgWrapper src={src} alt={alt} centered={centered} title={title} />
        ) : (
          <Centered>{title}</Centered>
        )}
      </CircularImageWrapper>
    </CircularImageContainer>
  );
};

export default CircleImage;
