const MailIcon = () => {
  return (
    <svg
      width='33'
      height='32'
      viewBox='0 0 33 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M26.1 4H6.9C5.58 4 4.512 5.08 4.512 6.4L4.5 28L9.3 23.2H26.1C27.42 23.2 28.5 22.12 28.5 20.8V6.4C28.5 5.08 27.42 4 26.1 4ZM23.6998 18.4H9.2998V16H23.6998V18.4ZM23.6998 14.7999H9.2998V12.3999H23.6998V14.7999ZM23.6998 11.2H9.2998V8.80005H23.6998V11.2Z'
        fill='white'
      />
    </svg>
  );
};

export default MailIcon;
