import axios from 'axios';
import auth from '../utils/auth';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use((request) => {
  const isApiUrl = request.url.startsWith(process.env.REACT_APP_API_URL);
  if (isApiUrl) {
    if (auth.isAuth()) {
      request.headers.common.Authorization = `Bearer ${auth.getUserToken()}`;
    }
  }

  return request;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
