import { useState, useCallback } from 'react';
import { requestStatus } from '../../../common/constants/requestStatus';
import { projectService } from '../../../common/services/projectService';
import { getErrorMessages } from '../../../common/utils/errorSetter';

export function useCreateProject() {
  const [status, setStatus] = useState(requestStatus.Initial);

  const isLoading = status === requestStatus.Pending;

  const createProject = useCallback(async ({
    title,
    description,
    customer,
    password,
    backgroundColor,
    image,
    isProtected,
  }) => {
    setStatus(requestStatus.Pending);

    try {
      const createProjectResponse = await projectService.createProject(title,
        description,
        customer,
        password,
        backgroundColor,
        image,
        isProtected,);

      if (!createProjectResponse) {
        // Throwing an error if response is not success
        throw new Error('');
      }

      setStatus(requestStatus.Done);
    } catch (error) {
      setStatus(requestStatus.Error);

      throw Error(
        getErrorMessages({
          error,
        })
      );
    }
  }, []);

  return {
    isLoading,
    createProject,
  };
}
