import { useCallback, useEffect, useState } from 'react';
import { useFetchProfile } from '../hooks/useFetchProfile';
import BlockUi from '../../../common/ui/BlockUi/BlockUi';
import DataTable from '../../../common/ui/DataTable/DataTable';
import { SmallBold } from '../../../common/ui/Theme/Theme.styles';
import { ActionLink } from '../CustomerList/CustomerList.styles';
import { MoveDownSection, MoveUpSection } from './ProfileSection.styles';
import CircleImage from '../../../common/ui/Image/CircleImage';
import ArrowIcon from '../../../assets/img/Arrow.svg';
import { toastHelper } from '../../../common/utils/toastHelper';
import { useNavigate } from 'react-router-dom';
import { useUpdateProfileSectionsOrder } from '../hooks/useUpdateProfileSectionsOrder';
import Modal from '../../../common/ui/Modal/Modal';
import ConfirmModalContent from '../ConfirmModalContent/ConfirmModalContent';
import { useDeleteProfileSection } from '../hooks/useDeleteProfileSection';
import { AboutMeForm } from './AboutMeForm';

const headers = [
  {
    id: 'movements',
    customWidth: '30px',
  },
  {
    id: 'title',
    label: 'Title',
  },
  {
    id: 'buttons',
    label: 'Buttons',
  },
  {
    id: 'actions',
    customWidth: '75px',
  },
];

export function AboutMeSectionListPage() {
  const navigate = useNavigate();

  const {
    isLoading: isFetchingProfile,
    profile,
    fetchProfile,
  } = useFetchProfile();
  const { isLoading: isUpdatingOrder, updateProfileSectionsOrder } =
    useUpdateProfileSectionsOrder();
  const { isLoading: isDeleting, deleteProfileSection } =
    useDeleteProfileSection();

  const [isSaveOrderModalOpened, setIsSaveOrderModalOpened] = useState(false);
  const [profileSectionToDelete, setProfileSectionToDelete] = useState(null);
  const [orderedProfileSections, setOrderedProfileSections] = useState([]);

  const isLoading = isFetchingProfile || isUpdatingOrder || isDeleting;

  function handleCreateClick() {
    navigate('create');
  }

  function handleEditClick(id) {
    navigate(`${id}`);
  }

  function handleContentClick(id) {
    navigate(`${id}/content`);
  }

  async function handleDeleteProfileSection() {
    try {
      await deleteProfileSection(profileSectionToDelete.id);

      toastHelper.success('Section deleted correctly');

      getProfile();
    } catch (error) {
      toastHelper.error(error);
    }
  }

  async function handleSaveOrder() {
    try {
      await updateProfileSectionsOrder(orderedProfileSections);

      toastHelper.success('Ordered updated correctly');

      getProfile();
    } catch (error) {
      toastHelper.error(error);
    }
  }

  function switchPositions(element, sum) {
    setOrderedProfileSections((values) => {
      let newValues = [...values];
      const index = newValues.findIndex((el) => el.id === element.id);

      if (index >= 0) {
        const switchWith = newValues[index + sum];
        newValues[index + sum] = element;
        newValues[index] = switchWith;
      }

      return newValues;
    });
  }

  const getProfile = useCallback(async () => {
    setProfileSectionToDelete(null);
    setIsSaveOrderModalOpened(false);
    setProfileSectionToDelete(null);

    try {
      await fetchProfile();
    } catch (error) {
      toastHelper.error(error);
    }
  }, [fetchProfile]);

  useEffect(() => {
    if (profile?.length > 0) {
      setOrderedProfileSections(profile.sort((a, b) => a.order - b.order));
    }
  }, [profile]);

  useEffect(() => {
    getProfile();
  }, [getProfile]);

  return (
    <>
      {isSaveOrderModalOpened && (
        <Modal>
          <BlockUi blocked={isUpdatingOrder}>
            <ConfirmModalContent
              body={`Do you want to save the order of the sections?`}
              onCancel={() => setIsSaveOrderModalOpened(false)}
              onCofirm={handleSaveOrder}
            />
          </BlockUi>
        </Modal>
      )}
      {profileSectionToDelete && (
        <Modal>
          <BlockUi blocked={isDeleting}>
            <ConfirmModalContent
              body={`Do you wanto to delete ${profileSectionToDelete.title}?`}
              onCancel={() => setProfileSectionToDelete(null)}
              onCofirm={handleDeleteProfileSection}
            />
          </BlockUi>
        </Modal>
      )}
      <AboutMeForm />
      <BlockUi blocked={isLoading}>
        <ActionLink>
          <span onClick={handleCreateClick}>Create new About Me section</span>
        </ActionLink>
        <ActionLink>
          <span onClick={() => setIsSaveOrderModalOpened(true)}>
            Save order
          </span>
        </ActionLink>
        <DataTable
          paginated={false}
          headers={headers}
          rows={orderedProfileSections.map((profileSection, index) => ({
            ...profileSection,
            buttons: (profileSection.buttons || []).map((button) => (
              <ActionLink key={`button-${button.name}`}>
                {button.title}
              </ActionLink>
            )),
            actions: (
              <div>
                <ActionLink>
                  <span onClick={() => handleContentClick(profileSection.id)}>
                    <SmallBold>Content</SmallBold>
                  </span>
                </ActionLink>
                <ActionLink>
                  <span
                    onClick={() => {
                      handleEditClick(profileSection.id);
                    }}
                  >
                    <SmallBold>Edit</SmallBold>
                  </span>
                </ActionLink>
                <ActionLink>
                  <span
                    onClick={() => setProfileSectionToDelete(profileSection)}
                  >
                    <SmallBold>Remove</SmallBold>
                  </span>
                </ActionLink>
              </div>
            ),
            movements: (
              <>
                {index > 0 && (
                  <MoveUpSection>
                    <CircleImage
                      title='Move Up'
                      src={ArrowIcon}
                      alt='Move Up'
                      wrapper='xxsm'
                      centered
                      onClick={() => switchPositions(profileSection, -1)}
                    />
                  </MoveUpSection>
                )}
                {index < orderedProfileSections.length - 1 && (
                  <MoveDownSection>
                    <CircleImage
                      title='Move Down'
                      src={ArrowIcon}
                      alt='Remove'
                      wrapper='xxsm'
                      centered
                      onClick={() => switchPositions(profileSection, 1)}
                    />
                  </MoveDownSection>
                )}
              </>
            ),
          }))}
        />
      </BlockUi>
    </>
  );
}
