import isEmpty from 'lodash/isEmpty';
import { PORTFOLIO_KEY } from '../constants/constants';

const isAuth = () => !isEmpty(localStorage.getItem(PORTFOLIO_KEY));
const getUserToken = () => localStorage.getItem(PORTFOLIO_KEY);
const setUserToken = (token) => localStorage.setItem(PORTFOLIO_KEY, token);
const removeUserToken = () => localStorage.removeItem(PORTFOLIO_KEY);

const auth = {
  isAuth,
  getUserToken,
  setUserToken,
  removeUserToken,
};

export default auth;
