import { useState, useCallback } from 'react';
import { requestStatus } from '../../../common/constants/requestStatus';
import { userService } from '../../../common/services/userService';
import { getErrorMessages } from '../../../common/utils/errorSetter';

export function useUpdateUserInformation() {
  const [status, setStatus] = useState(requestStatus.Initial);

  const isLoading = status === requestStatus.Pending;

  const updateUserInformation = useCallback(
    async ({
      firstName,
      lastName,
      phone,
      role,
      address,
      linkedIn,
      profilePic,
    }) => {
      setStatus(requestStatus.Pending);

      try {
        const updatedUser = await userService.updateUser(
          firstName,
          lastName,
          phone,
          role,
          address,
          linkedIn,
          profilePic
        );

        if (!updatedUser) {
          // Throw error when response is not succeed
          throw Error('');
        }

        setStatus(requestStatus.Done);
      } catch (error) {
        setStatus(requestStatus.Error);

        throw Error(
          getErrorMessages({
            error,
          })
        );
      }
    },
    []
  );

  return {
    isLoading,
    updateUserInformation,
  };
}
