const ArrowIcon = ({ fill }) => {
  return (
    <svg
      width='8'
      height='12'
      viewBox='0 0 8 12'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill={fill || 'none'}
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.99984 0L0.589844 1.41L5.16984 6L0.589844 10.59L1.99984 12L7.99984 6L1.99984 0Z'
      />
    </svg>
  );
};

export default ArrowIcon;
