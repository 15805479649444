export const X_LG_SCREEN_VALUE = '1367px';
export const LG_SCREEN_VALUE = '1366px';
export const MD_SCREEN_VALUE = '1080px';
export const SMALL_SCREEN_VALUE = '768px';
export const X_SMALL_SCREEN_VALUE = '600px';
export const XX_SMALL_SCREEN_VALUE = '360px';

export const SCREEN = {
  xlg: `@media only screen and (min-width: ${X_LG_SCREEN_VALUE})`,
  lg: `@media only screen and (max-width: ${LG_SCREEN_VALUE})`,
  md: `@media only screen and (max-width: ${MD_SCREEN_VALUE})`,
  sm: `@media only screen and (max-width: ${SMALL_SCREEN_VALUE})`,
  xsm: `@media only screen and (max-width: ${X_SMALL_SCREEN_VALUE})`,
  xxsm: `@media only screen and (max-width: ${XX_SMALL_SCREEN_VALUE})`,
};
