import Spinner from '../Spinner/Spinner';
import { BlockUiSpinnerWrapper, BlockUiWrapper } from './BlockUi.styles';

const BlockUi = ({ children, blocked }) => {
  return (
    <BlockUiWrapper>
      {blocked && (
        <BlockUiSpinnerWrapper>
          <Spinner />
        </BlockUiSpinnerWrapper>
      )}
      {children}
    </BlockUiWrapper>
  );
};

export default BlockUi;
