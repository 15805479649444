import { PortfolioInformation } from './PortfolioInformation';
import { CustomerList } from '../CustomerList/CustomerList';

export function PortfolioPage() {
  return (
    <>
      <PortfolioInformation />
      <div
        style={{
          marginTop: '16px',
        }}
      >
        <CustomerList />
      </div>
    </>
  );
}
