import { toast } from 'react-toastify';

const error = (err, translate = (txt) => txt) => {
  return err.message.split('\n').map((e) => toast.error(translate(e)));
};

const success = (message) => {
  return toast.success(message);
};

export const toastHelper = {
  success,
  error,
};
