import { ALERT_TYPE } from '../../../common/constants/constants';
import { AlertWrapper } from './Alert.styles';
import { COLORS } from '../Theme/Theme.styles';

const TYPE_STYLE = {
  [ALERT_TYPE.ERROR]: `background: ${COLORS.UI.ERROR}; color: ${COLORS.NEUTRAL.WHITE};`,
  [ALERT_TYPE.SUCCESS]: `background: ${COLORS.UI.SUCCESS}; color: ${COLORS.NEUTRAL.WHITE};`,
  [ALERT_TYPE.DANGER]: `background: ${COLORS.UI.DANGER}; color: ${COLORS.NEUTRAL.WHITE};`,
  [ALERT_TYPE.WARNING]: `background: ${COLORS.UI.WARNING}; color: ${COLORS.NEUTRAL.WHITE};`,
};

const Alert = ({ id, children, type = ALERT_TYPE.SUCCESS }) => (
  <AlertWrapper id={id} alertStyle={TYPE_STYLE[type]}>
    {children}
  </AlertWrapper>
);

export default Alert;
