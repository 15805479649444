import { useState } from 'react';
import { ALERT_TYPE } from '../../../common/constants/constants';
import { createUser } from '../../../common/services/userService';
import { UserInformationForm } from '../UserInformationForm/UserInformationForm';
import { portfolioInputFields } from './portfolio.inputs';
import { PortfolioLink } from './Portfolio.styles';

const inputs = portfolioInputFields({ create: true });

// TODO: Refactor in order to use hooks and this should be available just for ADMIN users
const CreatePortfolio = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({
    text: '',
    type: ALERT_TYPE.ERROR,
  });
  const [data, setData] = useState({
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    phone: '',
    role: '',
    linkedIn: '',
    profilePic: '',
    address: '',
  });

  const onSubmit = async ({
    email,
    password,
    firstName,
    lastName,
    phone,
    role,
    address,
    linkedIn,
    profilePic,
  }) => {
    setAlert({
      text: '',
      type: ALERT_TYPE.ERROR,
    });
    setIsLoading(true);
    try {
      const data = await createUser(
        email,
        password,
        firstName,
        lastName,
        phone,
        role,
        address,
        linkedIn,
        profilePic
      );
      if (data.isCreated) {
        setData({
          email: '',
          password: '',
          firstName: '',
          lastName: '',
          phone: '',
          role: '',
          linkedIn: '',
          profilePic: '',
          address: '',
        });
        setAlert({
          text: (
            <div>
              <div>User created succesfully!</div>
              <PortfolioLink
                rel='noreferrer'
                href={`${window.location.origin}/${data.portfolioUsername}`}
                target='_blank'
              >
                Check your portfolio at{' '}
                {`${window.location.origin}/${data.portfolioUsername}`}
              </PortfolioLink>
            </div>
          ),
          type: ALERT_TYPE.SUCCESS,
        });
      } else {
        throw new Error();
      }
    } catch (e) {
      if (e.response?.status === 400) {
        setAlert({
          text: e.response.data.errors.map((error) => (
            <div key={`error-${error}`}>
              {error}
              <br />
            </div>
          )),
          type: ALERT_TYPE.ERROR,
        });
      } else {
        setAlert({
          text: 'There was an error creating the user, please try again later',
          type: ALERT_TYPE.ERROR,
        });
      }
    }
    window.scrollTo(0, 0);
    setIsLoading(false);
  };

  return (
    <>
      <UserInformationForm
        onSubmit={onSubmit}
        isLoading={isLoading}
        alert={alert}
        userInformation={data}
        inputs={inputs}
      />
    </>
  );
};

export default CreatePortfolio;
