import { useCallback, useEffect, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { INPUT_TYPE } from '../../../common/constants/constants';
import Form from '../../../common/ui/Form/Form';
import { toastHelper } from '../../../common/utils/toastHelper';
import { useUpdatePortfolio } from '../hooks/useUpdatePortfolio';
import { useFetchPortfolio } from '../../../common/hooks/useFetchPortfolio';
import BlockUi from '../../../common/ui/BlockUi/BlockUi';
import { useTranslation } from 'react-i18next';

const inputs = [
  {
    id: uuidv4(),
    label: 'home.input.Header Text',
    type: INPUT_TYPE.TEXT,
    name: 'headerText',
    placeholder: 'Header Text',
    maxLength: 1000,
  },
  {
    id: uuidv4(),
    label: 'home.input.Title',
    type: INPUT_TYPE.TEXT,
    name: 'title',
    placeholder: 'Title',
    required: true,
    maxLength: 1000,
  },
  {
    id: uuidv4(),
    label: 'home.input.Description',
    type: INPUT_TYPE.TEXT,
    name: 'description',
    placeholder: 'Description',
    required: true,
    maxLength: 1000,
  },
  {
    id: uuidv4(),
    label: 'home.input.Projects Section Text',
    type: INPUT_TYPE.TEXT,
    name: 'projectText',
    placeholder: 'My work',
    maxLength: 200,
  },
  {
    id: uuidv4(),
    label: 'home.input.Customers Text (Text on top of customers images)',
    type: INPUT_TYPE.TEXT,
    name: 'customersText',
    placeholder: 'I AM TRUSTED BY TEAMS AT',
    maxLength: 200,
  },
];

export const PortfolioInformation = () => {
  const { t } = useTranslation();

  const {
    isLoading: isFetchingPortfolio,
    portfolio,
    fetchPortfolio,
  } = useFetchPortfolio();
  const { isLoading: isUpdatingPortfolio, updatePortfolio } =
    useUpdatePortfolio();

  const values = useMemo(
    () => ({
      headerText: portfolio?.headerText ?? '',
      title: portfolio?.title ?? '',
      description: portfolio?.description ?? '',
      projectText: portfolio?.projectText ?? '',
      customersText: portfolio?.customersText ?? '',
      aboutHeaderText: portfolio?.aboutHeaderText ?? '',
    }),
    [portfolio]
  );

  const isLoading = isFetchingPortfolio || isUpdatingPortfolio;

  async function handleUpdatePortfolio(formValues) {
    try {
      await updatePortfolio(formValues);

      toastHelper.success(t('home.messages.success.Portfolio update correctly'));

      await fetchPortfolio();
    } catch (error) {
      toastHelper.error(error, t);
    }
  }

  const getPortfolioData = useCallback(async () => {
    try {
      await fetchPortfolio();
    } catch (error) {
      toastHelper.error(error, t);
    }
  }, [fetchPortfolio, t]);

  useEffect(() => {
    getPortfolioData();
  }, [getPortfolioData]);

  return (
    <BlockUi blocked={isLoading}>
      <Form
        dataInputs={inputs.map((input) => ({
          ...input,
          label: t(input.label),
        }))}
        values={values}
        btnText={t('common.save')}
        onSubmit={handleUpdatePortfolio}
        onlyModifiedFields
      />
    </BlockUi>
  );
};
