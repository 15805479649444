import { useContext, useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Container, ContainerRow, MainWrapper } from '../../../common/ui/Theme/Theme.styles';
import PortfolioIntroduction from '../PortfolioIntroduction/PortfolioIntroduction';
import ProjectList from '../ProjectList/ProjectList';
import { PortfolioIntroductionColumn } from './UserPortfolio.styles';
import { getPortfolioByPortfolioId } from '../../../common/services/portfolioService';
import { PortfolioContext } from '../Home/Home';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Alert from '../../../common/ui/Alert/Alert';
import { ALERT_TYPE } from '../../../common/constants/constants';

const UserPortfolio = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [portfolio, setPortfolio] = useState({});
  const {
    userInformation: { portfolioId },
  } = useContext(PortfolioContext);

  useEffect(() => {
    const fetchPortfolio = async () => {
      try {
        const portfolioData = await getPortfolioByPortfolioId(portfolioId);
        setPortfolio(portfolioData);
      } catch (e) {
        setError(
          'There was an error processing the request, please try again later'
        );
      }
      setIsLoading(false);
    };
    fetchPortfolio();
  }, [setError, portfolioId]);

  if (isLoading) {
    return <></>;
  }

  return (
    <MainWrapper>
      <Header />
      <Container>
        {error ? (
          <Alert type={ALERT_TYPE.ERROR}>{error}</Alert>
        ) : (
          <>
            <ContainerRow>
              <PortfolioIntroductionColumn>
                <PortfolioIntroduction portfolio={portfolio} />
              </PortfolioIntroductionColumn>
            </ContainerRow>
            {!isEmpty(portfolio) && !isEmpty(portfolio.projects) && (
              <ProjectList portfolio={portfolio} />
            )}
          </>
        )}
      </Container>
      <Footer withContact />
    </MainWrapper>
  );
};

export default UserPortfolio;
