import { Navigate } from 'react-router-dom';
import auth from '../../../common/utils/auth';
import { Fragment } from 'react';

export function ProtectedRoute(props) {
  const isAuth = auth.isAuth();

  if (!isAuth) {
    return <Navigate to='/admin' replace />;
  }

  return <Fragment {...props}></Fragment>;
}
