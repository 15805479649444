import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

export const ProjectSectionTitle = styled.div`
  margin-bottom: 16px;
`;

export const ProjectSection = styled.div`
  display: flex;
  margin-top: 16px;
  margin-bottom: 16px;
  ${({ align }) => !isEmpty(align) && align}
`;

export const ProjectSectionData = styled.div`
  flex-basis: ${({ isSingleAlignedColumn }) =>
    isSingleAlignedColumn ? '500px' : '100%'};
  margin-right: 16px;
`;

export const ProjectSectionWrapper = styled.div`
  border-bottom: 1px solid black;
  padding-bottom: 24px;
  padding-top: 16px;
`;

export const ProjectColumnWrapper = styled.div`
  border-bottom: 1px solid black;
  padding-bottom: 24px;
  padding-top: 16px;
`;
