import styled from 'styled-components';
import { SCREEN } from '../screen/screen';

export const FONTS = {
  GENTONA: 'Gentona',
  OPEN_SANS: 'Open Sans',
};

export const COLORS = {
  SILVER_TREE: {
    PRIMARY: '#6D9981',
    SECONDARY: '#6EB393',
    LIGHT: '#77C19F',
  },
  BOSTON_BLUE: {
    PRIMARY: '#457599',
    SECONDARY: '#3D88B5',
    LIGHT: '#3E90C2',
  },
  MEDIUM_TURQUOISE: {
    PRIMARY: '#4794B0',
    SECONDARY: '#25ADCF',
    LIGHT: '#23BADE',
  },
  PUERTO_RICO: {
    PRIMARY: '#5B9592​',
    SECONDARY: '#51AEA9',
    LIGHT: '#56BCB5',
  },
  NEUTRAL: {
    WHITE: '#ffffff',
    PALE_GREY: '#F7F7F7',
    MEDIUM_GREY: '#B2B2B2',
    DARK_GREY: '#67666B',
    TEXT_BLACK: '#23212B',
    SUBTITLE: '#457599',
    LIGHT_GREY: '#E4E4E4',
    RICH_BLACK: '#03050C',
  },
  UI: {
    ERROR: '#B00020',
    DANGER: '#D4573B',
    WARNING: '#D4573B',
    WARNING_V2: '#DEA438',
    SUCCESS: '#6EB393',
  },
  CUSTOM: {
    CONTACT_WRAPPER: '#f7f7f71a',
  },
};

const TitleStyle = `
  font-family: ${FONTS.GENTONA};
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const TitleH1 = styled.h1`
  ${TitleStyle}
  font-size: 40px;
  line-height: 42px;
  ${({ $color }) => ($color ? `color: ${$color};` : '')}

  ${SCREEN.md} {
    font-size: 32px;
    line-height: 32px;
  }

  ${SCREEN.xsm} {
    font-size: 24px;
    line-height: 24px;
  }
`;

export const TitleH2 = styled.h2`
  ${TitleStyle}
  font-size: 32px;
  line-height: 34px;

  ${SCREEN.sm} {
    font-size: 24px;
    line-height: 24px;
  }
`;

export const TitleH3 = styled.h3`
  ${TitleStyle}
  font-size: 24px;
  line-height: 28px;
`;

export const PreTitle = styled.p`
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: ${FONTS.GENTONA};
  font-size: 14px;
  line-height: 32px;
  ${({ color }) => (color ? `color: ${color};` : '')}
`;

export const BodyBold = styled.p`
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: ${FONTS.OPEN_SANS};
  font-weight: bold;
  font-size: 18px;
  line-height: 36px;
`;

export const BodyRegular = styled.p`
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: ${FONTS.OPEN_SANS};
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;

  ${SCREEN.xsm} {
    font-size: 16px;
    line-height: 28px;
  }
`;

export const BodyLink = styled.a`
  cursor: pointer;
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: ${FONTS.OPEN_SANS};
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }

  ${SCREEN.md} {
    font-size: 16px;
    line-height: 24px;
  }

  ${SCREEN.sm} {
    font-size: 14px;
    line-height: 24px;
  }
`;

export const BodySmallRegular = styled.p`
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: ${FONTS.OPEN_SANS};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  ${({ color }) => (color ? `color: ${color};` : '')}
`;

export const BodySubtitle = styled.p`
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: ${FONTS.OPEN_SANS};
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  ${({ color }) => (color ? `color: ${color};` : '')}
`;

export const SmallBold = styled.p`
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: ${FONTS.OPEN_SANS};
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  ${({ hasError }) => (hasError ? `color: ${COLORS.UI.DANGER};` : '')}
  ${({ color }) => (color ? `color: ${color};` : '')}
`;

export const ContainerRow = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;

  ${SCREEN.sm} {
    margin-left: 0px;
  }
`;

export const ContainerColumn = styled.div`
  flex-basis: 50%;
  flex-grow: 0;
  max-width: 50%;
  margin-bottom: 24px;

  ${SCREEN.sm} {
    flex-basis: 100%;
    max-width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
`;
export const Container = styled.div`
  padding-top: 24px;
  padding-right: 80px;
  padding-bottom: ${({ $paddingBottom }) => $paddingBottom ?? '0px'};
  padding-left: 80px;
  max-width: 2048px;
  margin: auto;

  ${SCREEN.lg} {
    padding-left: 64px;
    padding-right: 64px;
  }

  ${SCREEN.md} {
    padding-left: 40px;
    padding-right: 40px;
  }

  ${SCREEN.sm} {
    padding-left: 32px;
    padding-right: 32px;
  }

  ${SCREEN.xxsm} {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const CustomColumnColumn = styled(ContainerColumn)`
  margin-bottom: 24px;
  ${({ customStyle }) => customStyle}
  max-width: calc(50% - 12px);
  flex-basis: calc(50% - 12px);

  ${SCREEN.sm} {
    margin-left: 0px;
    margin-right: 0px;
    flex-basis: 100%;
    max-width: 100%;
  }
`;

export const UppercaseText = styled.div`
  text-transform: uppercase;
`;

export const FlexCentered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FlexBoxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;

  > ${Container} {
    // Removing padding from width
    width: calc(100% - 80px - 80px);
    max-width: 100%;
    flex-grow: 1;

    ${SCREEN.lg} {
      // Removing padding from width
      width: calc(100% - 64px - 64px);
    }

    ${SCREEN.md} {
      // Removing padding from width
      width: calc(100% - 40px - 40px);
    }

    ${SCREEN.sm} {
      // Removing padding from width
      width: calc(100% - 32px - 32px);
    }

    ${SCREEN.xxsm} {
      // Removing padding from width
      width: calc(100% - 16px - 16px);
    }
  }
`;
