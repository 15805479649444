import styled from 'styled-components';
import { SCREEN } from '../../ui/screen/screen';
import { COLORS } from '../../ui/Theme/Theme.styles';

export const TabContainer = styled.div`
  display: flex;
  -webkit-box-shadow: 3px 2px 10px 3px rgba(0, 0, 0, 0.42);
  box-shadow: 3px 2px 10px 3px rgba(0, 0, 0, 0.42);
  border-radius: 10px;
  min-height: 300px;
  ${SCREEN.sm} {
    flex-direction: column;
  }
`;

export const TabsWrapperStyled = styled.div`
  border-right: 1px solid rgba(0,0,0,0.42);
  flex: 1;
  min-width: 150px;
  max-width: 190px;
  ${SCREEN.sm} {
    max-width: 100%;
  }
}
`;

export const Tab = styled.div`
  padding: 16px;
  cursor: pointer;
  background: ${({ active }) =>
    active ? COLORS.BOSTON_BLUE.PRIMARY : COLORS.BOSTON_BLUE.LIGHT};
  font-weight: ${({ active }) => (active ? 'bold' : '400')};
  color: ${COLORS.NEUTRAL.WHITE};
  :hover {
    background: ${COLORS.BOSTON_BLUE.SECONDARY};
    text-decoration: underline;
  }
`;

export const TabContentWrapper = styled.div`
  flex: 1;
  padding: 16px;
  max-width: calc(100% - 190px);
  border-left: none;

  ${SCREEN.sm} {
    max-width: 100%;
  }
`;

export const MobileTabExpandIcon = styled.div`
  width: 35px;
  height: 35px;
  margin: auto;
  text-align: center;
  margin-top: 8px;
`;
