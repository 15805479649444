import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { PhoneNumberInput } from './Form.styles';

const PhoneNumber = ({ input, onInputChange, value, hasError }) => (
  <PhoneNumberInput>
    <PhoneInput
      placeholder='Enter phone number'
      value={value}
      defaultCountry='NL'
      onChange={(val) => onInputChange(input, val)}
    />
  </PhoneNumberInput>
);

export default PhoneNumber;
