import { useContext, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { PortfolioContext } from '../Home/Home';
import CircleImage from '../../../common/ui/Image/CircleImage';
import { ContactOptionsWrapper, ContactText } from './Contact.styles';
import ContactOptions from './ContactOptions';
import { CONTACT_OPTIONS_TYPE } from '../../../common/constants/constants';
import PhoneIcon from '../../../common/ui/Theme/icons/Phone';
import LinkedInIcon from '../../../common/ui/Theme/icons/LinkedIn';
import MailIcon from '../../../common/ui/Theme/icons/Mail';

const ContactUser = () => {
  const { userInformation } = useContext(PortfolioContext);
  const contactOptions = useMemo(() => {
    let response = [];
    if (!isEmpty(userInformation.phone)) {
      response.push({
        iconLeft: <PhoneIcon />,
        text: `Call ${userInformation.phone}`,
        value: userInformation.phone,
        type: CONTACT_OPTIONS_TYPE.PHONE,
      });
    }
    if (!isEmpty(userInformation.email)) {
      response.push({
        iconLeft: <MailIcon />,
        text: `Mail ${userInformation.email}`,
        value: userInformation.email,
        type: CONTACT_OPTIONS_TYPE.MAIL,
      });
    }
    if (!isEmpty(userInformation.linkedIn)) {
      response.push({
        iconLeft: <LinkedInIcon />,
        text: 'View LinkedIn-profile',
        value: userInformation.linkedIn,
        type: CONTACT_OPTIONS_TYPE.LINKED_IN,
      });
    }
    return response;
  }, [userInformation.phone, userInformation.email, userInformation.linkedIn]);

  return (
    <div>
      <div>
        <CircleImage
          src={userInformation.profilePic}
          alt={`${userInformation.firstName} ${userInformation.lastName}`}
          title={`${(userInformation.firstName || '').substr(0, 1)} ${(
            userInformation.lastName || ''
          ).substr(0, 1)}`}
          centered
        />
      </div>
      <ContactText>
        Ready to rock the world?
        <br />
        Let's talk!
      </ContactText>
      <ContactOptionsWrapper>
        <ContactOptions options={contactOptions} />
      </ContactOptionsWrapper>
    </div>
  );
};

export default ContactUser;
