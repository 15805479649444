import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { SMALL_SCREEN_VALUE } from '../screen/screen';
import { MobileTabExpandIcon, Tab, TabsWrapperStyled } from './Tabs.styled';
import ArrowIcon from '../../../assets/img/Arrow.svg';
import CircleImage from '../Image/CircleImage';
import {
  MoveDownSection,
  MoveUpSection,
} from '../../../admin/ui/AboutMe/ProfileSection.styles';

export const Tabs = ({ tabs, activeTab, onChangeTab }) => {
  const { t } = useTranslation();

  const [isMobileExpanded, setIsMobileExpanded] = useState(false);
  const isSMScreen = useMediaQuery({
    query: `(max-width: ${SMALL_SCREEN_VALUE})`,
  });

  function getTabs() {
    return tabs.map((tab, index) => (
      <Tab
        key={tab.route}
        onClick={() => {
          onChangeTab(index);
          setIsMobileExpanded(false);
        }}
        active={activeTab === index}
      >
        {t(tab.title)}
      </Tab>
    ));
  }

  if (isSMScreen) {
    const currentTab = tabs[activeTab];

    return (
      <>
        {!isMobileExpanded && currentTab ? (
          <Tab key={currentTab.route} active>
            {t(currentTab.title)}
          </Tab>
        ) : (
          getTabs()
        )}
        <MobileTabExpandIcon>
          {isMobileExpanded ? (
            <MoveUpSection>
              <CircleImage
                title='Expand'
                src={ArrowIcon}
                alt='Expand'
                wrapper='xxsm'
                onClick={() => setIsMobileExpanded(false)}
                centered
              />
            </MoveUpSection>
          ) : (
            <MoveDownSection>
              <CircleImage
                title='Expand'
                src={ArrowIcon}
                alt='Expand'
                wrapper='xxsm'
                onClick={() => setIsMobileExpanded(true)}
                centered
              />
            </MoveDownSection>
          )}
        </MobileTabExpandIcon>
      </>
    );
  }

  return <TabsWrapperStyled>{getTabs()}</TabsWrapperStyled>;
};
