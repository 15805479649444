import { createContext, useContext, useState, useEffect } from 'react';
import auth from '../../common/utils/auth';

const initialValue = {
  shouldChangePassword: false,
  token: null,
  logout: () => {},
  setAuthUser: () => {},
  updateChangePassword: (bool) => {},
};

const AdminContext = createContext(initialValue);

export function AdminContextProvider({ ...props }) {
  const [token, setToken] = useState(null);
  const [shouldChangePassword, setShouldChangePassword] = useState(false);

  function updateChangePassword(value) {
    // Once password is updated after login, we should set the token in localstorage
    auth.setUserToken(token);
    setShouldChangePassword(value);
  }

  function setAuthUser({ shouldChangePassword, token }) {
    if (shouldChangePassword) {
      setShouldChangePassword(true);
    } else {
      auth.setUserToken(token);
    }

    setToken(token);
  }

  const logout = () => {
    auth.removeUserToken();
    setToken(null);
  };

  useEffect(() => {
    if (auth.isAuth()) {
      setToken(auth.getUserToken());
    }
  }, []);

  return (
    <AdminContext.Provider
      value={{
        shouldChangePassword,
        token,
        updateChangePassword,
        setAuthUser,
        logout,
      }}
      {...props}
    />
  );
}

export function useAdminContext() {
  const adminContext = useContext(AdminContext);

  if (!adminContext) {
    throw Error('useAdminContext should be used within AdminContextProvider');
  }

  return adminContext;
}
