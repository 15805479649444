import styled from 'styled-components';

export const BlockUiWrapper = styled.div`
  position: relative;
`;

export const BlockUiSpinnerWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgb(255 255 255 / 80%);
  z-index: 1;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
