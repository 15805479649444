import styled from 'styled-components';
import { THEME } from '../../../common/constants/constants';
import { COLORS } from '../Theme/Theme.styles';

const getColorByTheme = (theme) => {
  if (theme === THEME.LIGHT || theme === THEME.LIGHT_GRAY) {
    return COLORS.BOSTON_BLUE.PRIMARY;
  }
  return COLORS.NEUTRAL.WHITE;
};

const getBackgroundColorByTheme = (theme, isHover = false) => {
  if (theme === THEME.LIGHT) {
    return COLORS.NEUTRAL.WHITE;
  }
  if (theme === THEME.LIGHT_GRAY) {
    return COLORS.NEUTRAL.PALE_GREY;
  }
  if (theme === THEME.ERROR) {
    return COLORS.UI.ERROR;
  }
  return !isHover ? COLORS.BOSTON_BLUE.LIGHT : COLORS.BOSTON_BLUE.PRIMARY;
};

const Btn = styled.button`
  disabled: ${({ disabled }) => disabled || false};
  text-transform ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
  align-items: center;
  ${({ centered }) => (centered ? 'justify-content: center;' : '')}
  border: none;
  border-radius: 8px;
  padding: 8px 16px;
  display: flex;
  margin: auto;
  color: ${({ theme }) => getColorByTheme(theme)};
  background-color: ${({ theme }) => getBackgroundColorByTheme(theme)};
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => getBackgroundColorByTheme(theme, true)};
    ${({ theme }) =>
      theme === THEME.LIGHT || theme === THEME.LIGHT_GRAY
        ? 'text-decoration: underline;'
        : ''}
  }
  :disabled {
    background-color: ${COLORS.NEUTRAL.LIGHT_GREY};
    color: ${COLORS.NEUTRAL.TEXT_BLACK};
    cursor: not-allowed;
  }
  ${({ size }) => (size ? size : '')}
`;

const BtnIconLeft = styled.div`
  display: flex;
  margin-right: 16px;
`;

const BtnIconRight = styled.div`
  display: flex;
  margin-left: 8px;
`;

const btnSize = {
  lg: `
    padding: 16px 16px 16px 24px;
    width: 100%;
  `,
  sm: `
    padding: 0px 16px;
    width: 100%;
    justify-content: center;
  `,
  padd16: `
    padding: 16px;
    width: 100%;
    justify-content: center;
  `,
};

const Button = ({
  iconLeft,
  iconRight,
  type,
  disabled,
  uppercase,
  size,
  children,
  onClick,
  theme,
  centered = false,
}) => {
  return (
    <Btn
      disabled={disabled}
      type={type || 'button'}
      uppercase={uppercase}
      onClick={onClick}
      size={btnSize[size] || ''}
      theme={theme}
      centered={centered}
    >
      {iconLeft && <BtnIconLeft>{iconLeft}</BtnIconLeft>}
      {children}
      {iconRight && <BtnIconRight>{iconRight}</BtnIconRight>}
    </Btn>
  );
};

export default Button;
