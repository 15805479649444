import { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { INPUT_TYPE } from '../../../common/constants/constants';
import { InputShowPassword, InputStyled } from './Form.styles';

const Input = ({ input, onInputChange, value, hasError }) => {
  const [type, setType] = useState(INPUT_TYPE.TEXT);

  useEffect(() => {
    if (!isEmpty(input)) {
      setType(input.type);
    }
  }, [input]);

  return (
    <>
      <InputStyled
        type={type}
        name={input.name}
        disabled={input.disabled}
        required={input.required}
        value={value}
        maxLength={input.maxLength}
        minLength={input.minLength}
        pattern={input.pattern}
        placeholder={input.placeholder}
        onChange={(e) => onInputChange(input, e.target.value)}
        hasError={hasError}
      />
      {input.type === INPUT_TYPE.PASSWORD && input.allowShowPassword && (
        <InputShowPassword
          onClick={() => {
            setType(
              type === INPUT_TYPE.TEXT ? INPUT_TYPE.PASSWORD : INPUT_TYPE.TEXT
            );
          }}
        >
          {type === INPUT_TYPE.TEXT ? 'Hide password' : 'Show password'}
        </InputShowPassword>
      )}
    </>
  );
};

export default Input;
