export const CONTACT_OPTIONS_TYPE = {
  PHONE: 'PHONE',
  MAIL: 'MAIL',
  LINKED_IN: 'LINKED_IN',
};

export const CONTENT_TYPE = {
  CONTENT: 'CONTENT',
  CUSTOM_CONTENT: 'CUSTOM_CONTENT',
  IMAGE: 'IMAGE',
};

export const PORTFOLIO_KEY = 'LINKIT_PORTFOLIO_KEY_TOKEN';

export const INPUT_TYPE = {
  EMAIL: 'email',
  TEXT: 'text',
  TEXT_AREA: 'text_area',
  DATE: 'date',
  MONTH: 'month',
  GENERATED: 'generated',
  NUMBER: 'number',
  PASSWORD: 'password',
  PHONE_NUMBER: 'phone_number',
  PICTURE: 'picture',
  CHECKBOX: 'checkbox',
  CHECKBOX_LIST: 'checkbox_list',
  LIST: 'list',
};

export const IMAGE_TYPE = {
  CIRCULAR: 'CIRCULAR',
  SQUARE: 'SQUARE',
};

export const ALERT_TYPE = {
  DANGER: 'DANGER',
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  SUCCESS: 'SUCCESS',
};

export const DB_STATUS = {
  EXISTING: 'EXISTING',
  UPDATED: 'UPDATED',
  NEW: 'NEW',
  DELETED: 'DELETED',
};

export const THEME = {
  LIGHT: 'light',
  LIGHT_GRAY: 'light-gray',
  NEUTRAL: 'neutral',
  ERROR: 'error',
};

export const PROFILE_BUTTON_TYPE = {
  LINKED_IN: {
    name: 'LINKED_IN',
    label: 'LinkedIn',
    title: 'View linkedin-profile',
  },
  MAIL: {
    name: 'MAIL',
    label: 'Mail',
    address: 'mailto:',
    title: 'Send mail',
  },
  PHONE: {
    name: 'PHONE',
    label: 'Phone Call',
    address: 'tel:',
    title: 'Make phone call',
  },
};

export const ALERT_SECTION = {
  PAGE: 'PAGE',
  MODAL: 'MODAL',
};

export const COLUMN_LAYOUT = {
  DOUBLE_COLUMNS_LAYOUT: 'DOUBLE_COLUMNS_LAYOUT',
  SINGLE_COLUMN: 'SINGLE_COLUMN'
};

export const COLUMN_ALIGN = {
  NONE: 'NONE',
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
  CENTER: 'CENTER',
};
