import styled from 'styled-components';
import { SCREEN } from '../../../common/ui/screen/screen';
import {
  BodySmallRegular,
  COLORS,
  PreTitle,
  TitleH3,
} from '../../../common/ui/Theme/Theme.styles';

export const ProjectWrapper = styled.div`
  cursor: pointer;
  padding: 26px;
  background-color: ${COLORS.NEUTRAL.WHITE};
  border-radius 8px;
  height: calc(100% - 52px);
  :hover {
    box-shadow: 0px 4px 8px rgb(0 0 0 / 25%);
  }
`;

export const ProjectImgWrapper = styled.div`
  background: ${({ $backgroundColor }) =>
    $backgroundColor || COLORS.SILVER_TREE.LIGHT};
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  height: ${({ $size }) => $size === 'xsm' ? '160px' : '320px'};
  ${SCREEN.xsm} {
    height: 160px;
  }
`;

export const ProjectImg = styled.img`
  max-height: 100%;
`;

export const ProjectCustomerWrapper = styled.div`
  display: flex;
  margin-top: 16px;
  align-items: center;
  ${SCREEN.xxsm} {
    flex-direction: column;
    align-items: start;
  }
`;

export const ProjectCustomer = styled(PreTitle)`
  color: ${COLORS.NEUTRAL.DARK_GREY};
`;

export const TitleWrapper = styled.div`
  width: 100%;
  > p {
    display: inline-block;
    margin-right: 4px;
    :after {
      content: "•";
      margin-left: 4px;
    }
    :last-child:after {
      content: "";
    }
  }
`;

export const ProjectTitleWrapper = styled.div`
  display: flex;
  align-content: center;
`;

export const ProjectTitle = styled(TitleH3)`
  color: ${COLORS.NEUTRAL.TEXT_BLACK};
  display: inline-block;
`;

export const ProtectedProjectIconWrapper = styled.div``;

export const ProjectDescription = styled(BodySmallRegular)`
  color: ${COLORS.NEUTRAL.DARK_GREY};
  margin-top: 16px;
`;
