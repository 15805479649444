import { useState, useCallback } from 'react';
import { requestStatus } from '../../../common/constants/requestStatus';
import { getErrorMessages } from '../../../common/utils/errorSetter';
import { profileService } from '../../../common/services/profileService';

export function useFetchProfileSectionData() {
  const [sections, setSections] = useState([]);

  const [status, setStatus] = useState(requestStatus.Initial);

  const isLoading = status === requestStatus.Pending;

  const fetchProfileSectionData = useCallback(async (sectionId) => {
    setStatus(requestStatus.Pending);

    try {
      const profileSectionDataResponse = await profileService.getProfileSectionDataByProfileSectionId(sectionId);
      
      setSections(profileSectionDataResponse ?? []);

      setStatus(requestStatus.Done);
    } catch (error) {
      setStatus(requestStatus.Error);

      throw Error(
        getErrorMessages({
          error,
        })
      );
    }
  }, []);

  return {
    isLoading,
    sections,
    fetchProfileSectionData,
  };
}
