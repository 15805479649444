import { useState, createContext } from 'react';
import PortfolioWrapper from '../PortfolioWrapper/PortfolioWrapper';
import ErrorMessage from '../../../common/ui/ErrorPages/ErrorMessage';

export const PortfolioContext = createContext({
  userInformation: {},
  setUserInformation: () => {},
  error: false,
  setError: () => {},
  config: {},
});

function Home() {
  const [error, setError] = useState(null);
  const [userInformation, setUserInformation] = useState({});

  if (error) {
    return <ErrorMessage />;
  }

  return (
    <PortfolioContext.Provider
      value={{
        userInformation,
        setUserInformation,
        error,
        setError,
      }}
    >
      <PortfolioWrapper />
    </PortfolioContext.Provider>
  );
}

export default Home;
