import { Routes, Route } from 'react-router-dom';
import { AboutMeSectionListPage } from './AboutMeSectionListPage';
import { AboutMeSection } from './AboutMeSection';
import ErrorMessage from '../../../common/ui/ErrorPages/ErrorMessage';
import { AboutMeContent } from './AboutMeContent';

export function AboutMePage() {
  return (
    <Routes>
      <Route path={`/`} element={<AboutMeSectionListPage />} />
      <Route path={`/:id`} element={<AboutMeSection />} />
      <Route path={`/:id/content`} element={<AboutMeContent />} />
      <Route path='*' element={<ErrorMessage isNotFoundPage />} />
    </Routes>
  );
}
