import isEmpty from 'lodash/isEmpty';
import { CONTENT_TYPE } from '../../../common/constants/constants';
import CircleImage from '../../../common/ui/Image/CircleImage';
import TextDisplay from '../../../common/ui/Text/TextDisplay';
import { BodySubtitle, COLORS, PreTitle } from '../../../common/ui/Theme/Theme.styles';
import {
  SectionImage,
  SectionImageWrapper,
  SectionWrapper,
  SectionWrapperImg,
} from './Section.styles';

const SectionContent = ({ item }) => {
  if (item.type === CONTENT_TYPE.CONTENT) {
    return (
      <div>
        <TextDisplay text={item.body} id='about-header-text' />
        <br />
      </div>
    );
  } else if (item.type === CONTENT_TYPE.CUSTOM_CONTENT) {
    let preTitle;
    if (!isEmpty(item.preTitle)) {
      preTitle = item.preTitle;
    } else if (!isEmpty(item.startDate) || !isEmpty(item.endDate)) {
      if (!isEmpty(item.startDate)) {
        preTitle = item.startDate;
        if (isEmpty(item.endDate)) {
          preTitle = `${preTitle} - PRESENT`
        }
      }
      if (!isEmpty(item.endDate)) {
        preTitle = preTitle ? `${preTitle} - ${item.endDate}` : item.endDate;
      }
    }
    return (
      <SectionWrapper
        style={
          !isEmpty(item.image)
            ? {
                display: 'flex',
                alignItems: 'center',
                marginBottom: '40px',
              }
            : {}
        }
      >
        {!isEmpty(item.image) && (
          <SectionWrapperImg>
            <CircleImage
              centered
              alt={item.subTitle}
              src={item.image}
              wrapper='sm'
            />
          </SectionWrapperImg>
        )}
        <div>
          {preTitle && (
            <PreTitle
              color={COLORS.NEUTRAL.MEDIUM_GREY}
              style={{
                wordBreak: 'break-all',
              }}
            >
              {preTitle}
            </PreTitle>
          )}
          <BodySubtitle
            color={COLORS.NEUTRAL.TEXT_BLACK}
            style={{
              wordBreak: 'break-all',
            }}
          >
            {item.subTitle}
          </BodySubtitle>
          <PreTitle
            color={COLORS.NEUTRAL.DARK_GREY}
            style={{
              wordBreak: 'break-all',
            }}
          >
            {item.body}
          </PreTitle>
        </div>
      </SectionWrapper>
    );
  } else if (item.type === CONTENT_TYPE.IMAGE && !isEmpty(item.image)) {
    return (
      <SectionWrapper>
        <SectionImageWrapper>
          <SectionImage src={item.image} />
        </SectionImageWrapper>
      </SectionWrapper>
    );
  }
  return <div></div>;
};

export default SectionContent;
