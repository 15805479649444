import { useState, useCallback } from 'react';
import { requestStatus } from '../../common/constants/requestStatus';
import { projectService } from '../../common/services/projectService';
import { getErrorMessages } from '../utils/errorSetter';

export function useFetchProjects() {
  const [projects, setProjects] = useState([]);
  const [status, setStatus] = useState(requestStatus.Initial);

  const isLoading = status === requestStatus.Pending;

  const fetchProjects = useCallback(async () => {
    setStatus(requestStatus.Pending);

    try {
      const projectsResponse = await projectService.getProjects();

      setProjects(projectsResponse);

      setStatus(requestStatus.Done);
    } catch (error) {
      setStatus(requestStatus.Error);

      throw Error(
        getErrorMessages({
          error,
        })
      );
    }
  }, []);

  return {
    isLoading,
    projects,
    fetchProjects,
  };
}
