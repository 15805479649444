import { useState, useCallback } from 'react';
import { requestStatus } from '../../common/constants/requestStatus';
import { userService } from '../../common/services/userService';

export function useFetchUserInformation() {
  const [userInformation, setUserInformation] = useState({
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    role: '',
    linkedIn: '',
    profilePic: '',
    username: '',
    address: '',
  });
  const [status, setStatus] = useState(requestStatus.Initial);

  const isLoading = status === requestStatus.Pending;

  const fetchUserInformation = useCallback(async () => {
    setStatus(requestStatus.Pending);
    
    try {
      const res = await userService.getUserInformation();

      setUserInformation({
        ...res,
        linkedIn: res.linkedIn || '',
      });

      setStatus(requestStatus.Done);
    } catch (error) {
      setStatus(requestStatus.Error);

      throw error;
    }
  }, []);

  return {
    isLoading,
    userInformation,
    fetchUserInformation,
  };
}
