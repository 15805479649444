import { useCallback, useEffect, useState } from 'react';
import {
  ActionLink,
  CustomerImage,
  CustomerImageWrapper,
} from '../CustomerList/CustomerList.styles';
import DataTable from '../../../common/ui/DataTable/DataTable';
import { IMAGE_TYPE, INPUT_TYPE } from '../../../common/constants/constants';
import Modal from '../../../common/ui/Modal/Modal';
import { SmallBold } from '../../../common/ui/Theme/Theme.styles';
import ConfirmModalContent from '../ConfirmModalContent/ConfirmModalContent';
import { useFetchCustomers } from '../hooks/useFetchCustomers';
import BlockUi from '../../../common/ui/BlockUi/BlockUi';
import { CustomerCreateModal } from './CustomerCreateModal';
import { toastHelper } from '../../../common/utils/toastHelper';
import { useDeleteCustomer } from '../hooks/useDeleteCustomer';
import { useUpdateCustomer } from '../hooks/useUpdateCustomer';
import { useTranslation } from 'react-i18next';

const headers = [
  {
    id: 'title',
    label: 'home.headers.Title',
    type: INPUT_TYPE.TEXT,
    name: 'title',
    placeholder: 'Title',
    required: true,
    maxLength: 1000,
    isFormInput: true,
    customWidth: '50%',
  },
  {
    id: 'image',
    title: '',
    type: INPUT_TYPE.PICTURE,
    imageType: IMAGE_TYPE.SQUARE,
    name: 'image',
    placeholder: 'Image',
    isFormInput: true,
  },
  {
    id: 'actions',
    customWidth: '80px',
  },
];

export const CustomerList = () => {
  const { t } = useTranslation();

  const {
    isLoading: isFetchingCustomers,
    customers,
    fetchCustomers,
  } = useFetchCustomers();

  const { isLoading: isDeleting, deleteCustomer } = useDeleteCustomer();
  const { isLoading: isUpdating, updateCustomer } = useUpdateCustomer();

  const [customerToUpdate, setCustomerToUpdate] = useState(null);
  const [customerToDelete, setCustomerToDelete] = useState(null);
  const [isCreateModalOpened, setIsCreateModalOpened] = useState(false);

  const isLoading = isFetchingCustomers || isDeleting || isUpdating;

  const handleRemoveClick = (customer) => {
    setCustomerToDelete(customer);
  };

  const handleRemoveCustomer = async () => {
    try {
      await deleteCustomer(customerToDelete.id);

      setCustomerToDelete(null);

      toastHelper.success(
        t('home.messages.success.Customer deleted successfully')
      );

      getCustomers();
    } catch (error) {
      toastHelper.error(error, t);
    }
  };

  const handleConfirmUpdate = async (id, formValues) => {
    try {
      await updateCustomer({ id, ...formValues });

      setCustomerToUpdate(null);

      toastHelper.success(
        t('home.messages.success.Customer updated successfully')
      );

      getCustomers();
    } catch (error) {
      toastHelper.error(error, t);
    }
  };

  const getCustomers = useCallback(async () => {
    setCustomerToUpdate(null);
    setCustomerToDelete(null);

    try {
      await fetchCustomers();
    } catch (error) {
      toastHelper.error(error, t);
    }
  }, [fetchCustomers, t]);

  function handleClickCreateCustomer() {
    setIsCreateModalOpened(true);
  }

  function handleCompleteCreate() {
    getCustomers();
    setIsCreateModalOpened(false);
  }

  function handleEditClick(customer, index) {
    setCustomerToUpdate({
      title: customer.title,
      image: customer.image,
      index,
    });
  }

  useEffect(() => {
    getCustomers();
  }, [getCustomers]);

  return (
    <BlockUi blocked={isLoading}>
      {isCreateModalOpened && (
        <CustomerCreateModal
          headers={headers.filter((header) => header.isFormInput)}
          onCancel={() => setIsCreateModalOpened(false)}
          onCompleted={handleCompleteCreate}
        />
      )}
      {customerToDelete && (
        <Modal>
          <ConfirmModalContent
            body={t('home.customerList.delete', {
              customer: customerToDelete.title,
            })}
            onCancel={() => setCustomerToDelete(null)}
            onCofirm={handleRemoveCustomer}
          />
        </Modal>
      )}
      <ActionLink>
        <span onClick={handleClickCreateCustomer}>
          {t('home.links.Add new customer')}
        </span>
      </ActionLink>
      <DataTable
        headers={headers.map((header) => ({
          ...header,
          label: t(header.label),
        }))}
        rows={customers.map((customer, index) => ({
          ...customer,
          image: (
            <CustomerImageWrapper>
              {customer.image && (
                <CustomerImage src={customer.image} alt={customer.title} />
              )}
            </CustomerImageWrapper>
          ),
          actions: (
            <div>
              <ActionLink>
                <span
                  onClick={() => {
                    handleEditClick(customer, index);
                  }}
                >
                  <SmallBold>{t('common.edit')}</SmallBold>
                </span>
              </ActionLink>
              <ActionLink>
                <span onClick={() => handleRemoveClick(customer)}>
                  <SmallBold>{t('common.delete')}</SmallBold>
                </span>
              </ActionLink>
            </div>
          ),
        }))}
        updateVal={customerToUpdate}
        onCancelUpdate={() => setCustomerToUpdate(null)}
        onConfirmUpdate={handleConfirmUpdate}
        onDelete={handleRemoveClick}
      />
    </BlockUi>
  );
};
