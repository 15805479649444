import isEmpty from 'lodash/isEmpty';
import { Fragment, useEffect, useContext, useCallback, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ALERT_TYPE } from '../../../common/constants/constants';
import { PortfolioContext } from '../Home/Home';
import {
  getProjectByPortfolioIdByProjectId,
  getProjectByPortfolioIdByProjectIdByPassword,
} from '../../../common/services/projectService';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import Alert from '../../../common/ui/Alert/Alert';
import {
  Container,
  FlexBoxWrapper,
  MainWrapper,
  TitleH1,
} from '../../../common/ui/Theme/Theme.styles';
import ProjectAuthForm from '../ProjectAuthForm/ProjectAuthForm';
import SingleProject from '../SingleProject/SingleProject';
import ProjectSection from './ProjectSection';
import {
  OtherProject,
  OtherProjectsWrapper,
  ProjectAuthWrapper,
  ProjectSectionRow,
  ProjectSectionWrapper,
} from './Project.styles';

const Project = () => {
  const { userInformation } = useContext(PortfolioContext);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [projectPassword, setProjectPassword] = useState('');
  const [projectAuthError, setProjectAuthError] = useState(null);
  const [isSubmittinPassword, setIsSubmittinPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [project, setProject] = useState(null);
  const { projectId, userId } = useParams();

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const projectRes = await getProjectByPortfolioIdByProjectId(
        userInformation.portfolioId,
        projectId
      );
      if (projectRes) {
        setProject({
          ...projectRes,
        });
      } else {
        throw new Error();
      }
    } catch (e) {
      setError(
        'There was an error processing the request, please try again later'
      );
    }
    setIsLoading(false);
  }, [setIsLoading, setError, projectId, userInformation.portfolioId]);

  useEffect(() => {
    setProject(null);
    fetchData();
  }, [fetchData]);

  const handleOnAuthProject = useCallback(
    async (e) => {
      e.preventDefault();
      setIsSubmittinPassword(true);
      setError(null);
      try {
        const projectRes = await getProjectByPortfolioIdByProjectIdByPassword(
          userInformation.portfolioId,
          projectId,
          projectPassword
        );
        if (projectRes) {
          setProject({
            ...projectRes,
          });
          setProjectPassword('');
        } else {
          throw new Error();
        }
      } catch (e) {
        setProjectAuthError('Password is not valid');
      }
      setIsSubmittinPassword(false);
    },
    [userInformation.portfolioId, projectId, projectPassword]
  );

  const handleRequestPassword = useCallback(() => {
    if (isEmpty(project)) {
      return;
    }
    const subject = 'Request Password for Project';
    const body = `Hello, I want to request the password for project ${project.data.title} that is in your portfolio.`;
    window.open(
      `mailto:${userInformation.email}?subject=${subject}&body=${body}`,
      '_blank'
    );
  }, [userInformation.email, project]);

  if (isLoading) {
    return <></>;
  }

  const renderBody = () => {
    if (error) {
      return (
        <Container>
          <Alert type={ALERT_TYPE.ERROR}>{error}</Alert>
        </Container>
      );
    }
    return (
      <Container>
        <ProjectSectionRow>
          <TitleH1>{project.data.title}</TitleH1>
        </ProjectSectionRow>
        {(project.sections || []).map((section) =>
          section.columns.length > 0 ? (
            <ProjectSection
              key={`project-section-${section.id}`}
              section={section}
            />
          ) : (
            <Fragment key={`project-section-${section.id}`}></Fragment>
          )
        )}
        {project.data.isProtected ? (
          <FlexBoxWrapper>
            <ProjectAuthWrapper>
              <ProjectAuthForm
                password={projectPassword}
                setPassword={setProjectPassword}
                error={projectAuthError}
                isLoading={isSubmittinPassword}
                onSubmit={handleOnAuthProject}
                onRequestPassword={handleRequestPassword}
              />
            </ProjectAuthWrapper>
          </FlexBoxWrapper>
        ) : (
          // It will display the project list at the bottom
          <OtherProjectsWrapper>
            {(project.otherProjects || []).map((other) => (
              <OtherProject key={`other-project-${other.id}`}>
                <SingleProject
                  projectId={other.id}
                  customer={other.customer}
                  title={other.title}
                  description={other.description}
                  image={other.image}
                  isProtected={other.isProtected}
                  backgroundColor={other.backgroundColor}
                  size='xsm'
                  onButtonClick={(id) => {
                    window.scrollTo(0, 0);
                    navigate(`/${userId}/project/${id}`);
                  }}
                />
              </OtherProject>
            ))}
          </OtherProjectsWrapper>
        )}
      </Container>
    );
  };

  return (
    <ProjectSectionWrapper>
      <MainWrapper>
        <Header />
        {renderBody()}
        <Footer withContact customMargin='80px' />
      </MainWrapper>
    </ProjectSectionWrapper>
  );
};

export default Project;
