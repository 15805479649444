import { useState } from 'react';
import { requestStatus } from '../../../common/constants/requestStatus';
import { getErrorMessages } from '../../../common/utils/errorSetter';
import { userService } from '../../../common/services/userService';

export function useForgotPassword() {
  const [status, setStatus] = useState(requestStatus.Initial);

  const isLoading = status === requestStatus.Pending;

  async function forgotPassword(email) {
    setStatus(requestStatus.Pending);

    try {
      await userService.forgotPassword(email);

      setStatus(requestStatus.Done);
    } catch (error) {
      setStatus(requestStatus.Error);

      throw Error(
        getErrorMessages({
          error,
        })
      );
    }
  }

  return {
    isLoading,
    forgotPassword,
  };
}
