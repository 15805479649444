import { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { requestStatus } from '../../../common/constants/requestStatus';
import { login } from '../../../common/services/userService';

export function useLogin() {
  const [status, setStatus] = useState(requestStatus.Initial);

  const isLoading = status === requestStatus.Pending;

  async function authUser(email, password) {
    if (isEmpty(email)) {
      throw Error('Email is required');
    }

    if (isEmpty(password)) {
      throw Error('Password is required');
    }

    setStatus(requestStatus.Pending);
    
    try {
      const loginData = await login(email, password);

      setStatus(requestStatus.Done);

      return loginData;
    } catch (err) {
      let error = 'There was an error trying to login, please try again later';

      if ((err.response || {}).status === 404) {
        error = 'Wrong credentials';
      }

      setStatus(requestStatus.Error);

      throw Error(error);
    }
  }

  return {
    status,
    isLoading,
    authUser,
  }
}