import styled from 'styled-components';

export const AdminWrapper = styled.div`
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
`;

export const AuthFormWrapper = styled.div`
  width: 500px;
`;

export const LINKITLogo = styled.div`
  text-align: center;
  margin-bottom: 32px;
  > img {
    height: 100px;
  }
`;
