import isEmpty from 'lodash/isEmpty';
import { INPUT_TYPE } from '../constants/constants';

const getNonNullValues = (currentValues, newValues) => {
  const updatedValues = { ...currentValues };
  Object.keys(newValues).forEach((key) => {
    updatedValues[key] = newValues[key] || currentValues[key];
  });
  return updatedValues;
};

const getErrorMessage = (input, value, match) => {
  let error;
  if (!input.disabled) {
    if (input.required && isEmpty(value)) {
      error = `${input.label} is required`;
    } else if (input.maxLength > 0 && !isEmpty(value) && value.length > input.maxLength) {
      error = `${input.label} should have a max length of ${input.maxLength}`;
    } else if (!isEmpty(value) && input.minLength > 0 && value.length < input.minLength) {
      error = `${input.label} should have a min length of ${input.minLength}`;
    } else if (input.type === INPUT_TYPE.NUMBER && !value.match(/^[0-9]*$/)) {
      error = `${input.label} should be a numeric value`;
    } else if (match !== undefined && value !== match) {
      error = `${input.matchLabel} doesn't match`;
    }
  }
  return error;
};

const formHelper = {
  getNonNullValues,
  getErrorMessage,
};

export default formHelper;
