import { useState, useCallback } from 'react';
import { requestStatus } from '../../../common/constants/requestStatus';
import { projectService } from '../../../common/services/projectService';
import { getErrorMessages } from '../../../common/utils/errorSetter';

export function useUpdateProject() {
  const [status, setStatus] = useState(requestStatus.Initial);

  const isLoading = status === requestStatus.Pending;

  const updateProject = useCallback(
    async ({
      id,
      title,
      description,
      customer,
      password,
      backgroundColor,
      image,
      isProtected,
    }) => {
      setStatus(requestStatus.Pending);

      try {
        const updateProjectResponse = await projectService.updateProject(
          id,
          title,
          description,
          customer,
          password,
          backgroundColor,
          image,
          isProtected
        );

        if (!updateProjectResponse) {
          // Throwing an error if response is not success
          throw new Error('');
        }

        setStatus(requestStatus.Done);
      } catch (error) {
        setStatus(requestStatus.Error);

        throw Error(
          getErrorMessages({
            error,
          })
        );
      }
    },
    []
  );

  async function updateProjectSection(id, sections) {
    setStatus(requestStatus.Pending);

      try {
        const updateProjectResponse = await projectService.saveProjectSections(
          id,
          sections
        );

        if (!updateProjectResponse) {
          // Throwing an error if response is not success
          throw new Error('');
        }

        setStatus(requestStatus.Done);
      } catch (error) {
        setStatus(requestStatus.Error);

        throw Error(
          getErrorMessages({
            error,
          })
        );
      }
  }

  return {
    isLoading,
    updateProject,
    updateProjectSection,
  };
}
