import { ContactOptionText, ContactOptionWrapper } from './Contact.styles';
import Button from '../../../common/ui/Button/Button';
import { CONTACT_OPTIONS_TYPE } from '../../../common/constants/constants';

const ContactOptions = ({ options }) => {
  return (
    <>
      {options.map((option) => (
        <ContactOptionWrapper key={`contact-option-${option.type}`}>
          <Button
            iconLeft={option.iconLeft}
            size='lg'
            onClick={() => {
              if (option.type === CONTACT_OPTIONS_TYPE.PHONE) {
                window.location = `tel:${option.value}`;
              } else if (option.type === CONTACT_OPTIONS_TYPE.MAIL) {
                window.location = `mailto:${option.value}`;
              } else if (option.type === CONTACT_OPTIONS_TYPE.LINKED_IN) {
                window.open(
                  option.value,
                  '_blank'
                );
              }
            }}
          >
            <ContactOptionText>{option.text}</ContactOptionText>
          </Button>
        </ContactOptionWrapper>
      ))}
    </>
  );
};

export default ContactOptions;
