const PhoneIcon = ({ fill }) => (
  <svg
    width='33'
    height='32'
    viewBox='0 0 33 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.32716 14.3867C11.2472 18.16 14.3405 21.24 18.1138 23.1733L21.0472 20.24C21.4072 19.88 21.9405 19.76 22.4072 19.92C23.9005 20.4133 25.5138 20.68 27.1672 20.68C27.9005 20.68 28.5005 21.28 28.5005 22.0133V26.6667C28.5005 27.4 27.9005 28 27.1672 28C14.6472 28 4.50049 17.8533 4.50049 5.33333C4.50049 4.6 5.10049 4 5.83382 4H10.5005C11.2338 4 11.8338 4.6 11.8338 5.33333C11.8338 7 12.1005 8.6 12.5938 10.0933C12.7405 10.56 12.6338 11.08 12.2605 11.4533L9.32716 14.3867Z'
      fill={fill || 'white'}
    />
  </svg>
);

export default PhoneIcon;
