import isEmpty from 'lodash/isEmpty';
import { useEffect, useMemo, useState } from 'react';
import {
  ALERT_TYPE,
  IMAGE_TYPE,
  INPUT_TYPE,
  THEME,
} from '../../../common/constants/constants';
import Button from '../Button/Button';
import CircleImage from '../Image/CircleImage';
import { BodySmallRegular, SmallBold } from '../Theme/Theme.styles';
import {
  ButtonWrapper,
  CheckboxWrapper,
  InputPictureWrapper,
  InputWrapper,
  PictureWrapper,
  SquareImage,
} from './Form.styles';
import ImageUploader from './ImageUploader';
import Input from './Input';
import TextArea from './TextArea';
import Alert from '../Alert/Alert';
import formHelper from '../../utils/formHelper';
import Select from './Select';
import PhoneNumber from './PhoneNumber';
import { useTranslation } from 'react-i18next';

const Form = ({
  dataInputs,
  values,
  onSubmit,
  btnText,
  onlyModifiedFields,
  onCancel,
  onDelete,
}) => {
  const { t } = useTranslation();

  const [inputValues, setInputValues] = useState({});
  const [inputList, setInputList] = useState([]);
  const [errors, setErrors] = useState({});

  const hasError = useMemo(
    () => Boolean(Object.keys(errors).find((key) => errors[key])),
    [errors]
  );

  useEffect(() => {
    setInputValues(
      !isEmpty(values)
        ? {
            ...values,
          }
        : {}
    );
  }, [values]);

  useEffect(() => {
    if (!isEmpty(dataInputs)) {
      setInputList([...dataInputs]);
      const errorsToSet = {};
      dataInputs.forEach((val) => {
        errorsToSet[val.id] = null;
      });
      setErrors(errorsToSet);
    }
  }, [dataInputs]);

  const onInputChange = (input, value) => {
    const error = formHelper.getErrorMessage(
      input,
      value,
      inputValues[input.shouldMatch]
    );

    if (!isEmpty(error)) {
      const errorsToSet = { ...errors };
      errorsToSet[input.id] = error;

      setErrors(errorsToSet);
    } else if (!isEmpty(errors[input.id])) {
      const errorsToSet = { ...errors };
      errorsToSet[input.id] = null;

      setErrors(errorsToSet);
    }

    if (input.type === INPUT_TYPE.LIST && input.allowCustom) {
      if (!input.name.includes('Custom')) {
        return setInputValues((val) => ({
          ...val,
          [`${input.name}Custom`]: null,
          [input.name]: value,
        }));
      }
    }

    setInputValues((val) => ({
      ...val,
      [input.name]: value,
    }));
  };

  const onCheckboxChange = (input, value) => {
    setInputValues((val) => ({
      ...val,
      [input.name]: value,
    }));
  };

  const onCheckboxListChange = (checkBoxItem, input, isChecked) => {
    setInputValues((currValues) => {
      const newValues = { ...currValues };
      if (
        newValues[input.name] === undefined ||
        newValues[input.name] === null
      ) {
        newValues[input.name] = [];
      }
      if (isChecked) {
        newValues[input.name] = newValues[input.name].concat([
          {
            address: checkBoxItem.address,
            title: checkBoxItem.title,
            name: checkBoxItem.name,
          },
        ]);
      } else {
        newValues[input.name] = newValues[input.name].filter(
          (el) => el.name !== checkBoxItem.name
        );
      }
      return newValues;
    });
  };

  const renderInput = (input) => {
    const key = `input-${input.id}`;
    if (input.type === INPUT_TYPE.CHECKBOX) {
      return (
        <InputWrapper key={key}>
          <CheckboxWrapper key={`${key}-checkbox-${input.name}`}>
            <SmallBold
              onClick={() => onCheckboxChange(input, !inputValues[input.name])}
            >
              {input.label}
            </SmallBold>
            <input
              type='checkbox'
              name={input.name}
              checked={Boolean(inputValues[input.name])}
              onChange={(e) => onCheckboxChange(input, e.target.checked)}
            />
          </CheckboxWrapper>
        </InputWrapper>
      );
    } else if (input.type === INPUT_TYPE.CHECKBOX_LIST) {
      return (
        <InputWrapper key={key}>
          <SmallBold>{input.label}</SmallBold>
          {input.items.map((item) => (
            <CheckboxWrapper key={`${key}-checkbox-${item.name}`}>
              <SmallBold
                onClick={() =>
                  onCheckboxListChange(
                    item,
                    input,
                    isEmpty(
                      inputValues[input.name].filter(
                        (el) => el.name === item.name
                      )
                    )
                  )
                }
              >
                {item.label}
              </SmallBold>
              <input
                type='checkbox'
                name={item.name}
                checked={Boolean(
                  (inputValues[input.name] || []).find(
                    (el) => el.name === item.name
                  )
                )}
                onChange={(e) =>
                  onCheckboxListChange(item, input, e.target.checked)
                }
              />
            </CheckboxWrapper>
          ))}
        </InputWrapper>
      );
    } else if (input.type === INPUT_TYPE.PICTURE) {
      return (
        <div key={key}>
          <div>{input.label}</div>
          <InputPictureWrapper>
            <PictureWrapper>
              {inputValues[input.name] &&
                (input.imageType === IMAGE_TYPE.SQUARE ? (
                  <SquareImage src={inputValues[input.name]} alt='Image' />
                ) : (
                  <CircleImage
                    src={inputValues[input.name]}
                    alt='Image'
                    wrapper='lg'
                    bordered
                  />
                ))}
            </PictureWrapper>
            <ImageUploader input={input} onInputChange={onInputChange} />
          </InputPictureWrapper>
        </div>
      );
    } else if (input.type === INPUT_TYPE.TEXT_AREA) {
      return (
        <InputWrapper key={key}>
          <SmallBold hasError={Boolean(errors[input.id])}>
            {input.label}
            {input.required && ' *'}
          </SmallBold>
          <TextArea
            input={input}
            onChange={onInputChange}
            value={inputValues[input.name]}
            hasError={Boolean(errors[input.id])}
          />
        </InputWrapper>
      );
    } else if (input.type === INPUT_TYPE.LIST) {
      return (
        <InputWrapper key={key}>
          <SmallBold hasError={Boolean(errors[input.id])}>
            {input.label}
            {input.required && ' *'}
          </SmallBold>
          <Select
            input={input}
            items={input.items}
            value={inputValues[input.name]}
            customValue={inputValues[`${input.name}Custom`]}
            onInputChange={onInputChange}
          />
        </InputWrapper>
      );
    } else if (input.type === INPUT_TYPE.PHONE_NUMBER) {
      return (
        <InputWrapper key={key}>
          <SmallBold hasError={Boolean(errors[input.id])}>
            {input.label}
            {input.required && ' *'}
          </SmallBold>
          <PhoneNumber
            input={input}
            onInputChange={onInputChange}
            value={inputValues[input.name]}
            hasError={Boolean(errors[input.id])}
          />
        </InputWrapper>
      );
    }

    return (
      <InputWrapper key={key}>
        <SmallBold hasError={Boolean(errors[input.id])}>
          {input.label}
          {input.required && ' *'}
        </SmallBold>
        <Input
          input={input}
          onInputChange={onInputChange}
          value={inputValues[input.name]}
          hasError={Boolean(errors[input.id])}
        />
        {input.helperText && (
          <BodySmallRegular>{input.helperText}</BodySmallRegular>
        )}
      </InputWrapper>
    );
  };

  const getChangedFields = (currentValues, storedValues) => {
    const changedFields = {};
    Object.keys(currentValues).forEach((key) => {
      if (Array.isArray(currentValues[key])) {
        changedFields[key] = currentValues[key] || [];
      } else if (
        key !== 'index' &&
        currentValues[key] !== undefined &&
        currentValues[key] !== null
      ) {
        if (typeof currentValues[key] === 'boolean') {
          if (currentValues[key] !== storedValues[key]) {
            changedFields[key] = currentValues[key];
          }
        } else if (
          currentValues[key].trimStart().trim() !== storedValues[key]
        ) {
          changedFields[key] = currentValues[key].trimStart().trim();
        }
      }
    });
    return changedFields;
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    const errorsToSet = {};
    inputList.forEach((input) => {
      const error = formHelper.getErrorMessage(
        input,
        inputValues[input.name],
        inputValues[input.shouldMatch]
      );
      if (!isEmpty(error)) {
        errorsToSet[input.id] = error;
      }
    });
    if (!isEmpty(errorsToSet)) {
      setErrors(errorsToSet);
      window.scrollTo(0, 0);
      return;
    }
    if (onlyModifiedFields) {
      const changedFields = getChangedFields(inputValues, values);
      onSubmit(changedFields);
      return;
    }
    onSubmit({
      ...inputValues,
    });
  };

  return (
    <form onSubmit={onFormSubmit}>
      {hasError && (
        <Alert type={ALERT_TYPE.ERROR}>
          {Object.keys(errors).map((key) => (
            <div key={`error-form-${key}`}>{errors[key]}</div>
          ))}
        </Alert>
      )}
      {inputList.map((input) => renderInput(input))}
      <ButtonWrapper>
        {onDelete && (
          <Button theme={THEME.ERROR} type='button' onClick={onDelete}>
            {t('common.delete')}
          </Button>
        )}
        {onCancel && (
          <Button type='button' onClick={onCancel}>
            {t('common.cancel')}
          </Button>
        )}
        <Button type='submit' disabled={hasError}>
          {btnText || t('common.submit')}
        </Button>
      </ButtonWrapper>
    </form>
  );
};

export default Form;
