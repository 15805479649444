import axios from 'axios';
import axiosInstance from './interceptor';

export const getProjectByPortfolioIdByProjectId = async (
  portfolioId,
  projectId
) => {
  const projectData = await axios.get(
    `${process.env.REACT_APP_API_URL}/portfolio/${portfolioId}/project/${projectId}`
  );

  return projectData.data;
};

export const getProjectByPortfolioIdByProjectIdByPassword = async (
  portfolioId,
  projectId,
  password
) => {
  const projectData = await axios.post(
    `${process.env.REACT_APP_API_URL}/portfolio/${portfolioId}/project/${projectId}`,
    {
      password,
    }
  );

  return projectData.data;
};

export const getProjects = async () => {
  const projectData = await axiosInstance.get(
    `${process.env.REACT_APP_API_URL}/project`
  );

  return projectData.data;
};

export const createProject = async (
  title,
  description,
  customer,
  password,
  backgroundColor,
  image,
  isProtected
) => {
  const projectData = await axiosInstance.post(
    `${process.env.REACT_APP_API_URL}/project`,
    {
      title,
      description,
      customer,
      password,
      backgroundColor,
      image,
      isProtected,
    }
  );

  return projectData.data;
};

export const updateProject = async (
  id,
  title,
  description,
  customer,
  password,
  backgroundColor,
  image,
  isProtected
) => {
  const projectData = await axiosInstance.patch(
    `${process.env.REACT_APP_API_URL}/project/${id}`,
    {
      title,
      description,
      customer,
      password,
      backgroundColor,
      image,
      isProtected,
    }
  );

  return projectData.data;
};

export const updateOrder = async (data) => {
  const projectData = await axiosInstance.patch(
    `${process.env.REACT_APP_API_URL}/project/order`,
    {
      data,
    }
  );

  return projectData.data;
};

export const deleteProject = async (id) => {
  const projectData = await axiosInstance.delete(
    `${process.env.REACT_APP_API_URL}/project/${id}`
  );

  return projectData.data;
};

export const getProjectByProjectId = async (projectId) => {
  const projectData = await axiosInstance.get(
    `${process.env.REACT_APP_API_URL}/project/${projectId}`
  );

  return projectData.data;
};

export const saveProjectSections = async (projectId, sections) => {
  const projectData = await axiosInstance.post(
    `${process.env.REACT_APP_API_URL}/project/${projectId}`,
    {
      sections,
    }
  );

  return projectData.data;
};

export const projectService = {
  getProjects,
  updateOrder,
  updateProject,
  createProject,
  deleteProject,
  getProjectByProjectId,
  saveProjectSections,
};
