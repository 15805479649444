import { useState, useCallback } from 'react';
import { requestStatus } from '../../../common/constants/requestStatus';
import { getErrorMessages } from '../../../common/utils/errorSetter';
import { profileService } from '../../../common/services/profileService';

export function useUpdateProfileSectionsOrder() {
  const [status, setStatus] = useState(requestStatus.Initial);

  const isLoading = status === requestStatus.Pending;

  const updateProfileSectionsOrder = useCallback(async (sections) => {
    setStatus(requestStatus.Pending);

    try {
      // New order will be gotten from the sections array
      const body = sections.map((el, index) => ({
        ...el,
        order: index + 1,
      }));

      const updateOrderResponse = await profileService.updateOrder(body);

      if (!updateOrderResponse) {
        // Throwing an error if response is not success
        throw new Error('');
      }

      setStatus(requestStatus.Done);
    } catch (error) {
      setStatus(requestStatus.Error);

      throw Error(
        getErrorMessages({
          error,
        })
      );
    }
  }, []);

  return {
    isLoading,
    updateProfileSectionsOrder,
  };
}
