import styled from 'styled-components';
import { SCREEN } from '../screen/screen';

export const OverlayWrapper = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgb(103 102 107 / 70%);
  top: 0;
  left: 0;
  z-index: 999;
`;

export const ModalWrapper = styled.div`
  position: absolute;
  margin: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 650px;
  background: white;
  padding: 24px;
  border-radius: 5px;
  min-height: 150px;
  ${SCREEN.sm} {
    min-width: initial;
    width: 85%;
  }
  ${SCREEN.xxsm} {
    min-width: initial;
    width: 80%;
  }
`;
