import { useState, useCallback } from 'react';
import { requestStatus } from '../../../common/constants/requestStatus';
import { getErrorMessages } from '../../../common/utils/errorSetter';
import { userService } from '../../../common/services/userService';

export function useFetchIsForgotPasswordTokenValid() {
  const [status, setStatus] = useState(requestStatus.Initial);

  const isLoading = status === requestStatus.Pending;

  const fetchIsTokenValid = useCallback(async (token) => {
    setStatus(requestStatus.Pending);

    try {
      const isTokenValid = await userService.isForgotPasswordTokenValid(token);

      setStatus(requestStatus.Done);

      return isTokenValid;
    } catch (error) {
      setStatus(requestStatus.Error);

      throw Error(
        getErrorMessages({
          error,
        })
      );
    }
  }, []);

  return {
    isLoading,
    fetchIsTokenValid,
  };
}
