import styled from 'styled-components';
import { SCREEN } from '../screen/screen';
import { BodySmallRegular, COLORS } from '../Theme/Theme.styles';

export const InputWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
  align-items: center;
  width: 100%;

  > p {
    width: 100%;
  }

  > select {
    height: 40px;
  }
`;

export const InputShowPassword = styled(BodySmallRegular)`
  color: ${COLORS.BOSTON_BLUE.SECONDARY};
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

export const InputStyled = styled.input`
  padding: 8px;
  border: 1px solid ${COLORS.NEUTRAL.DARK_GREY};
  border-radius: 8px;
  width: 100%;
  ${({ hasError }) =>
    hasError
      ? `border: 1px solid ${COLORS.UI.DANGER}; outline: ${COLORS.UI.DANGER};`
      : ''}
`;

export const TextAreaStyled = styled.textarea`
  padding: 8px;
  border: none;
  border: 1px solid ${COLORS.NEUTRAL.DARK_GREY};
  border-radius: 8px;
  width: 100%;
  height: 75px;
  resize: none;
`;

export const ButtonWrapper = styled.div`
  text-align: right;
  width: 100%;
  > button {
    display: inline-block;
    margin-right: 16px;
    :last-child {
      margin-right: 0px;
    }
  }
`;

export const InputPictureWrapper = styled.div`
  display: flex;
  align-items: center;
  ${SCREEN.xsm} {
    flex-direction: column;
  }
`;

export const PictureWrapper = styled.div`
  min-width: 65px;

  ${SCREEN.xsm} {
    margin-bottom: 16px;
  }

  :first-child {
    margin-right: 15px;
  }
`;

export const InputFileImage = styled.input`
  position: absolute;
  margin: 0;
  padding: 0;
  outline: none;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
`;

export const InputFileWrapper = styled.div`
  position: relative;
  height: 150px;
  width: 250px;
  border: 1px dashed black;
  display: flex;
  justify-content: center;
  align-items: center;

  > span {
    text-align: center;
  }
`;

export const SquareImage = styled.img`
  max-width: 150px;
  max-height: 150px;
`;

export const CheckboxWrapper = styled.div`
  display: inline-block;
  min-width: 50px;
  margin-right: 16px;
  text-align: center;

  > p,
  input {
    cursor: pointer;
  }
`;

export const ColorPreview = styled.div`
  width: 40px;
  height: 40px;
  margin-left: 8px;
  background: ${({ background }) => (background ? background : 'transparent')};
`;

export const PhoneNumberInput = styled.div`
  input {
    padding: 8px;
    border: 1px solid ${COLORS.NEUTRAL.DARK_GREY};
    border-radius: 8px;
  }
`;

export const SelectCustomOptionWrapper = styled.div`
  margin-left: 8px;
  margin-right: 16px;

  ${InputStyled} {
    height: 40px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;