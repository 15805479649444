import { useTranslation } from 'react-i18next';

import Form from '../../../common/ui/Form/Form';
import LogoImg from '../../../assets/img/logo.svg';
import { v4 as uuidv4 } from 'uuid';
import { INPUT_TYPE } from '../../../common/constants/constants';
import { Logo, Title } from './ForgotPasswordPage.styles';
import { toastHelper } from '../../../common/utils/toastHelper';
import { useForgotPassword } from '../hooks/useForgotPassword';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AdminWrapper, AuthFormWrapper } from '../Admin.styles';
import BlockUi from '../../../common/ui/BlockUi/BlockUi';
import { Container } from '../../../common/ui/Theme/Theme.styles';

const inputs = [
  {
    id: uuidv4(),
    label: 'Email',
    type: INPUT_TYPE.EMAIL,
    name: 'email',
    required: true,
  },
];

export function ForgotPasswordForm() {
  const { t } = useTranslation();

  const { isLoading, forgotPassword } = useForgotPassword();
  const navigate = useNavigate();

  const [data] = useState({
    email: '',
  });

  async function handleForgotPassword({ email }) {
    try {
      await forgotPassword(email);

      toastHelper.success(t('forgotPassword.messages.success'));

      navigate('/admin');
    } catch (error) {
      toastHelper.error(error, t);
    }
  }

  function handleBack() {
    navigate(-1);
  }

  return (
    <Container>
      <BlockUi blocked={isLoading}>
        <AdminWrapper>
          <AuthFormWrapper>
            <Logo src={LogoImg} alt='LINKT' />
            <Title>{t('forgotPassword.title')}</Title>
            <Form
              dataInputs={inputs}
              values={data}
              onSubmit={handleForgotPassword}
              onCancel={handleBack}
            />
          </AuthFormWrapper>
        </AdminWrapper>
      </BlockUi>
    </Container>
  );
}
