import i18n from 'i18next';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import { getMessages as getEnMessages } from '../../../assets/intl/en/en';
import { getMessages as getNlMessages } from '../../../assets/intl/nl/nl';

export function setLanguage(language) {
  localStorage.setItem('language', language);
}

export function getLanguage() {
  return localStorage.getItem('language') || 'nl';
}

const currentLanguage = getLanguage();

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: getEnMessages(),
    },
    nl: { translation: getNlMessages() },
  },
  lng: currentLanguage,
  fallbackLng: currentLanguage,

  interpolation: {
    escapeValue: false,
  },
});

export function IntlProvider({ children }) {
  return (
    <I18nextProvider i18n={i18n} defaultNS={'translation'}>
      {children}
    </I18nextProvider>
  );
}
