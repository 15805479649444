import styled from 'styled-components';
import FooterImg from '../../../assets/img/footer.png';
import { SCREEN } from '../../../common/ui/screen/screen';
import {
  COLORS,
  Container,
  ContainerColumn,
  ContainerRow,
  TitleH3,
} from '../../../common/ui/Theme/Theme.styles';

// Adding -45px that are added in FooterProfilePicture
// 40px because we need 64px to match Figma, there are already 24px in the bottom of the work cards
export const FooterWrapper = styled.div`
  margin-top: ${({ $noMargin, $customMargin }) => {
    if ($noMargin) {
      return '0px';
    }
    if ($customMargin) {
      return $customMargin;
    }
    return '85px';
  }};
`;

export const FooterContactWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  background-image: url(${FooterImg});
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  flex-wrap: wrap;
`;

const FooterSection = styled.div`
  flex-basis: 100%;
  width: 100%;
`;

export const FooterProfilePicture = styled(FooterSection)`
  margin-top: -45px;
  height: 160px;
`;

export const FooterContact = styled(FooterSection)`
  text-align: center;
  margin-top: 16px;
  padding-bottom: 64px;
  ${({ withContactImg }) => (!withContactImg ? 'padding-top: 64px;' : '')}
`;

export const FooterContactText = styled(TitleH3)`
  color: ${COLORS.NEUTRAL.WHITE};
`;

export const FooterContactButtonWrapper = styled.div`
  margin-top: 24px;
`;

export const FooterNavWrapper = styled.div`
  background-color: ${COLORS.NEUTRAL.RICH_BLACK};
`;

export const FooterAboutRow = styled(ContainerRow)`
  margin-top: ${({ marginTop }) => marginTop};

  ${SCREEN.xsm} {
    flex-direction: column-reverse;
  }
`;

export const FooterAboutCopyrightColumn = styled(ContainerColumn)`
  flex-basis: 40%;
  max-width: 40%;
  color: ${COLORS.NEUTRAL.MEDIUM_GREY};
  margin-bottom: 0px;

  ${SCREEN.xsm} {
    flex-basis: 100%;
    max-width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 40px;
  }
`;

export const FooterAboutColumn = styled(ContainerColumn)`
  flex-basis: 60%;
  max-width: 60%;
  color: ${COLORS.NEUTRAL.WHITE};
  text-align: right;
  margin-bottom: 0px;

  ${SCREEN.xsm} {
    flex-basis: 100%;
    max-width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    text-align: left;
  }
`;

export const FooterAboutColumnItem = styled.div`
  display: inline-block;
  margin-left: 40px;

  ${SCREEN.xsm} {
    margin-left: 0px;
    display: block;
    margin-bottom: 16px;
    :last-child {
      margin-bottom: 0px;
    }
  }
`;

export const FooterAboutColumnItemLink = styled.a`
  color: inherit;
  text-decoration: none;
`;

export const FooterContainer = styled(Container)`
  padding-top: 32px;
  padding-bottom: 32px;
`;
