import styled from 'styled-components';

export const ProfileSectionDataWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 32px;
  margin-bottom: 16px;
  border-bottom: 1px solid black;
  align-items: center;
`;

export const ProfileSectionDataColumn = styled.div`
  flex: 1;
  margin-left: 16px;
`;

export const ProfileSectionActionsColumn = styled.div`
  max-width: 50px;
`;

export const AddRemoveSection = styled.div`
  margin-bottom: 8px;
  cursor: pointer;
`;

export const MoveUpSection = styled.div`
  margin-bottom: 8px;
  transform: rotate(270deg);
  cursor: pointer;
`;

export const MoveDownSection = styled.div`
  transform: rotate(90deg);
  cursor: pointer;
`;
