import isEmpty from 'lodash/isEmpty';
import {
  ALERT_TYPE,
  INPUT_TYPE,
  CONTENT_TYPE,
} from '../../../common/constants/constants';
import {
  InputPictureWrapper,
  InputWrapper,
  PictureWrapper,
} from '../../../common/ui/Form/Form.styles';
import Input from '../../../common/ui/Form/Input';
import TextArea from '../../../common/ui/Form/TextArea';
import {
  BodySmallRegular,
  SmallBold,
  COLORS,
} from '../../../common/ui/Theme/Theme.styles';
import SectionContent from '../../../portfolio/ui/Section/SectionContent';
import ArrowIcon from '../../../assets/img/Arrow.svg';
import DeleteIcon from '../../../assets/img/delete.svg';
import AddIcon from '../../../assets/img/plus.svg';
import {
  MoveDownSection,
  MoveUpSection,
  ProfileSectionActionsColumn,
  ProfileSectionDataColumn,
  ProfileSectionDataWrapper,
  AddRemoveSection,
} from './ProfileSection.styles';
import CircleImage from '../../../common/ui/Image/CircleImage';
import Alert from '../../../common/ui/Alert/Alert';
import ImageUploader from '../../../common/ui/Form/ImageUploader';

export const ProfileSectionData = ({
  onMoveUp,
  onMoveDown,
  onRemove,
  onAdd,
  profileSectionData,
  onProfileDataChanged,
  isRemoved,
  errors,
}) => {
  return (
    <>
      {isRemoved && (
        <Alert type={ALERT_TYPE.DANGER}>
          <SmallBold>
            Element is removed, once you save the changes the element will be
            removed from the Database.
          </SmallBold>
        </Alert>
      )}
      <ProfileSectionDataWrapper>
        <ProfileSectionActionsColumn>
          <AddRemoveSection>
            {isRemoved ? (
              <CircleImage
                title='Add'
                src={AddIcon}
                alt='Add'
                wrapper='xxsm'
                centered
                onClick={() => onAdd(profileSectionData)}
              />
            ) : (
              <CircleImage
                title='Remove'
                src={DeleteIcon}
                alt='Remove'
                wrapper='xxsm'
                centered
                onClick={() => onRemove(profileSectionData)}
              />
            )}
          </AddRemoveSection>
          {onMoveUp && (
            <MoveUpSection>
              <CircleImage
                title='Move Up'
                src={ArrowIcon}
                alt='Move Up'
                wrapper='xxsm'
                centered
                onClick={() => onMoveUp(profileSectionData)}
              />
            </MoveUpSection>
          )}
          {onMoveDown && (
            <MoveDownSection>
              <CircleImage
                title='Move Down'
                src={ArrowIcon}
                alt='Remove'
                wrapper='xxsm'
                centered
                onClick={() => onMoveDown(profileSectionData)}
              />
            </MoveDownSection>
          )}
        </ProfileSectionActionsColumn>
        <ProfileSectionDataColumn>
          <div>
            <select
              id='type'
              name='type'
              disabled={isRemoved}
              value={profileSectionData.type}
              onChange={(e) => {
                onProfileDataChanged(
                  profileSectionData.id,
                  e.target.id,
                  e.target.value
                );
              }}
            >
              {Object.keys(CONTENT_TYPE).map((key) => (
                <option key={`section-type-${key}`} value={key}>
                  {CONTENT_TYPE[key]}
                </option>
              ))}
            </select>
          </div>
          {profileSectionData.type === CONTENT_TYPE.CUSTOM_CONTENT && (
            <div>
              <InputWrapper>
                <SmallBold>Pre Title</SmallBold>
                <Input
                  input={{
                    name: 'preTitle',
                    type: INPUT_TYPE.TEXT,
                    disabled: isRemoved,
                  }}
                  onInputChange={(input, value) => {
                    onProfileDataChanged(
                      profileSectionData.id,
                      input.name,
                      value
                    );
                  }}
                  value={profileSectionData.preTitle}
                />
                <BodySmallRegular>
                  Pre Title should be removed in order to see start/end date
                </BodySmallRegular>
              </InputWrapper>
              <InputWrapper>
                <SmallBold>SubTitle</SmallBold>
                <Input
                  input={{
                    name: 'subTitle',
                    type: INPUT_TYPE.TEXT,
                    disabled: isRemoved,
                  }}
                  onInputChange={(input, value) => {
                    onProfileDataChanged(
                      profileSectionData.id,
                      input.name,
                      value
                    );
                  }}
                  value={profileSectionData.subTitle}
                />
              </InputWrapper>
            </div>
          )}
          {profileSectionData.type !== CONTENT_TYPE.IMAGE && (
            <InputWrapper>
              <SmallBold>Body</SmallBold>
              <TextArea
                input={{
                  name: 'body',
                  type: INPUT_TYPE.TEXT,
                  disabled: isRemoved,
                }}
                onChange={(input, value) => {
                  onProfileDataChanged(
                    profileSectionData.id,
                    input.name,
                    value
                  );
                }}
                value={profileSectionData.body}
              />
            </InputWrapper>
          )}
          {profileSectionData.type === CONTENT_TYPE.CUSTOM_CONTENT && (
            <div>
              <InputWrapper>
                <SmallBold hasError={!isEmpty(errors['startDate'])}>
                  Start Date
                </SmallBold>
                <Input
                  input={{
                    name: 'startDate',
                    type: INPUT_TYPE.MONTH,
                    disabled: isRemoved,
                    placeholder: 'MM/YY',
                  }}
                  onInputChange={(input, value) => {
                    onProfileDataChanged(
                      profileSectionData.id,
                      input.name,
                      value
                    );
                  }}
                  hasError={!isEmpty(errors['startDate'])}
                  value={profileSectionData.startDate}
                />
                {!isEmpty(errors['startDate']) && (
                  <BodySmallRegular color={COLORS.UI.ERROR}>
                    {errors['startDate']}
                  </BodySmallRegular>
                )}
              </InputWrapper>
              <InputWrapper>
                <SmallBold hasError={!isEmpty(errors['endDate'])}>
                  End Date
                </SmallBold>
                <Input
                  input={{
                    name: 'endDate',
                    type: INPUT_TYPE.MONTH,
                    disabled: isRemoved,
                    placeholder: 'MM/YY',
                  }}
                  onInputChange={(input, value) => {
                    onProfileDataChanged(
                      profileSectionData.id,
                      input.name,
                      value
                    );
                  }}
                  hasError={!isEmpty(errors['endDate'])}
                  value={profileSectionData.endDate}
                />
                {!isEmpty(errors['endDate']) && (
                  <BodySmallRegular color={COLORS.UI.ERROR}>
                    {errors['endDate']}
                  </BodySmallRegular>
                )}
              </InputWrapper>
            </div>
          )}
          {(profileSectionData.type === CONTENT_TYPE.CUSTOM_CONTENT ||
            profileSectionData.type === CONTENT_TYPE.IMAGE) && (
            <div>
              <div>Image</div>
              <InputPictureWrapper>
                {!isEmpty(profileSectionData.image) && (
                  <PictureWrapper>
                    <CircleImage
                      src={profileSectionData.image}
                      alt='Image'
                      wrapper='sm'
                      bordered
                      centered
                    />
                  </PictureWrapper>
                )}

                <ImageUploader
                  input={{
                    name: 'image',
                    disabled: isRemoved,
                  }}
                  onInputChange={(input, value) => {
                    onProfileDataChanged(
                      profileSectionData.id,
                      input.name,
                      value
                    );
                  }}
                />
              </InputPictureWrapper>
            </div>
          )}
        </ProfileSectionDataColumn>
        <ProfileSectionDataColumn>
          <div>Preview</div>
          <div>
            <SectionContent item={profileSectionData} />
          </div>
        </ProfileSectionDataColumn>
      </ProfileSectionDataWrapper>
    </>
  );
};
