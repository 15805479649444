import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Form from '../Form/Form';
import { COLORS, SmallBold } from '../Theme/Theme.styles';
import {
  DataTableCell,
  DataTablePagination,
  DataTablePaginationButton,
  DataTableRow,
  DataTableWrapper,
  Wrapper,
} from './DataTable.styles';
import ArrowIcon from '../../../assets/img/Arrow.svg';
import CircleImage from '../Image/CircleImage';
import Alert from '../Alert/Alert';
import { useTranslation } from 'react-i18next';

const DataTable = ({
  headers,
  rows,
  updateVal,
  onCancelUpdate,
  onDelete,
  onConfirmUpdate,
  alertsOnUpdate,
  itemsPerPage = 10,
  paginated = true,
}) => {
  const { t } = useTranslation();

  const [pagination, setPagination] = useState({
    currentPage: 0,
    itemsPerPage,
    rows: [],
  });

  const formInputs = useMemo(
    () => headers.filter((header) => header.isFormInput),
    [headers]
  );

  const changePage = useCallback(
    (page) => {
      const startIndex = page * pagination.itemsPerPage;
      const lastIndex = (page + 1) * pagination.itemsPerPage - 1;
      const elements = rows.filter(
        (row, index) => index >= startIndex && index <= lastIndex
      );
      setPagination((val) => ({
        ...val,
        currentPage: page,
        rows: elements,
      }));
    },
    [rows, pagination.itemsPerPage]
  );

  useEffect(() => {
    if (!isEmpty(rows)) {
      if (paginated) {
        setPagination({
          currentPage: 0,
          itemsPerPage: -1,
          rows,
        });
      } else {
        changePage(0);
      }
    } else {
      setPagination({
        currentPage: 0,
        itemsPerPage,
        rows: [],
      });
    }
  }, [rows, itemsPerPage, changePage, paginated]);

  return (
    <Wrapper>
      <DataTableWrapper>
        <thead>
          <DataTableRow background={COLORS.NEUTRAL.RICH_BLACK}>
            {headers.map((header) => (
              <DataTableCell key={`data-table-header-${header.id}`}>
                <SmallBold color={COLORS.NEUTRAL.WHITE}>
                  {header.label}
                </SmallBold>
              </DataTableCell>
            ))}
          </DataTableRow>
        </thead>
        <tbody>
          {pagination.rows.length === 0 ? (
            <DataTableRow>
              <DataTableCell colSpan={headers.length}>No data</DataTableCell>
            </DataTableRow>
          ) : (
            pagination.rows.map((row, i) => (
              <DataTableRow
                key={`data-table-row-${row.id}`}
                background={
                  i % 2 === 0
                    ? COLORS.NEUTRAL.LIGHT_GREY
                    : COLORS.NEUTRAL.PALE_GREY
                }
              >
                {updateVal && updateVal.index === i ? (
                  <DataTableCell colSpan={headers.length}>
                    {alertsOnUpdate && (
                      <Alert type={alertsOnUpdate.type}>
                        {alertsOnUpdate.text}
                      </Alert>
                    )}
                    <Form
                      dataInputs={formInputs}
                      onCancel={onCancelUpdate}
                      onDelete={() => {
                        onDelete(row, i);
                      }}
                      values={updateVal}
                      btnText={t('common.save')}
                      onSubmit={(values) => onConfirmUpdate(row.id, values)}
                      onlyModifiedFields
                    />
                  </DataTableCell>
                ) : (
                  headers.map((header) => (
                    <DataTableCell
                      style={{
                        width: header.customWidth || 'auto',
                      }}
                      key={`data-table-row-${header.id}-${row.id}`}
                    >
                      {row[header.id]}
                    </DataTableCell>
                  ))
                )}
              </DataTableRow>
            ))
          )}
        </tbody>
        <tfoot>
          <DataTableRow>
            <DataTableCell colSpan={headers.length}>
              <DataTablePagination>
                {pagination.currentPage > 0 && (
                  <DataTablePaginationButton
                    onClick={() => changePage(pagination.currentPage - 1)}
                    left
                  >
                    <CircleImage
                      src={ArrowIcon}
                      wrapper='xsm'
                      alt='arrow'
                      centered
                    />
                  </DataTablePaginationButton>
                )}
                {pagination.currentPage <
                  Math.ceil(rows.length / pagination.itemsPerPage) - 1 && (
                  <DataTablePaginationButton
                    onClick={() => changePage(pagination.currentPage + 1)}
                  >
                    <CircleImage
                      src={ArrowIcon}
                      wrapper='xsm'
                      alt='arrow'
                      centered
                    />
                  </DataTablePaginationButton>
                )}
              </DataTablePagination>
            </DataTableCell>
          </DataTableRow>
        </tfoot>
      </DataTableWrapper>
    </Wrapper>
  );
};

export default DataTable;
