import { COLUMN_ALIGN } from '../constants/constants';

const getProjectSectionAlignment = (align) => {
  switch (align) {
    case COLUMN_ALIGN.CENTER:
      return 'justify-content: center;';
    case COLUMN_ALIGN.LEFT:
      return 'justify-content: start;';
    case COLUMN_ALIGN.RIGHT:
      return 'justify-content: end;';
    default:
      return;
  }
};

const alignHelper = {
  getProjectSectionAlignment,
};

export default alignHelper;
