import { useEffect, useContext, useCallback, useState } from 'react';
import { useParams, Route, Routes } from 'react-router-dom';
import { PortfolioContext } from '../Home/Home';
import { getUserInformationAndPortfolioDataByUsername } from '../../../common/services/userService';
import UserPortfolio from '../UserPortfolio/UserPortfolio';
import About from '../About/About';
import Contact from '../Contact/Contact';
import ErrorMessage from '../../../common/ui/ErrorPages/ErrorMessage';
import Project from '../Project/Project';

const PortfolioWrapper = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { setUserInformation, setError } = useContext(PortfolioContext);
  const params = useParams();

  const onLoad = useCallback(async () => {
    try {
      const userInformation =
        await getUserInformationAndPortfolioDataByUsername(params.userId);
      setUserInformation(userInformation);
      document.title = `${userInformation.firstName} ${userInformation.lastName} ${userInformation.role}`;
    } catch (e) {
      setError('Something went wrong, please try again later');
    }
    setIsLoading(false);
  }, [setUserInformation, setError, params.userId]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  if (isLoading) {
    return <></>;
  }

  return (
    <Routes>
      <Route path={`/`} element={<UserPortfolio />} />
      <Route path={`/about`} element={<About />} />
      <Route path={`/contact`} element={<Contact />} />
      <Route path={`/project/:projectId`} element={<Project />} />
      <Route path={`/*`} element={<ErrorMessage isNotFoundPage />} />
    </Routes>
  );
};

export default PortfolioWrapper;
