import axios from 'axios';
import axiosInstance from './interceptor';

export const getUserInformationAndPortfolioDataByUsername = async (userId) => {
  const userData = await axios.get(
    `${process.env.REACT_APP_API_URL}/user/${userId}`
  );
  return userData.data;
};

export const login = async (email, password) => {
  const data = await axios.post(`${process.env.REACT_APP_API_URL}/user/login`, {
    email,
    password,
  });
  return data.data;
};

export const getUserInformation = async () => {
  const userData = await axiosInstance.get(
    `${process.env.REACT_APP_API_URL}/user`
  );

  return userData.data;
};

export const updateUser = async (
  firstName,
  lastName,
  phone,
  role,
  address,
  linkedIn,
  profilePic
) => {
  const data = await axiosInstance.patch(
    `${process.env.REACT_APP_API_URL}/user`,
    {
      firstName,
      lastName,
      phone,
      role,
      linkedIn,
      profilePic,
      address,
    }
  );

  return data.data;
};

export const createUser = async (
  email,
  password,
  firstName,
  lastName,
  phone,
  role,
  address,
  linkedIn,
  profilePic
) => {
  const data = await axiosInstance.post(
    `${process.env.REACT_APP_API_URL}/user`,
    {
      email,
      password,
      firstName,
      lastName,
      phone,
      role,
      linkedIn,
      profilePic,
      address,
    }
  );

  return data.data;
};

export const changePassword = async (token, password, repeatPassword) => {
  const url = `${process.env.REACT_APP_API_URL}/user/change-password`;
  const body = {
    password,
    repeatPassword,
  };

  // If there's no token passed to this function, it means it's already Authenticated and it should be gotten from localStorage
  if (!token) {
    const data = await axiosInstance.patch(url, body);

    return data.data;
  }

  // If there's a token passed to this function, it means change password after login
  const data = await axios.patch(url, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data.data;
};

export const forgotPassword = async (email) => {
  const data = await axiosInstance.post(
    `${process.env.REACT_APP_API_URL}/user/forgot-password`,
    {
      email,
    }
  );

  return data.data;
};

export const isForgotPasswordTokenValid = async (token) => {
  const data = await axiosInstance.get(
    `${process.env.REACT_APP_API_URL}/user/forgot-password/${token}`
  );

  return data.data;
};

export const resetPassword = async (token, password, repeatPassword) => {
  const data = await axiosInstance.post(
    `${process.env.REACT_APP_API_URL}/user/reset-password/${token}`,
    {
      password,
      repeatPassword,
    }
  );

  return data.data;
};

export const userService = {
  changePassword,
  resetPassword,
  getUserInformation,
  isForgotPasswordTokenValid,
  getUserInformationAndPortfolioDataByUsername,
  updateUser,
  createUser,
  login,
  forgotPassword,
};
