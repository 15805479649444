import styled from 'styled-components';
import { SCREEN } from '../../../common/ui/screen/screen';
import { BodyBold, COLORS, TitleH3 } from '../../../common/ui/Theme/Theme.styles';

export const ContactWrapper = styled.div`
  background-color: ${COLORS.NEUTRAL.TEXT_BLACK};
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ContactUserWrapper = styled.div`
  padding-left: 32px;
  padding-right: 32px;
  display: flex;
  flex-grow: 1;
  ${SCREEN.xxsm} {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const ContactContainer = styled.div`
  width: 558px;
  margin: auto;
  background: ${COLORS.CUSTOM.CONTACT_WRAPPER};
  border-radius: 16px;
  padding: 40px;

  ${SCREEN.md} {
    width: calc(100% - 16px);
    padding: 40px 8px;
  }
`;

export const ContactText = styled(TitleH3)`
  color: ${COLORS.NEUTRAL.WHITE};
  margin-top: 32px;
  text-align: center;
`;

export const ContactOptionsWrapper = styled.div`
  margin-top: 32px;
  width: 100%;
`;

export const ContactOptionWrapper = styled.div`
  margin-bottom: 8px;
`;

export const ContactFooterWrapper = styled.div``;

export const ContactOptionText = styled(BodyBold)`
  text-align: left;
  word-break: break-all;

  ${SCREEN.xxsm} {
    font-size: 14px;
    line-height: 24px;
  }
`;
