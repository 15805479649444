import { useState, useCallback } from 'react';
import { requestStatus } from '../../../common/constants/requestStatus';
import { getErrorMessages } from '../../../common/utils/errorSetter';
import { customerService } from '../../../common/services/customerService';

export function useDeleteCustomer() {
  const [status, setStatus] = useState(requestStatus.Initial);

  const isLoading = status === requestStatus.Pending;

  const deleteCustomer = useCallback(async (customerId) => {
    setStatus(requestStatus.Pending);

    try {
      const deleteCustomerResponse =
        await customerService.deleteCustomerByCustomerId(customerId);

      if (!deleteCustomerResponse) {
        // Throwing an error if response is not success
        throw new Error('');
      }

      setStatus(requestStatus.Done);
    } catch (error) {
      setStatus(requestStatus.Error);

      throw Error(
        getErrorMessages({
          error,
        })
      );
    }
  }, []);

  return {
    isLoading,
    deleteCustomer,
  };
}
