import { useState, useCallback } from 'react';
import { requestStatus } from '../../../common/constants/requestStatus';
import { getErrorMessages } from '../../../common/utils/errorSetter';
import { customerService } from '../../../common/services/customerService';

export function useCreateCustomer() {
  const [status, setStatus] = useState(requestStatus.Initial);

  const isLoading = status === requestStatus.Pending;

  const createCustomer = useCallback(async ({ title, image }) => {
    setStatus(requestStatus.Pending);

    try {
      const createdCustomerResponse = await customerService.createCustomer(
        title,
        image
      );

      if (!createdCustomerResponse) {
        // Throwing an error if response is not success
        throw new Error('');
      }

      setStatus(requestStatus.Done);
    } catch (error) {
      setStatus(requestStatus.Error);

      throw Error(
        getErrorMessages({
          error,
        })
      );
    }
  }, []);

  return {
    isLoading,
    createCustomer,
  };
}
