import { useState, useCallback } from 'react';
import { requestStatus } from '../../../common/constants/requestStatus';
import { getErrorMessages } from '../../../common/utils/errorSetter';
import { customerService } from '../../../common/services/customerService';

export function useFetchCustomers() {
  const [customers, setCustomers] = useState([]);

  const [status, setStatus] = useState(requestStatus.Initial);

  const isLoading = status === requestStatus.Pending;

  const fetchCustomers = useCallback(async () => {
    setStatus(requestStatus.Pending);

    try {
      const customersResponse =
        await customerService.getCustomers();

      setCustomers(
        (customersResponse || []).map((customer) => ({
          id: customer.id,
          title: customer.title,
          image: customer.image,
        }))
      );

      setStatus(requestStatus.Done);
    } catch (error) {
      setStatus(requestStatus.Error);

      throw Error(
        getErrorMessages({
          error,
        })
      );
    }
  }, []);

  return {
    isLoading,
    customers,
    fetchCustomers,
  };
}
