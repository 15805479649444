import React, { useContext, useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useMediaQuery } from 'react-responsive';
import { ALERT_TYPE, CONTACT_OPTIONS_TYPE } from '../../../common/constants/constants';
import { PortfolioContext } from '../Home/Home';
import { getProfileSectionsByPortfolioId } from '../../../common/services/portfolioService';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import Section from '../Section/Section';
import CircleImage from '../../../common/ui/Image/CircleImage';
import {
  Container,
  MainWrapper,
  PreTitle,
  TitleH1,
  TitleH3,
} from '../../../common/ui/Theme/Theme.styles';
import {
  AboutWrapper,
  ProfileDataItem,
  ProfileDataWrapper,
  ProfileSectionsContainer,
  ProfileSectionsWrapper,
  ProfileWrapper,
} from './About.styles';
import Alert from '../../../common/ui/Alert/Alert';
import {
  LG_SCREEN_VALUE,
  MD_SCREEN_VALUE,
  XX_SMALL_SCREEN_VALUE,
  X_LG_SCREEN_VALUE,
  X_SMALL_SCREEN_VALUE,
} from '../../../common/ui/screen/screen';

const About = () => {
  // TODO: Look for a best way of doing this
  const isXLGScreen = useMediaQuery({
    query: `(min-width: ${X_LG_SCREEN_VALUE})`,
  });
  const isLGScreen = useMediaQuery({
    query: `(max-width: ${LG_SCREEN_VALUE})`,
  });
  const isMDScreen = useMediaQuery({
    query: `(max-width: ${MD_SCREEN_VALUE})`,
  });
  const isXSMScreen = useMediaQuery({
    query: `(max-width: ${X_SMALL_SCREEN_VALUE})`,
  });
  const isXXSMScreen = useMediaQuery({
    query: `(max-width: ${XX_SMALL_SCREEN_VALUE})`,
  });
  const [error, setError] = useState(null);
  const { userInformation } = useContext(PortfolioContext);
  const [isLoading, setIsLoading] = useState(true);
  const [profileSections, setProfileSections] = useState([]);

  useEffect(() => {
    const fetchProfileSections = async () => {
      try {
        const profileSectionsData = await getProfileSectionsByPortfolioId(
          userInformation.portfolioId
        );
        setProfileSections(profileSectionsData);
      } catch (e) {
        setError(
          'There was an error processing the request, please try again later'
        );
      }
      setIsLoading(false);
    };
    fetchProfileSections();
  }, [setError, userInformation.portfolioId]);

  const getProfileSection = (profileSection) => ({
    ...profileSection,
    buttons: profileSection.buttons
      .filter(
        (btn) =>
          (btn.name === CONTACT_OPTIONS_TYPE.PHONE &&
            !isEmpty(userInformation.phone)) ||
          (btn.name === CONTACT_OPTIONS_TYPE.LINKED_IN &&
            !isEmpty(userInformation.linkedIn)) ||
          (btn.name === CONTACT_OPTIONS_TYPE.MAIL &&
            !isEmpty(userInformation.email))
      )
      .map((btn) => {
        const newBtn = { ...btn };
        if (newBtn.name === CONTACT_OPTIONS_TYPE.LINKED_IN) {
          newBtn.address = userInformation.linkedIn;
        } else if (newBtn.name === CONTACT_OPTIONS_TYPE.MAIL) {
          newBtn.address = `${newBtn.address}${userInformation.email}`;
        } else if (newBtn.name === CONTACT_OPTIONS_TYPE.PHONE) {
          newBtn.address = `${newBtn.address}${userInformation.phone}`;
        }
        return newBtn;
      }),
  });

  if (isLoading) {
    return <></>;
  }

  return (
    <MainWrapper>
      <Header noMargin />
      {error ? (
        <Container>
          <Alert type={ALERT_TYPE.ERROR}>{error}</Alert>
        </Container>
      ) : (
        <AboutWrapper>
          <ProfileWrapper>
            <ProfileDataWrapper>
              <ProfileDataItem>
                <CircleImage
                  src={userInformation.profilePic}
                  alt={`${userInformation.firstName} ${userInformation.lastName}`}
                  wrapper='lg'
                  title={`${(userInformation.firstName || '').substr(0, 1)} ${(
                    userInformation.lastName || ''
                  ).substr(0, 1)}`}
                  centered
                />
              </ProfileDataItem>
              <ProfileDataItem marginTop='16px'>
                <TitleH1>{`${userInformation.firstName} ${userInformation.lastName}`}</TitleH1>
              </ProfileDataItem>
              {userInformation.address && (
                <ProfileDataItem>
                  <PreTitle>{userInformation.address}</PreTitle>
                </ProfileDataItem>
              )}
              {userInformation.aboutHeaderText && (
                <ProfileDataItem marginTop='24px'>
                  <TitleH3>
                    {userInformation.aboutHeaderText
                      .split('\n')
                      .map((el, index) => (
                        <React.Fragment key={`about-header-text-${index}`}>
                          {el}
                          <br />
                        </React.Fragment>
                      ))}
                  </TitleH3>
                </ProfileDataItem>
              )}
            </ProfileDataWrapper>
          </ProfileWrapper>
          <ProfileSectionsContainer>
            <ProfileSectionsWrapper>
              {profileSections.map((profileSection) => (
                <Section
                  key={`profile-section-${profileSection.id}`}
                  section={getProfileSection(profileSection)}
                />
              ))}
            </ProfileSectionsWrapper>
          </ProfileSectionsContainer>
        </AboutWrapper>
      )}
      <Footer withContact withContactImg={false} />
    </MainWrapper>
  );
};

export default About;
