import styled from 'styled-components';
import { COLORS } from '../Theme/Theme.styles';

export const ImgWrapper = styled.img`
  max-height: 100%;
  max-width: 100%;
  ${({ centered }) =>
    centered
      ? `position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);`
      : ''}
`;

export const Centered = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${COLORS.NEUTRAL.RICH_BLACK};
`;

export const CircularImageWrapper = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 90px;
  margin: auto;
  ${({ bordered }) => (bordered ? 'border: 1px solid black;' : '')}
  ${({ centered }) => (centered ? 'position: relative;' : '')}
`;

export const CircularImageWrapperSM = styled.div`
  width: 65px;
  height: 65px;
  margin: auto;
`;

export const CircularImageWrapperLG = styled.div`
  margin: auto;
`;

export const CircularImageContainer = styled.div`
  ${({ size }) => size}
  margin: auto;
`;
