import styled from 'styled-components';
import { COLORS } from '../../../common/ui/Theme/Theme.styles';

export const CustomerImageWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const CustomerImage = styled.img`
  max-width: 100px;
  max-height: 100px;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  > button {
    margin-top: 16px;
  }
`;

export const ActionLink = styled.div`
  margin-bottom: 8px;
  color: ${COLORS.BOSTON_BLUE.PRIMARY};
  > span {
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  }
`;