import { useState, useCallback } from 'react';
import { requestStatus } from '../../../common/constants/requestStatus';
import { getErrorMessages } from '../../../common/utils/errorSetter';
import { profileService } from '../../../common/services/profileService';

export function useUpdateProfileSectionData() {
  const [status, setStatus] = useState(requestStatus.Initial);

  const isLoading = status === requestStatus.Pending;

  const updateProfileSectionData = useCallback(async ({ id, sectionsData }) => {
    setStatus(requestStatus.Pending);

    try {
      const updateProfileSectionDataResponse = await profileService.createProfileSectionData(
        id,
        sectionsData,
      );

      if (!updateProfileSectionDataResponse) {
        // Throwing an error if response is not success
        throw new Error('');
      }

      setStatus(requestStatus.Done);
    } catch (error) {
      setStatus(requestStatus.Error);

      throw Error(
        getErrorMessages({
          error,
        })
      );
    }
  }, []);

  return {
    isLoading,
    updateProfileSectionData,
  };
}
