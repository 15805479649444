import { ColorPreview, SelectCustomOptionWrapper } from './Form.styles';
import Input from './Input';

export const CUSTOM_LIST_VALUE = 'CUSTOM_LIST_VALUE';

const Select = ({ input, value, items, onInputChange, disabled, customValue = null }) => (
  <>
    <select
      id={input.name}
      name={input.name}
      value={value}
      onChange={(e) => onInputChange(input, e.target.value)}
      disabled={disabled}
    >
      {!input.noDefaultValue && <option value=''>Select an option...</option>}
      {(items || []).map((item, index) => (
        <option key={`select-option-${item.value}-${index}`} value={item.value}>
          {item.label}
        </option>
      ))}
    </select>
    {input.allowCustom && value === CUSTOM_LIST_VALUE && (
      <SelectCustomOptionWrapper>
        <Input
          input={{
            name: `${input.name}Custom`,
            required: true,
          }}
          onInputChange={(_, value) =>
            onInputChange(
              {
                ...input,
                name: `${input.name}Custom`,
              },
              value
            )
          }
          value={customValue ?? ''}
        />
        </SelectCustomOptionWrapper>
    )}
    {input.allowColorPreview && <ColorPreview background={customValue ?? value} />}
  </>
);

export default Select;
