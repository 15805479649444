import { useState, useCallback } from 'react';
import { requestStatus } from '../../../common/constants/requestStatus';
import { projectService } from '../../../common/services/projectService';
import { getErrorMessages } from '../../../common/utils/errorSetter';

export function useUpdateProjectsOrder() {
  const [status, setStatus] = useState(requestStatus.Initial);

  const isLoading = status === requestStatus.Pending;

  const updateProjectsOrder = useCallback(async (projects) => {
    setStatus(requestStatus.Pending);

    try {
      // New order will be gotten from the projects array
      const body = projects.map((el, index) => ({
        ...el,
        order: index + 1,
      }));

      const updatedOrderResponse = await projectService.updateOrder(body);

      if (!updatedOrderResponse) {
        // Throwing an error if response is not success
        throw new Error('');
      }

      setStatus(requestStatus.Done);
    } catch (error) {
      setStatus(requestStatus.Error);

      throw Error(
        getErrorMessages({
          error,
        })
      );
    }
  }, []);

  return {
    isLoading,
    updateProjectsOrder,
  };
}
