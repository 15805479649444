import logo from '../../../assets/img/logo.svg';
import { TitleH2 } from '../../../common/ui/Theme/Theme.styles';
import { ImgWrapper, PortfolioWrapperStyled, TextWrapper } from './Portfolio.styles';

const Portfolio = () => (
  <PortfolioWrapperStyled>
    <ImgWrapper>
      <img src={logo} alt='logo' />
    </ImgWrapper>
    <TextWrapper>
      <TitleH2>LINKIT Portfolio</TitleH2>
    </TextWrapper>
  </PortfolioWrapperStyled>
);

export default Portfolio;
