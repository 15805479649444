import { TitleH3 } from '../Theme/Theme.styles';
import { ErroMessageWrapper, ErrorMessageImgWrapper, ErrorMessageTextWrapper } from './ErrorPages.styles';
import logo from '../../../assets/img/logo.svg';

const ErrorMessage = ({
  isNotFoundPage = false
}) => {
  return (
    <ErroMessageWrapper>
      <ErrorMessageImgWrapper>
        <img src={logo} alt='logo' />
      </ErrorMessageImgWrapper>
      <ErrorMessageTextWrapper>
        <TitleH3>
          {!isNotFoundPage ? "Ops! Something went wrong, please try again later..." : "Ops! Page not found"}
        </TitleH3>
      </ErrorMessageTextWrapper>
    </ErroMessageWrapper>
  );
};

export default ErrorMessage;
