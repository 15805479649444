import { useState } from 'react';
import { requestStatus } from '../../../common/constants/requestStatus';
import { userService } from '../../../common/services/userService';
import { getErrorMessages } from '../../../common/utils/errorSetter';

export function useChangePassword({ token }) {
  const [status, setStatus] = useState(requestStatus.Initial);

  const isLoading = status === requestStatus.Pending;

  async function changePassword(password, repeatPassword) {
    setStatus(requestStatus.Pending);

    try {
      const changePasswordResponse = await userService.changePassword(
        token,
        password,
        repeatPassword
      );

      if (!changePasswordResponse) {
        // Throwing error when 'success' isn't true
        throw Error('');
      }

      setStatus(requestStatus.Done);
    } catch (error) {
      setStatus(requestStatus.Error);

      throw Error(
        getErrorMessages({
          error,
        })
      );
    }
  }

  return {
    status,
    isLoading,
    changePassword,
  };
}
