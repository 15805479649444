import { useCallback, useEffect, useState } from 'react';
import DataTable from '../../../common/ui/DataTable/DataTable';
import CircleImage from '../../../common/ui/Image/CircleImage';
import { SmallBold } from '../../../common/ui/Theme/Theme.styles';
import {
  MoveDownSection,
  MoveUpSection,
} from '../AboutMe/ProfileSection.styles';
import { ActionLink } from '../CustomerList/CustomerList.styles';
import ArrowIcon from '../../../assets/img/Arrow.svg';
import BlockUi from '../../../common/ui/BlockUi/BlockUi';
import Modal from '../../../common/ui/Modal/Modal';
import ConfirmModalContent from '../ConfirmModalContent/ConfirmModalContent';
import { useFetchProjects } from '../../../common/hooks/useFetchProjects';
import { toastHelper } from '../../../common/utils/toastHelper';
import { useUpdateProjectsOrder } from '../hooks/useUpdateProjectsOrder';
import { useNavigate } from 'react-router-dom';
import { useDeleteProject } from '../hooks/useDeleteProject';

const headers = [
  {
    id: 'movements',
    customWidth: '30px',
  },
  {
    id: 'title',
    label: 'Title',
  },
  {
    id: 'description',
    label: 'Description',
  },
  {
    id: 'isProtected',
    label: 'isProtected',
    customWidth: '75px',
  },
  {
    id: 'actions',
    customWidth: '75px',
  },
];

export const ProjectListPage = () => {
  const navigate = useNavigate();

  const { projects, isLoading: isFetching, fetchProjects } = useFetchProjects();
  const { isLoading: isUpdatingProjectsOrder, updateProjectsOrder } =
    useUpdateProjectsOrder();
  const { isLoading: isDeletingProject, deleteProjectById } =
    useDeleteProject();

  const [isSaveOrderModalOpened, setIsSaveOrderModalOpened] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState(null);
  const [orderedProjects, setOrderedProjects] = useState([]);

  const isLoading = isFetching || isUpdatingProjectsOrder || isDeletingProject;

  const switchPositions = (element, sum) => {
    setOrderedProjects((values) => {
      let newValues = [...values];
      const index = newValues.findIndex((el) => el.id === element.id);

      if (index >= 0) {
        const switchWith = newValues[index + sum];
        newValues[index + sum] = element;
        newValues[index] = switchWith;
      }

      return newValues;
    });
  };

  const getProjects = useCallback(async () => {
    try {
      await fetchProjects();
    } catch (error) {
      toastHelper.error(error);
    }
  }, [fetchProjects]);

  async function handleSaveProjecsOrder() {
    try {
      await updateProjectsOrder(orderedProjects);
      await fetchProjects();

      setIsSaveOrderModalOpened(false);

      toastHelper.success('Order updated succesfully');
    } catch (error) {
      toastHelper.error(error);
    }
  }

  function handleAddNewProject() {
    navigate('create');
  }

  function handleEdit(projectId) {
    navigate(`${projectId}`);
  }

  function handleDeleteProject(project) {
    setProjectToDelete(project);
  }

  function handleModifyContent(projectId) {
    navigate(`${projectId}/content`);
  }

  async function onConfirmProjectDelete() {
    try {
      await deleteProjectById(projectToDelete.id);

      setProjectToDelete(null);

      await fetchProjects();

      window.scrollTo(0, 0);

      toastHelper.success('Project deleted succesfully');
    } catch (error) {
      toastHelper.error(error);
    }
  }

  useEffect(() => {
    if (projects.length > 0) {
      setOrderedProjects([...projects].sort((a, b) => a.order - b.order));
    }
  }, [projects]);

  useEffect(() => {
    getProjects();
  }, [getProjects]);

  return (
    <>
      {projectToDelete && (
        <Modal>
          <BlockUi blocked={isLoading}>
            <ConfirmModalContent
              body={`Are you sure that you want to delete ${projectToDelete.title}?`}
              onCancel={() => setProjectToDelete(null)}
              onCofirm={onConfirmProjectDelete}
            />
          </BlockUi>
        </Modal>
      )}
      {isSaveOrderModalOpened && (
        <Modal>
          <BlockUi blocked={isLoading}>
            <ConfirmModalContent
              body={`Do you wanto to save the order of the projects?`}
              onCancel={() => setIsSaveOrderModalOpened(false)}
              onCofirm={handleSaveProjecsOrder}
            />
          </BlockUi>
        </Modal>
      )}
      <BlockUi blocked={isLoading}>
        <ActionLink>
          <span onClick={handleAddNewProject}>Add new project</span>
        </ActionLink>
        <ActionLink>
          <span onClick={() => setIsSaveOrderModalOpened(true)}>
            Save Order
          </span>
        </ActionLink>
        <DataTable
          headers={headers}
          rows={orderedProjects.map((project, index) => ({
            ...project,
            title: (() => {
              if (project.title && project.title.length > 75) {
                return `${project.title.substr(0, 74)}...`;
              }
              return project.title;
            })(),
            description: (() => {
              if (project.description && project.description.length > 75) {
                return `${project.description.substr(0, 74)}...`;
              }
              return project.description;
            })(),
            isProtected: project.isProtected ? 'Y' : 'N',
            movements: (
              <>
                {index > 0 && (
                  <MoveUpSection>
                    <CircleImage
                      title='Move Up'
                      src={ArrowIcon}
                      alt='Move Up'
                      wrapper='xxsm'
                      centered
                      onClick={() => switchPositions(project, -1)}
                    />
                  </MoveUpSection>
                )}
                {index < projects.length - 1 && (
                  <MoveDownSection>
                    <CircleImage
                      title='Move Down'
                      src={ArrowIcon}
                      alt='Move Down'
                      wrapper='xxsm'
                      centered
                      onClick={() => switchPositions(project, 1)}
                    />
                  </MoveDownSection>
                )}
              </>
            ),
            actions: (
              <div>
                <ActionLink>
                  <span
                    onClick={() => {
                      handleEdit(project.id);
                    }}
                  >
                    <SmallBold>Edit</SmallBold>
                  </span>
                </ActionLink>
                <ActionLink>
                  <span
                    onClick={() => {
                      handleDeleteProject(project);
                    }}
                  >
                    <SmallBold>Remove</SmallBold>
                  </span>
                </ActionLink>
                <ActionLink>
                  <span onClick={() => handleModifyContent(project.id)}>
                    <SmallBold>Content</SmallBold>
                  </span>
                </ActionLink>
              </div>
            ),
          }))}
          paginated={false}
        />
      </BlockUi>
    </>
  );
};
