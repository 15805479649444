import { Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import AdminApp from './admin/ui';
import Portfolio from './portfolio/ui/Portfolio/Portfolio';
import ErrorMessage from './common/ui/ErrorPages/ErrorMessage';
import Home from './portfolio/ui/Home/Home';

import 'react-toastify/dist/ReactToastify.css';

function App() {
  // TODO: Need to check a better way to handle nested routes (need to check how to do it with RouterV6)
  return (
    <>
      <ToastContainer />
      <Routes>
        <Route path='/' element={<Portfolio />} />
        <Route path=':userId/*' element={<Home />} />
        <Route path='admin/*' element={<AdminApp />} />
        <Route path='*' element={<ErrorMessage isNotFoundPage />} />
      </Routes>
    </>
  );
}

export default App;
