import isEmpty from 'lodash/isEmpty';
import {
  ProjectCustomer,
  ProjectCustomerWrapper,
  ProjectDescription,
  ProjectImg,
  ProjectImgWrapper,
  ProjectTitle,
  ProjectTitleWrapper,
  ProjectWrapper,
  TitleWrapper,
} from './SingleProject.styles';
import {
  COLORS,
  FlexCentered,
  PreTitle,
  TitleH1,
  UppercaseText,
} from '../../../common/ui/Theme/Theme.styles';

const SingleProject = ({
  projectId,
  customer,
  title,
  description,
  image,
  isProtected,
  backgroundColor,
  onButtonClick,
  // Only xsm, and "undefined" are supported
  size,
}) => (
  <ProjectWrapper onClick={() => onButtonClick(projectId)}>
    <ProjectImgWrapper $backgroundColor={backgroundColor} $size={size}>
      {!isEmpty(image) ? (
        <ProjectImg src={image} alt={title} />
      ) : (
        <FlexCentered>
          <TitleH1>{title.substr(0, 1)}</TitleH1>
        </FlexCentered>
      )}
    </ProjectImgWrapper>

    <ProjectCustomerWrapper>
      <UppercaseText style={isProtected ? { marginRight: '16px' } : {}}>
        <TitleWrapper>
          <ProjectCustomer>{customer}</ProjectCustomer>
          {isProtected ? (
            <PreTitle color={COLORS.UI.WARNING_V2}>Password required</PreTitle>
          ) : null}
        </TitleWrapper>
      </UppercaseText>
    </ProjectCustomerWrapper>

    <ProjectTitleWrapper>
      <ProjectTitle>
        {title}
      </ProjectTitle>
    </ProjectTitleWrapper>
    {description && (
      <ProjectDescription>
        {description.length > 200
          ? `${description.substr(0, 200)}...`
          : description}
      </ProjectDescription>
    )}
  </ProjectWrapper>
);

export default SingleProject;
