import styled from 'styled-components';

export const Wrapper = styled.div`
  overflow-x: auto;
`;

export const DataTableWrapper = styled.table`
  border-collapse: collapse;
  width: 100%;
  min-width: 700px;
`;

export const DataTableRow = styled.tr`
  vertical-align: middle;
  ${({ background }) => (background ? `background: ${background};` : '')}
`;

export const DataTableCell = styled.td`
  padding: 16px;
  word-break: break-all;
  border-bottom: 1px solid rgb(81, 81, 81);
`;

export const DataTablePagination = styled.div`
  display: flex;
  justify-content: center;
`;

export const DataTablePaginationButton = styled.div`
  cursor: pointer;
  ${({ left }) => (left ? 'transform: rotate(-180deg);' : '')}
`;
