import { Routes, Route } from 'react-router-dom';

import { ForgotPasswordForm } from './ForgotPasswordForm';
import { ResetPasswordForm } from './ResetPasswordForm';
import { useEffect } from 'react';
import { useAdminContext } from '../../context/adminContext';

export function ForgotPasswordPage() {
  const { logout } = useAdminContext();

  useEffect(() => {
    logout();
  }, [logout])

  return (
    <Routes>
      <Route path={`/`} element={<ForgotPasswordForm />} />
      <Route path={`/:token`} element={<ResetPasswordForm />} />
    </Routes>
  );
}
