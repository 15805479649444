import { BodyRegular } from '../Theme/Theme.styles';

const TextDisplay = ({ text, id }) =>
  text.split('\n').map((el, index) => (
    <BodyRegular key={`text-${index}-${id}`}>
      {el}
      <br />
    </BodyRegular>
  ));

export default TextDisplay;
