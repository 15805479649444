import styled from 'styled-components';
import { COLORS } from '../../../common/ui/Theme/Theme.styles';

export const PortfolioLink = styled.a`
  margin-bottom: 16px;
  display: block;
  text-decoration: none;
  color: ${COLORS.BOSTON_BLUE.PRIMARY};
  :hover {
    text-decoration: underline;
  }
`;
