import Button from '../../../common/ui/Button/Button';
import {
  COLORS,
  CustomColumnColumn,
  PreTitle,
  TitleH2,
} from '../../../common/ui/Theme/Theme.styles';
import { ButtonWrapper, SectionRow } from './Section.styles';
import SectionContent from './SectionContent';
import ArrowIcon from '../../../common/ui/Theme/icons/Arrow';

const Section = ({ section }) => {
  return (
    <SectionRow>
      <CustomColumnColumn customStyle='margin-right: 12px;'>
        <TitleH2>{section.title}</TitleH2>
      </CustomColumnColumn>
      <CustomColumnColumn customStyle='margin-left: 12px;'>
        {(section.content || []).map((item) => (
          <SectionContent
            key={`section-item-section-content-${section.id}-${item.id}`}
            item={item}
          />
        ))}
        {(section.buttons || []).map((btn) => (
          <ButtonWrapper key={`section-btn-${btn.name}`}>
            <Button
              uppercase
              theme='light'
              iconRight={<ArrowIcon fill={COLORS.BOSTON_BLUE.PRIMARY} />}
              onClick={() => {
                window.open(btn.address, '_blank');
              }}
            >
              <PreTitle>{btn.title}</PreTitle>
            </Button>
          </ButtonWrapper>
        ))}
      </CustomColumnColumn>
    </SectionRow>
  );
};

export default Section;
