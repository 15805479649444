import styled from 'styled-components';
import { SCREEN } from '../../../common/ui/screen/screen';
import { COLORS, ContainerRow } from '../../../common/ui/Theme/Theme.styles';

export const SectionWrapper = styled.div`
  margin-bottom: 24px;
`;

export const ButtonWrapper = styled.div`
  margin-bottom: 24px;
  > button {
    margin-left: 0px;
  }
  :last-child {
    margin-bottom: 0px;
  }
`;

export const SectionRow = styled(ContainerRow)`
  border-bottom: 1px solid ${COLORS.NEUTRAL.LIGHT_GREY};
  padding-bottom: 80px;
  padding-top: 80px;
  ${SCREEN.xxsm} {
    padding-top: 40px;
    padding-bottom: 40px;
    :first-child {
      padding-top: 80px;
    }
  }
  :last-child {
    border: none;
  }
`;

export const SectionWrapperImg = styled.div`
  margin-right: 16px;
`;

export const SectionImageWrapper = styled.div`
  max-height: 400px;
  display: flex;
`;

export const SectionImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;
