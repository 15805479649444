import styled from 'styled-components';
import { SCREEN } from '../../../common/ui/screen/screen';
import { BodyRegular, BodyLink, COLORS } from '../../../common/ui/Theme/Theme.styles';

export const TextWrapper = styled.div`
  text-align: center;
  color: ${COLORS.BOSTON_BLUE.PRIMARY};
`;

export const ProjectForm = styled.form`
  max-width: 400px;
  margin: auto;
  margin-top: 24px;
  margin-bottom: 40px;
  button {
    width: 100%;
  }
  ${SCREEN.sm} {
    max-width: 100%;
  }
`;

export const ProjectAuthInput = styled.input`
  padding: 16px;
  border: 1px solid ${COLORS.BOSTON_BLUE.PRIMARY};
  border-radius: 8px;
  width: 100%;
`;

export const FormFooterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${SCREEN.xxsm} {
    display: flex;
    flex-direction: column;
  }

  > ${BodyRegular} {
    margin-right: 8px;
  }

  > ${BodyLink} {
    color: ${COLORS.BOSTON_BLUE.PRIMARY};
  }
`;
