import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UserInformationForm } from '../UserInformationForm/UserInformationForm';
import { portfolioInputFields } from '../Portfolio/portfolio.inputs';
import { PortfolioLink } from '../Portfolio/Portfolio.styles';
import { useFetchUserInformation } from '../../../common/hooks/useFetchUserInformation';
import { useUpdateUserInformation } from '../hooks/useUpdateUserInformation';
import { toastHelper } from '../../../common/utils/toastHelper';
import { ChangePassword } from '../ChangePassword/ChangePassword';

const inputs = portfolioInputFields();

export const ProfilePage = () => {
  const { t } = useTranslation();

  const {
    isLoading: isFetching,
    userInformation,
    fetchUserInformation,
  } = useFetchUserInformation();
  const { isLoading: isUpdating, updateUserInformation } =
    useUpdateUserInformation();

  const isLoading = isFetching || isUpdating;

  const fetchData = useCallback(async () => {
    try {
      await fetchUserInformation();
    } catch (error) {
      toastHelper.error(error, t);
    }
  }, [fetchUserInformation, t]);

  const onSubmit = async (values) => {
    try {
      await updateUserInformation(values);

      toastHelper.success(t('messages.success.savedCorrectly'));

      await fetchData();
    } catch (error) {
      toastHelper.error(error, t);
    }

    window.scrollTo(0, 0);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <PortfolioLink
        rel='noreferrer'
        href={`${window.location.origin}/${userInformation.username}`}
        target='_blank'
      >
        {t('profile.portfolioLink', { 'link': `${window.location.origin}/${userInformation.username}` })}
      </PortfolioLink>
      <UserInformationForm
        onSubmit={onSubmit}
        isLoading={isLoading}
        alert={alert}
        userInformation={userInformation}
        inputs={inputs.map(input => ({
          ...input,
          label: t(input.label),
        }))}
      />
      <br />
      <ChangePassword />
    </>
  );
};
