import { useCreateCustomer } from '../hooks/useCreateCustomer';
import { toastHelper } from '../../../common/utils/toastHelper';
import BlockUi from '../../../common/ui/BlockUi/BlockUi';
import Form from '../../../common/ui/Form/Form';
import Modal from '../../../common/ui/Modal/Modal';
import { useTranslation } from 'react-i18next';

export function CustomerCreateModal({ headers, onCancel, onCompleted }) {
  const { t } = useTranslation();
  
  const { isLoading, createCustomer } = useCreateCustomer();

  const values = {
    title: '',
    image: '',
  };

  async function handleCreate(formValues) {
    try {
      await createCustomer(formValues);

      onCompleted();
    } catch (error) {
      toastHelper.error(error);
    }
  }

  return (
    <Modal>
      <BlockUi blocked={isLoading}>
      <Form
        dataInputs={headers}
        onCancel={onCancel}
        values={values}
        btnText={t('common.save')}
        onSubmit={handleCreate}
      />
      </BlockUi>
    </Modal>
  );
}
