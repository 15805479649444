import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { IMAGE_TYPE, INPUT_TYPE } from '../../../common/constants/constants';
import Form from '../../../common/ui/Form/Form';
import { CUSTOM_LIST_VALUE } from '../../../common/ui/Form/Select';
import { COLORS } from '../../../common/ui/Theme/Theme.styles';

const backgroundColorOptions = [
  {
    label: 'Silver Tree Primary',
    value: COLORS.SILVER_TREE.PRIMARY,
  },
  {
    label: 'Silver Tree Secondary',
    value: COLORS.SILVER_TREE.SECONDARY,
  },
  {
    label: 'Silver Tree Light',
    value: COLORS.SILVER_TREE.LIGHT,
  },
  {
    label: 'Boston Blue Primary',
    value: COLORS.BOSTON_BLUE.PRIMARY,
  },
  {
    label: 'Boston Blue Secondary',
    value: COLORS.BOSTON_BLUE.SECONDARY,
  },
  {
    label: 'Boston Blue Light',
    value: COLORS.BOSTON_BLUE.LIGHT,
  },
  {
    label: 'Custom',
    value: CUSTOM_LIST_VALUE,
  },
];

const inputs = [
  {
    id: uuidv4(),
    label: 'Title',
    type: INPUT_TYPE.TEXT,
    name: 'title',
    required: true,
    maxLength: 1000,
  },
  {
    id: uuidv4(),
    label: 'Description',
    type: INPUT_TYPE.TEXT,
    name: 'description',
    maxLength: 1500,
  },
  {
    id: uuidv4(),
    label: 'Customer',
    type: INPUT_TYPE.TEXT,
    name: 'customer',
    required: true,
    maxLength: 250,
  },
  {
    id: uuidv4(),
    label: 'Password',
    type: INPUT_TYPE.PASSWORD,
    name: 'password',
    helperText:
      'Password will be asked when accessing to the project through the portfolio',
    allowShowPassword: true,
    minLength: 5,
    maxLength: 250,
  },
  {
    id: uuidv4(),
    label: 'Protect with password',
    type: INPUT_TYPE.CHECKBOX,
    name: 'isProtected',
  },
  {
    id: uuidv4(),
    label: 'Background',
    type: INPUT_TYPE.LIST,
    name: 'backgroundColor',
    allowCustom: true,
    allowColorPreview: true,
    items: backgroundColorOptions,
  },
  {
    id: uuidv4(),
    label: 'Image',
    type: INPUT_TYPE.PICTURE,
    imageType: IMAGE_TYPE.SQUARE,
    name: 'image',
  },
];

const ProjectForm = ({ onSubmit, values, isCreate, onCancel }) => {
  const [obj, setObj] = useState({});

  const onChanged = useCallback(() => {
    let backgroundColor = values?.backgroundColor || '';
    let backgroundColorCustom = null;
    const hasBackgroundColor = Boolean(backgroundColorOptions.find(color => color.value === values?.backgroundColor));

    if (!hasBackgroundColor) {
      backgroundColor = CUSTOM_LIST_VALUE;
      backgroundColorCustom = values?.backgroundColor;
    }

    setObj({
      backgroundColor,
      backgroundColorCustom,
      title: values?.title || '',
      description: values?.description || '',
      customer: values?.customer || '',
      password: values?.password || '',
      image: values?.image || '',
      isProtected: values?.isProtected || false,
    });
  }, [values]);

  useEffect(() => {
    onChanged();
  }, [onChanged]);

  if (isEmpty(obj)) {
    return <></>;
  }

  return (
    <Form
      dataInputs={inputs}
      values={obj}
      btnText='Save'
      onSubmit={(newValues) => {
        const submitValues = {
          password: obj.password,
          isProtected: obj.isProtected,
          ...newValues,
        };
        const backgroundColor =
          newValues.backgroundColorCustom ?? newValues.backgroundColor;

        if (backgroundColor) {
          delete submitValues.backgroundColorCustom;

          submitValues.backgroundColor = backgroundColor;
        }

        onSubmit(submitValues);
      }}
      onlyModifiedFields={!isCreate}
      onCancel={onCancel}
    />
  );
};

export default ProjectForm;
