import { useEffect, useLayoutEffect } from 'react';
import { ModalWrapper, OverlayWrapper } from './Modal.styles';

const Modal = ({ children }) => {
  useEffect(
    () => () => {
      document.body.style.position = '';
      document.body.style.overflow = '';
      document.body.style.width = '';
    },
    []
  );

  useLayoutEffect(() => {
    document.body.style.position = 'fixed';
    document.body.style.overflow = 'hidden';
    document.body.style.width = '100%';
  }, []);

  return (
    <OverlayWrapper>
      <ModalWrapper>{children}</ModalWrapper>
    </OverlayWrapper>
  );
};

export default Modal;
