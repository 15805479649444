import BlockUi from '../../../common/ui/BlockUi/BlockUi';
import { ALERT_TYPE, INPUT_TYPE } from '../../../common/constants/constants';
import {
  BodyLink,
  BodyRegular,
  COLORS,
  PreTitle,
  TitleH3,
} from '../../../common/ui/Theme/Theme.styles';
import { InputWrapper } from '../../../common/ui/Form/Form.styles';
import ArrowIcon from '../../../common/ui/Theme/icons/Arrow';
import Button from '../../../common/ui/Button/Button';
import Alert from '../../../common/ui/Alert/Alert';
import {
  FormFooterWrapper,
  ProjectAuthInput,
  ProjectForm,
  TextWrapper,
} from './ProjectAuthForm.styles';

const ProjectAuthForm = ({
  password,
  setPassword,
  error,
  isLoading,
  onSubmit,
  onRequestPassword,
}) => {
  return (
    <>
      {error && <Alert type={ALERT_TYPE.ERROR}>{error}</Alert>}
      <BlockUi blocked={isLoading}>
        <TextWrapper>
          <TitleH3>Hey there fellow explorer</TitleH3>
          <BodyRegular>
            Please provide the correct password to continue your journey.
          </BodyRegular>
        </TextWrapper>
        <ProjectForm onSubmit={onSubmit}>
          <InputWrapper>
            <ProjectAuthInput
              name='password'
              id='password'
              type={INPUT_TYPE.TEXT}
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              required
            />
            <div
              style={{
                marginTop: '8px',
                width: '100%',
              }}
            >
              <Button
                type='submit'
                uppercase
                iconRight={<ArrowIcon fill={COLORS.NEUTRAL.WHITE} />}
                centered
              >
                <PreTitle>access</PreTitle>
              </Button>
            </div>
          </InputWrapper>
        </ProjectForm>
        <TextWrapper>
          <FormFooterWrapper>
            <BodyRegular>Want to continue on your journey?</BodyRegular>
            <BodyLink onClick={onRequestPassword}>Request a password</BodyLink>
          </FormFooterWrapper>
        </TextWrapper>
      </BlockUi>
    </>
  );
};

export default ProjectAuthForm;
