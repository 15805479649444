import { AdminContextProvider } from '../../admin/context/adminContext';
import { Routes, Route } from 'react-router-dom';
import ErrorMessage from '../../common/ui/ErrorPages/ErrorMessage';
import AdminHomepage from './AdminHomepage';
import { TabsWrapper } from '../../common/ui/Tabs/TabsWrapper';

import { PortfolioPage } from './Portfolio/PortfolioPage';
import { AboutMePage } from './AboutMe/AboutMePage';
import { UsersPage } from './Users/UsersPage';
import { ProjectPage } from './Project/ProjectPage';
import { ForgotPasswordPage } from './ForgotPassword/ForgotPasswordPage';
import { ProtectedRoute } from '../../common/ui/ProtectedRoute/ProtectedRoute';
import { IntlProvider } from '../../common/ui/IntlProvider/IntlProvider';

function Wrapper({ children }) {
  return (
    <ProtectedRoute>
      <TabsWrapper>{children}</TabsWrapper>
    </ProtectedRoute>
  );
}

function AdminApp() {
  return (
    <IntlProvider>
      <AdminContextProvider>
        <Routes>
          <Route path={`/`} element={<AdminHomepage />} />
          <Route
            path={`/portfolio`}
            element={
              <Wrapper>
                <PortfolioPage />
              </Wrapper>
            }
          />
          <Route
            path={`/projects/*`}
            element={
              <Wrapper>
                <ProjectPage />
              </Wrapper>
            }
          />
          <Route
            path={`/about-me/*`}
            element={
              <Wrapper>
                <AboutMePage />
              </Wrapper>
            }
          />
          <Route
            path={`/users`}
            element={
              <Wrapper>
                <UsersPage />
              </Wrapper>
            }
          />
          <Route path={`/forgot-password/*`} element={<ForgotPasswordPage />} />
          <Route path='*' element={<ErrorMessage isNotFoundPage />} />
        </Routes>
      </AdminContextProvider>
    </IntlProvider>
  );
}

export default AdminApp;
