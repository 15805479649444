import { Fragment } from 'react';
import { CONTENT_TYPE } from '../../../common/constants/constants';
import TextDisplay from '../../../common/ui/Text/TextDisplay';
import { COLORS, PreTitle } from '../../../common/ui/Theme/Theme.styles';
import {
  ProjectSectionImageWrapper,
  ProjectSectionTextWrapper,
  ProjectSectionTitle,
} from './Project.styles';

const ProjectSectionData = ({ column }) => {
  const renderData = (columnData) => {
    if (columnData.type === CONTENT_TYPE.IMAGE) {
      return (
        <ProjectSectionImageWrapper>
          <img src={columnData.image} alt='src' />
        </ProjectSectionImageWrapper>
      );
    }
    if (columnData.type === CONTENT_TYPE.CONTENT) {
      return (
        <ProjectSectionTextWrapper>
          <ProjectSectionTitle>
            <PreTitle color={COLORS.NEUTRAL.DARK_GREY}>
              {columnData.title}
            </PreTitle>
          </ProjectSectionTitle>
          <div>
            <TextDisplay text={columnData.body} id={columnData.id} />
          </div>
        </ProjectSectionTextWrapper>
      );
    }
    return <></>;
  };
  return (
    <>
      {(column.children || []).map((columnData) => (
        <Fragment key={`column-${column.id}-data-${columnData.id}`}>
          {renderData(columnData)}
        </Fragment>
      ))}
    </>
  );
};

export default ProjectSectionData;
