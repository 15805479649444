import styled from 'styled-components';
import { SCREEN } from '../../../common/ui/screen/screen';

export const PortfolioWrapperStyled = styled.div`
  position: absolute;
  left: 50%;
  right: 0px;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  ${SCREEN.sm} {
    display: block;
    width: calc(100% - 32px);
    padding: 16px;
  }
`;

export const ImgWrapper = styled.div`
  > img {
    height: 150px;
  }
`;

export const TextWrapper = styled.div`
  margin-top: 8px;
`;
