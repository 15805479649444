import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import Alert from '../../../common/ui/Alert/Alert';
import BlockUi from '../../../common/ui/BlockUi/BlockUi';
import Form from '../../../common/ui/Form/Form';

export const UserInformationForm = ({
  onSubmit,
  isLoading,
  alert,
  userInformation,
  inputs,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      {!isEmpty(alert.text) && <Alert type={alert.type}>{alert.text}</Alert>}
      <div>
        <BlockUi blocked={isLoading}>
          <Form
            dataInputs={inputs}
            values={userInformation}
            btnText={t('profile.save')}
            onSubmit={onSubmit}
            onlyModifiedFields
          />
        </BlockUi>
      </div>
    </div>
  );
};
