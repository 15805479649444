import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

import { INPUT_TYPE } from '../../../common/constants/constants';
import Form from '../../../common/ui/Form/Form';
import { useChangePassword } from '../hooks/useChangePassword';
import { toastHelper } from '../../../common/utils/toastHelper';
import BlockUi from '../../../common/ui/BlockUi/BlockUi';

const inputs = [
  {
    id: uuidv4(),
    label: 'profile.input.password',
    type: INPUT_TYPE.PASSWORD,
    name: 'password',
    required: true,
    maxLength: 100,
    minLength: 5,
  },
  {
    id: uuidv4(),
    label: 'profile.input.repeatPassword',
    type: INPUT_TYPE.PASSWORD,
    name: 'repeatPassword',
    required: true,
    maxLength: 100,
    minLength: 5,
    shouldMatch: 'password',
    matchLabel: 'Password',
  },
];

export function ChangePasswordForm({ onChangePassword, token }) {
  const { t } = useTranslation();
  const { isLoading, changePassword } = useChangePassword({ token });

  const [data, setData] = useState({
    password: '',
    repeatPassword: '',
  });

  async function handleChangePassword({ password, repeatPassword }) {
    try {
      await changePassword(password, repeatPassword);

      setData({
        password: '',
        repeatPassword: '',
      });

      toastHelper.success(t('profile.messages.success.passwordUpdated'));

      onChangePassword?.();
    } catch (error) {
      toastHelper.error(error, t);
    }
  }

  return (
    <BlockUi blocked={isLoading}>
      <Form
        dataInputs={inputs.map(input => ({
          ...input,
          label: t(input.label),
        }))}
        values={data}
        btnText={t('profile.changePassword.title')}
        onSubmit={handleChangePassword}
      />
    </BlockUi>
  );
}

export default ChangePasswordForm;
