import { useState, useCallback } from 'react';
import { requestStatus } from '../../common/constants/requestStatus';
import { getErrorMessages } from '../utils/errorSetter';
import { portfolioService } from '../../common/services/portfolioService';

export function useFetchPortfolio() {
  const [portfolio, setPortfolio] = useState(null);

  const [status, setStatus] = useState(requestStatus.Initial);

  const isLoading = status === requestStatus.Pending;

  const fetchPortfolio = useCallback(async () => {
    setStatus(requestStatus.Pending);

    try {
      const { portfolio: portfolioResponse } =
        await portfolioService.getPortfolio();

      setPortfolio(portfolioResponse);

      setStatus(requestStatus.Done);
    } catch (error) {
      setStatus(requestStatus.Error);

      throw Error(
        getErrorMessages({
          error,
        })
      );
    }
  }, []);

  return {
    isLoading,
    portfolio,
    fetchPortfolio,
  };
}
