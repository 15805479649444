import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import {
  ContactContainer,
  ContactFooterWrapper,
  ContactUserWrapper,
  ContactWrapper,
} from './Contact.styles';
import ContactUser from './ContactUser';
import { COLORS } from '../../../common/ui/Theme/Theme.styles';

const Contact = () => {
  return (
    <ContactWrapper>
      <Header color={COLORS.NEUTRAL.WHITE} />
      <ContactUserWrapper>
        <ContactContainer>
          <ContactUser />
        </ContactContainer>
      </ContactUserWrapper>
      <ContactFooterWrapper>
        <Footer withUserRole={false} />
      </ContactFooterWrapper>
    </ContactWrapper>
  );
};

export default Contact;
