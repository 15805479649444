import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { TabContainer, TabContentWrapper } from './Tabs.styled';
import { Tabs } from './Tabs';
import { useAdminContext } from '../../../admin/context/adminContext';
import ConfirmModalContent from '../../../admin/ui/ConfirmModalContent/ConfirmModalContent';
import Modal from '../Modal/Modal';
import { Container } from '../Theme/Theme.styles';
import { LanguageModal } from '../../../admin/ui/LanguageModal/LanguageModal';
import { setLanguage } from '../IntlProvider/IntlProvider';

const tabs = [
  {
    route: '/admin',
    title: 'tabs.profile',
  },
  {
    route: '/admin/portfolio',
    title: 'tabs.home',
  },
  {
    route: '/admin/about-me',
    title: 'tabs.aboutMe',
  },
  {
    route: '/admin/projects',
    title: 'tabs.projects',
  },
  {
    route: '/admin/users',
    title: 'tabs.users',
  },
  {
    route: 'language',
    title: 'tabs.language.title',
  },
  {
    route: 'logout',
    title: 'tabs.logout.title',
  },
];

export function TabsWrapper({ children }) {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const { logout } = useAdminContext();

  const [isLogountModalOpened, setIsLogountModalOpened] = useState(false);
  const [isLanguageModalOpened, setIsLanguageModalOpened] = useState(false);
  const [activeTab, setActiveTab] = useState(null);

  const { language } = i18n;

  function handleCloseLanguageModal() {
    setIsLanguageModalOpened(false);
  }

  function handleConfirmLanguage({ language: selectedLanguage }) {
    setLanguage(selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
    
    handleCloseLanguageModal();
  }

  const handleChangeTab = (index) => {
    if (tabs[index].route === 'logout') {
      return setIsLogountModalOpened(true);
    }

    if (tabs[index].route === 'language') {
      return setIsLanguageModalOpened(true);
    }

    return navigate(tabs[index].route);
  };

  useEffect(() => {
    const tabIndex = tabs.findIndex((tab) => tab.route === location.pathname);

    if (tabIndex > -1) {
      setActiveTab(tabIndex);
    }
  }, [location]);

  return (
    <Container $paddingBottom='24px'>
      {isLogountModalOpened && (
        <Modal>
          <ConfirmModalContent
            body={t('tabs.logout.confirmationMessage')}
            onCancel={() => setIsLogountModalOpened(false)}
            onCofirm={logout}
          />
        </Modal>
      )}
      {isLanguageModalOpened && (
        <LanguageModal
          language={language}
          onClose={handleCloseLanguageModal}
          onConfirm={handleConfirmLanguage}
        />
      )}
      <TabContainer>
        <Tabs tabs={tabs} activeTab={activeTab} onChangeTab={handleChangeTab} />
        <TabContentWrapper>{children}</TabContentWrapper>
      </TabContainer>
    </Container>
  );
}
