import { useCallback, useEffect, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { INPUT_TYPE } from '../../../common/constants/constants';
import Form from '../../../common/ui/Form/Form';
import { toastHelper } from '../../../common/utils/toastHelper';
import { useUpdatePortfolio } from '../hooks/useUpdatePortfolio';
import { useFetchPortfolio } from '../../../common/hooks/useFetchPortfolio';
import BlockUi from '../../../common/ui/BlockUi/BlockUi';

const inputs = [
  {
    id: uuidv4(),
    label: 'Title',
    type: INPUT_TYPE.TEXT_AREA,
    name: 'aboutHeaderText',
    maxLength: 450,
  },
];

export const AboutMeForm = () => {
  const {
    isLoading: isFetchingPortfolio,
    portfolio,
    fetchPortfolio,
  } = useFetchPortfolio();
  const { isLoading: isUpdatingPortfolio, updatePortfolio } =
    useUpdatePortfolio();

  const values = useMemo(
    () => ({
      aboutHeaderText: portfolio?.aboutHeaderText ?? '',
    }),
    [portfolio]
  );

  const isLoading = isFetchingPortfolio || isUpdatingPortfolio;

  async function handleUpdatePortfolio(formValues) {
    try {
      await updatePortfolio(formValues);

      toastHelper.success('Title updated correctly');

      await fetchPortfolio();
    } catch (error) {
      toastHelper.error(error);
    }
  }

  const getPortfolioData = useCallback(async () => {
    try {
      await fetchPortfolio();
    } catch (error) {
      toastHelper.error(error);
    }
  }, [fetchPortfolio]);

  useEffect(() => {
    getPortfolioData();
  }, [getPortfolioData]);

  return (
    <BlockUi blocked={isLoading}>
      <Form
        dataInputs={inputs}
        values={values}
        btnText='Save'
        onSubmit={handleUpdatePortfolio}
        onlyModifiedFields
      />
    </BlockUi>
  );
};
