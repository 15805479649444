import { useState, useCallback } from 'react';
import { requestStatus } from '../../../common/constants/requestStatus';
import { getErrorMessages } from '../../../common/utils/errorSetter';
import { profileService } from '../../../common/services/profileService';

export function useFetchProfileSection() {
  const [profileSection, setProfileSection] = useState(null);

  const [status, setStatus] = useState(requestStatus.Initial);

  const isLoading = status === requestStatus.Pending;

  const fetchProfileSection = useCallback(async (sectionId) => {
    setStatus(requestStatus.Pending);

    try {
      const profileSectionResponse =
        await profileService.getProfileSectionByProfileSectionId(sectionId);

      setProfileSection({
        ...profileSectionResponse,
        buttons: JSON.parse(profileSectionResponse.buttons),
      });

      setStatus(requestStatus.Done);
    } catch (error) {
      setStatus(requestStatus.Error);

      throw Error(
        getErrorMessages({
          error,
        })
      );
    }
  }, []);

  return {
    isLoading,
    profileSection,
    fetchProfileSection,
  };
}
