import { Routes, Route } from 'react-router-dom';

import { ProjectListPage } from './ProjectListPage';
import { Project } from './Project';
import { ProjectContent } from './ProjectContent';
import ErrorMessage from '../../../common/ui/ErrorPages/ErrorMessage';

export function ProjectPage() {
  return (
    <Routes>
      <Route path={`/`} element={<ProjectListPage />} />
      <Route path={`/:id`} element={<Project />} />
      <Route path={`/:id/content`} element={<ProjectContent />} />
      <Route path='*' element={<ErrorMessage isNotFoundPage />} />
    </Routes>
  );
}
