import styled from 'styled-components';
import { SCREEN } from '../../../common/ui/screen/screen';
import { COLORS, Container } from '../../../common/ui/Theme/Theme.styles';

export const AboutWrapper = styled.div`
  flex-grow: 1;
`;

export const ProfileWrapper = styled.div`
  background: linear-gradient(98.22deg, #6d9981 -2.95%, #457599 104.96%);
  padding: 80px 16px;
  // margin-top: 8px;
`;

export const ProfileDataWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  max-width: 500px;
  margin: auto;
`;

export const ProfileDataItem = styled.div`
  flex-basis: 100%;
  width: 100%;
  color: ${COLORS.NEUTRAL.WHITE};
  margin-top: ${({ marginTop }) => marginTop || '0px'};
`;

export const ProfileSectionsContainer = styled(Container)`
  padding-top: 0px;
  padding-left: 80px;
  padding-right: 80px;

  ${SCREEN.lg} {
    padding-left: 64px;
    padding-right: 64px;
  }

  ${SCREEN.md} {
    padding-left: 40px;
    padding-right: 40px;
  }

  ${SCREEN.sm} {
    width: 66.6666%;
  }

  ${SCREEN.xsm} {
    padding-left: 32px;
    padding-right: 32px;
    width: initial;
  }

  ${SCREEN.xxsm} {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const ProfileSectionsWrapper = styled.div``;
