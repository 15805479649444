import axiosInstance from './interceptor';

export const getProfile = async () => {
  const res = await axiosInstance.get(
    `${process.env.REACT_APP_API_URL}/profile`
  );

  return res.data;
};

export const createProfileSection = async (title, buttons) => {
  const res = await axiosInstance.post(
    `${process.env.REACT_APP_API_URL}/profile`,
    {
      title,
      buttons,
    }
  );

  return res.data;
};

export const deleteProfileSection = async (id) => {
  const res = await axiosInstance.delete(
    `${process.env.REACT_APP_API_URL}/profile/${id}`
  );

  return res.data;
};

export const updateProfileSection = async (id, title, buttons) => {
  const res = await axiosInstance.patch(
    `${process.env.REACT_APP_API_URL}/profile/${id}`,
    {
      title,
      buttons,
    }
  );

  return res.data;
};

export const updateOrder = async (data) => {
  const res = await axiosInstance.post(
    `${process.env.REACT_APP_API_URL}/profile/order`,
    {
      data,
    }
  );

  return res.data;
};

export const getProfileSectionDataByProfileSectionId = async (id) => {
  const res = await axiosInstance.get(
    `${process.env.REACT_APP_API_URL}/profile/${id}/data`
  );

  return res.data;
};

export const getProfileSectionByProfileSectionId = async (id) => {
  const res = await axiosInstance.get(
    `${process.env.REACT_APP_API_URL}/profile/${id}`
  );

  return res.data;
};

export const createProfileSectionData = async (id, data) => {
  const res = await axiosInstance.post(
    `${process.env.REACT_APP_API_URL}/profile/${id}/data`,
    data
  );

  return res.data;
};

export const profileService = {
  updateOrder,
  deleteProfileSection,
  createProfileSection,
  getProfile,
  updateProfileSection,
  getProfileSectionByProfileSectionId,
  getProfileSectionDataByProfileSectionId,
  createProfileSectionData,
};
