import { useState } from 'react';
import styled from 'styled-components';
import { COLORS } from '../Theme/Theme.styles';

const TooltipWrapper = styled.div`
  position: relative;
`;

const TooltipTitle = styled.div`
  position: absolute;
  z-index: 999;
  bottom: 50px;
  min-width: 100px;
  word-break: break-all;
  background: ${COLORS.NEUTRAL.TEXT_BLACK};
  color: ${COLORS.NEUTRAL.WHITE};
  padding: 8px;
  border-radius: 10px;
`;

const Tooltip = ({ title, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <TooltipWrapper
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {showTooltip && <TooltipTitle>{title}</TooltipTitle>}
      {children}
    </TooltipWrapper>
  );
};

export default Tooltip;
