import { v4 as uuidv4 } from 'uuid';
import { INPUT_TYPE } from '../../../common/constants/constants';

const getInputs = ({ create = false } = {}) => {
  let inputs = [
    {
      id: uuidv4(),
      label: 'profile.input.email',
      type: INPUT_TYPE.EMAIL,
      name: 'email',
      required: create,
      disabled: !create,
      maxLength: 100,
    },
  ];

  if (create) {
    inputs = inputs.concat([
      {
        id: uuidv4(),
        label: 'profile.input.password',
        type: INPUT_TYPE.PASSWORD,
        name: 'password',
        required: true,
        minLength: 5,
        maxLength: 100,
      },
    ]);
  }

  inputs = inputs.concat([
    {
      id: uuidv4(),
      label: 'profile.input.firstName',
      type: INPUT_TYPE.TEXT,
      name: 'firstName',
      required: true,
      maxLength: 100,
    },
    {
      id: uuidv4(),
      label: 'profile.input.lastName',
      type: INPUT_TYPE.TEXT,
      name: 'lastName',
      required: true,
      maxLength: 100,
    },
    {
      id: uuidv4(),
      label: 'profile.input.position',
      type: INPUT_TYPE.TEXT,
      name: 'role',
      required: true,
      maxLength: 200,
    },
    {
      id: uuidv4(),
      label: 'profile.input.address',
      type: INPUT_TYPE.TEXT,
      name: 'address',
      required: false,
      maxLength: 400,
    },
    {
      id: uuidv4(),
      label: 'profile.input.phone',
      type: INPUT_TYPE.PHONE_NUMBER,
      name: 'phone',
      maxLength: 20,
    },
    {
      id: uuidv4(),
      label: 'profile.input.linkedIn',
      type: INPUT_TYPE.TEXT,
      name: 'linkedIn',
      maxLength: 100,
    },
    {
      id: uuidv4(),
      label: 'profile.input.picture',
      type: INPUT_TYPE.PICTURE,
      name: 'profilePic',
    },
  ]);

  return inputs;
};

export const portfolioInputFields = getInputs;
