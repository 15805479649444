import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { INPUT_TYPE } from '../../../common/constants/constants';
import Modal from '../../../common/ui/Modal/Modal';
import Form from '../../../common/ui/Form/Form';
import styled from 'styled-components';

const StyledContainer = styled.div`
  select {
    width: 100%;
  }
`;

const inputs = [
  {
    id: uuidv4(),
    label: 'tabs.language.select',
    type: INPUT_TYPE.LIST,
    name: 'language',
    items: [
      {
        label: 'English',
        value: 'en',
      },
      {
        label: 'Nederlands',
        value: 'nl',
      },
    ],
  },
];

export function LanguageModal({ language, onClose, onConfirm }) {
  const { t } = useTranslation();

  return (
    <Modal>
      <StyledContainer>
        <Form
          dataInputs={inputs.map((input) => ({
            ...input,
            label: t(input.label),
          }))}
          values={{
            language,
          }}
          btnText={t('common.confirm')}
          onCancel={onClose}
          onSubmit={onConfirm}
        />
      </StyledContainer>
    </Modal>
  );
}
