import styled from 'styled-components';
import { SCREEN } from '../screen/screen';

export const ErroMessageWrapper = styled.div`
  position: absolute;
  left: 50%;
  right: 0px;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  ${SCREEN.sm} {
    display: block;
    width: calc(100% - 32px);
    padding: 16px;
  }
`;

export const ErrorMessageImgWrapper = styled.div`
  > img {
    height: 100px;
  }
`;

export const ErrorMessageTextWrapper = styled.div`
  margin-left: 16px;
  ${SCREEN.sm} {
    margin-left: 0px;
    margin-top: 16px;
  }
`;
