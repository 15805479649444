import { useParams, useNavigate } from 'react-router-dom';
import ProjectForm from './ProjectForm';
import { useCreateProject } from '../hooks/useCreateProject';
import { toastHelper } from '../../../common/utils/toastHelper';
import BlockUi from '../../../common/ui/BlockUi/BlockUi';
import { useCallback, useEffect } from 'react';
import { useFetchProject } from '../hooks/useFetchProject';
import { useUpdateProject } from '../hooks/useUpdateProject';

export function Project() {
  const { id } = useParams();
  const navigate = useNavigate();
  
  const { isLoading: isUpdatingProject, updateProject } = useUpdateProject();
  const { isLoading: isCreatingProject, createProject } = useCreateProject();
  const {
    isLoading: isFetchingProject,
    project,
    fetchProjectById,
  } = useFetchProject();

  const isLoading = isCreatingProject || isFetchingProject || isUpdatingProject;
  const isCreate = id === 'create';

  function handleGoBack() {
    navigate(-1);
  }

  async function handleCreate(formValues) {
    try {
      await createProject(formValues);

      toastHelper.success('Project created succesfully');

      handleGoBack();
    } catch (error) {
      toastHelper.error(error);
    }
  }

  async function handleUpdate(formValues) {
    try {
      await updateProject({
        id,
        ...formValues,
      });

      toastHelper.success('Project updated succesfully');

      handleGoBack();
    } catch (error) {
      toastHelper.error(error);
    }
  }

  const getProject = useCallback(async () => {
    try {
      await fetchProjectById(id);

    } catch (error) {
      toastHelper.error(error);
    }
  }, [id, fetchProjectById]);

  useEffect(() => {
    if (id !== 'create') {
      getProject();
    }
  }, [id, getProject ]);

  return (
    <BlockUi blocked={isLoading}>
      <ProjectForm
        values={project?.data}
        onCancel={handleGoBack}
        onSubmit={isCreate ? handleCreate : handleUpdate}
      />
    </BlockUi>
  );
}
