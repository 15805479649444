import axiosInstance from './interceptor';

export const deleteCustomerByCustomerId = async (customerId) => {
  const res = await axiosInstance.delete(
    `${process.env.REACT_APP_API_URL}/customer/${customerId}`
  );

  return res.data;
};

export const getCustomers = async () => {
  const res = await axiosInstance.get(
    `${process.env.REACT_APP_API_URL}/customer`
  );

  return res.data;
};

export const createCustomer = async (title, image) => {
  const res = await axiosInstance.post(
    `${process.env.REACT_APP_API_URL}/customer`,
    {
      title,
      image,
    }
  );

  return res.data;
};

export const updateCustomer = async (id, title, image) => {
  const res = await axiosInstance.patch(
    `${process.env.REACT_APP_API_URL}/customer/${id}`,
    {
      title,
      image,
    }
  );

  return res.data;
};

export const customerService = {
  getCustomers,
  updateCustomer,
  createCustomer,
  deleteCustomerByCustomerId,
};
