import { useNavigate, useParams } from 'react-router-dom';
import ProfileSectionForm from './ProfileSectionForm';
import { useCallback, useEffect, useMemo } from 'react';
import { useFetchProfileSection } from '../hooks/useFetchProfileSection';
import { toastHelper } from '../../../common/utils/toastHelper';
import BlockUi from '../../../common/ui/BlockUi/BlockUi';
import { useCreateProfileSection } from '../hooks/useCreateProfileSection';
import { useUpdateProfileSection } from '../hooks/useUpdateProfileSection';

export function AboutMeSection() {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    isLoading: isFetchingSection,
    profileSection,
    fetchProfileSection,
  } = useFetchProfileSection();
  const { isLoading: isCreating, createProfile } = useCreateProfileSection();
  const { isLoading: isUpdating, updateProfile } = useUpdateProfileSection();

  const values = useMemo(
    () => ({
      title: profileSection?.title ?? '',
      buttons: profileSection?.buttons ?? [],
    }),
    [profileSection]
  );

  const isLoading = isFetchingSection || isCreating || isUpdating;
  const isCreate = id === 'create';

  function handleGoBack() {
    navigate(-1);
  }

  async function handleCreateProfileSection(formValues) {
    try {
      await createProfile(formValues);

      toastHelper.success('Section created successfully');

      handleGoBack();
    } catch (error) {
      toastHelper.error(error);
    }
  }

  async function handleUpdateProfileSection(formValues) {
    try {
      await updateProfile({
        id,
        ...formValues,
      });

      toastHelper.success('Section updated successfully');

      handleGoBack();
    } catch (error) {
      toastHelper.error(error);
    }
  }

  const getProfileSection = useCallback(async () => {
    try {
      await fetchProfileSection(id);
    } catch (error) {
      toastHelper.error(error);
    }
  }, [id, fetchProfileSection]);

  useEffect(() => {
    if (id !== 'create') {
      getProfileSection();
    }
  }, [id, getProfileSection]);

  return (
    <BlockUi blocked={isLoading}>
      <ProfileSectionForm
        values={values}
        onSubmit={
          isCreate ? handleCreateProfileSection : handleUpdateProfileSection
        }
        onCancel={handleGoBack}
        isCreate={isCreate}
      />
    </BlockUi>
  );
}
